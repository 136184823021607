var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { RenderProperty } from "@/src/views/RenderProperty";
import { BreakFormLayout, getColumnStyle, getFieldProps, useCommonFormStyles, } from "./common";
import cn from "classnames";
import { createUseStyles } from "react-jss";
var useStyles = createUseStyles({
    textArea: {
        appearance: "none",
        display: "block",
        minHeight: 200,
        padding: "1rem",
        resize: "none",
        verticalAlign: "top",
    },
    textBoxValidityIcon: function (_a) {
        var _b;
        var invalidIcon = _a.invalidIcon, validIcon = _a.validIcon;
        return (_b = {},
            _b["&.".concat(validIcon, ":after, &.").concat(invalidIcon, ":after")] = {
                top: "90%",
            },
            _b);
    },
});
export function FormTextArea(props) {
    var commonFormStyles = useCommonFormStyles();
    var styles = useStyles({
        invalidIcon: commonFormStyles.invalidIcon,
        validIcon: commonFormStyles.validIcon,
    });
    var columnStyle = commonFormStyles[getColumnStyle(props.data.horizontalLayout.value)];
    var newRow = props.data.newRow.value;
    var validationError = props.isValid === true ? null : props.isValid.join(" ");
    var showValid = props.isBlurred && !validationError;
    var showInvalid = props.isBlurred && validationError;
    return (React.createElement(React.Fragment, null,
        newRow && React.createElement(BreakFormLayout, null),
        React.createElement("div", { className: columnStyle },
            React.createElement("label", null,
                props.data.label.value && (React.createElement(RenderProperty, { value: props.data.label, className: commonFormStyles.label })),
                React.createElement("div", { className: cn(commonFormStyles.inputWrapper, styles.textBoxValidityIcon, showValid && commonFormStyles.validIcon, showInvalid && commonFormStyles.invalidIcon) },
                    React.createElement("textarea", __assign({}, getFieldProps(props.data), { className: cn(commonFormStyles.inputBox, styles.textArea), placeholder: props.data.placeHolder.value || undefined, value: props.value || "", onChange: function (e) {
                            props.onChange(e.target.value);
                        }, onBlur: props.setBlurred })),
                    React.createElement("div", { className: cn(commonFormStyles.underlinePrimary, showValid && commonFormStyles.validUnderlinePrimary, showInvalid && commonFormStyles.invalidUnderlinePrimary) }))),
            validationError && (React.createElement("div", { className: commonFormStyles.validationError }, validationError)))));
}
