import React from "react";
export var Logo = function (_a) {
    var className = _a.className;
    return (React.createElement("svg", { viewBox: "0 0 130 77", fill: "none", xmlns: "http://www.w3.org/2000/svg", className: className },
        React.createElement("rect", { width: "130", height: "77", fill: "white" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M81.215 43.6229V32.6427H82.5662C86.2717 32.6427 88.6611 34.2966 88.6611 38.0947V38.17C88.6611 41.9682 86.2717 43.6229 82.5662 43.6229H81.215ZM73.1113 50.2647H82.1157C91.9162 50.2647 96.8335 45.1705 96.8335 37.9937V37.9243C96.8335 30.7492 92.0205 26 82.3927 26H73.1113V50.2647Z", fill: "#006025" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M51.083 42.7343H63.7913V34.3672H51.083V42.7343Z", fill: "#E1001C" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M99.375 50.2647H107V26H99.375V50.2647Z", fill: "#006025" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M46 50.2647H53.625V26H46V50.2647Z", fill: "#006025" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M62.0977 50.2647H69.7227V26H62.0977V50.2647Z", fill: "#006025" })));
};
