var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { createUseStyles } from "react-jss";
import { colors } from "@basics/colors";
import { onBreakpoint, GUTTER_WIDTHS, rem } from "@basics/layout";
import { typography, customTypography } from "@basics/typography";
import { spacings } from "@basics/spacings";
export function getFieldProps(field) {
    return {
        name: "__field_".concat(field.contentLink.id),
        id: field.contentLink.guidValue,
    };
}
export function getColumnStyle(columns) {
    return columns === "three-columns"
        ? "threeCols"
        : columns === "two-columns"
            ? "twoCols"
            : "singleCol";
}
export function hasOptions(data) {
    return Boolean(data.options);
}
export function hasValidators(data) {
    return (Boolean(data.validatorsExpanded) &&
        Array.isArray(data.validatorsExpanded));
}
export var useCommonFormStyles = createUseStyles({
    singleCol: __assign(__assign(__assign({ width: "100%", marginBottom: rem(spacings.m) }, onBreakpoint("sm", {
        marginLeft: GUTTER_WIDTHS["small"],
    })), onBreakpoint("md", {
        marginLeft: GUTTER_WIDTHS["big"],
    })), { "& h4": {
            marginBottom: "".concat(spacings.xs, " !important"),
        } }),
    twoCols: __assign(__assign({ extend: "singleCol" }, onBreakpoint("sm", {
        width: "calc(100% / 2 - ".concat(GUTTER_WIDTHS["small"], "px)"),
    })), onBreakpoint("md", {
        width: "calc(100% / 2 - ".concat(GUTTER_WIDTHS["big"], "px)"),
    })),
    threeCols: __assign(__assign({ extend: "singleCol" }, onBreakpoint("sm", {
        width: "calc(100% / 3 - ".concat(GUTTER_WIDTHS["small"], "px)"),
    })), onBreakpoint("md", {
        width: "calc(100% / 3 - ".concat(GUTTER_WIDTHS["big"], "px)"),
    })),
    flexBreak: {
        flexBasis: "100%",
        height: 0,
    },
    label: __assign(__assign({}, typography.textSmall), { cursor: "pointer" }),
    inputBox: __assign(__assign({}, customTypography(typography.textDefault, { marginBottom: 0 }, { marginBottom: 0 }, { marginBottom: 0 })), { background: colors.gray10, border: 0, borderBottom: "2px solid ".concat(colors.black), borderRadius: 0, color: colors.black, height: "3.5rem", padding: "0 0.5rem", width: "100%", outline: "none", "&:focus~$underlinePrimary": { width: "100%" }, "&:hover~$underlinePrimary": { width: "100%" } }),
    underlinePrimary: {
        backgroundColor: colors.webGreen40,
        bottom: 0,
        display: "inline-block",
        height: 2,
        left: 0,
        position: "absolute",
        transition: "all .3s ease-out",
        width: 0,
        zIndex: 2,
    },
    invalidUnderlinePrimary: {
        backgroundColor: colors.alertsRed60,
        width: "100%",
    },
    validUnderlinePrimary: {
        backgroundColor: colors.webGreen40,
        width: "100%",
    },
    inputWrapper: {
        position: "relative",
        "& >*:last-child": {
            marginBottom: 0,
        },
        "&[class*='horizontal'] >*": __assign({}, onBreakpoint("sm", {
            marginBottom: 0,
        })),
    },
    validationError: __assign({}, customTypography(__assign(__assign({}, typography.textSmall), { color: colors.alertsRed60, minHeight: "1rem", width: "100%" }), {}, {
        marginBottom: 0,
    })),
    validityIcon: {
        top: "50%",
        right: 0,
        position: "absolute",
        fontSize: "1.25rem",
        fontFamily: "Font Awesome\\ 5 Pro",
        fontWeight: 300,
        marginRight: "1.5rem",
        transform: "translateY(-50%)",
    },
    validIcon: {
        "&:after": {
            extend: "validityIcon",
            content: '"\\f00c"',
            color: colors.webGreen40,
        },
    },
    invalidIcon: {
        "&:after": {
            extend: "validityIcon",
            content: '"\\f00d"',
            color: colors.alertsRed60,
        },
    },
});
export function BreakFormLayout() {
    var commonFormStyles = useCommonFormStyles();
    return React.createElement("div", { className: commonFormStyles.flexBreak });
}
