var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { rem } from "@basics/layout";
import { createUseStyles } from "react-jss";
import { colors } from "@basics/colors";
import { spacings } from "@basics/spacings";
import { customTypography, typography } from "@basics/typography";
import cn from "classnames";
var useStyles = createUseStyles({
    element: {
        marginBottom: rem(spacings.s),
        display: "flex",
        position: "relative",
        "& input:checked + $customCheckbox::after": {
            opacity: 1,
        },
        "& input:focus + $customCheckbox": {
            outline: "2px solid ".concat(colors.focusOutline),
        },
    },
    customCheckbox: {
        content: '""',
        cursor: "pointer",
        height: 32,
        width: 32,
        position: "absolute",
        top: 0,
        left: 0,
        backgroundColor: colors.white,
        border: "2px solid ".concat(colors.gray90),
        "&:before": {
            display: function (blackbox) { return (blackbox ? "block" : "none"); },
            content: '"\\f0c8"',
            fontFamily: "Font Awesome\\ 5 Pro",
            fontSize: 26,
            fontWeight: "bold",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        },
        "&:after": {
            display: function (blackbox) { return (blackbox ? "none" : "block"); },
            position: "absolute",
            content: '""',
            top: 0,
            left: 8,
            height: 20,
            width: 12,
            cursor: "pointer",
            border: "2px solid ".concat(colors.gray90),
            borderLeft: 0,
            borderTop: 0,
            opacity: 0,
            transform: "rotate(45deg)",
        },
    },
    label: __assign({}, customTypography(__assign(__assign({}, typography.textDefault), { marginLeft: rem(spacings.sam), marginBottom: 0, cursor: "pointer" }), {}, {
        marginLeft: rem(spacings.s),
        marginRight: rem(spacings.sam),
        marginBottom: 0,
    }, {
        marginLeft: rem(spacings.sam),
        marginRight: rem(spacings.m),
    })),
    strong: {
        fontWeight: "bold",
    },
    disabled: {
        "& div": {
            cursor: "not-allowed",
            borderColor: colors.gray40,
            "&:after": {
                cursor: "not-allowed",
                borderColor: colors.gray40,
            },
        },
        "& label": {
            color: colors.gray40,
        },
    },
    checkbox: {
        height: 32,
        width: 32,
        flexShrink: 0,
        margin: 0,
        padding: 0,
        opacity: 0,
    },
});
export function Checkbox(props) {
    var styles = useStyles(props.blackbox);
    return (React.createElement("label", { className: cn(styles.element, props.checked && styles.strong, props.disabled && styles.disabled, props.className) },
        React.createElement("input", { type: "checkbox", className: styles.checkbox, checked: props.checked, onChange: function () { return props.onCheck(); }, disabled: props.disabled, onBlur: props.onBlur }),
        React.createElement("div", { className: styles.customCheckbox }),
        React.createElement("span", { className: styles.label }, props.label)));
}
