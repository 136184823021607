var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useContext } from "react";
import { ContentPage } from "./ContentPage";
import { LocalizationContext, localizedLabelString, } from "@hooks/LocalizationContext";
export function UnsubscribeConfirmationPage(props) {
    var initialQueryParametrs = new URLSearchParams(window.location.search);
    var mailingID = initialQueryParametrs.get("link");
    var localizationCtx = useContext(LocalizationContext);
    var unsubscribeLabel = localizedLabelString({
        section: "Global",
        label: "Unsubscribe",
        localizationCtx: localizationCtx,
    });
    var unsubscribeButton = mailingID
        ? [
            {
                link: [
                    {
                        href: "https://api.broadmail.de/http/mail/" +
                            mailingID +
                            "/unsubscribe?bmUrl=https://www.hdi.global/newsletter/unsubscribe-successful?localise=true",
                        text: unsubscribeLabel,
                        target: null,
                        title: unsubscribeLabel,
                    },
                ],
                buttonType: "primary",
                buttonIconType: "none",
                buttonIcon: null,
            },
        ]
        : [];
    return (React.createElement(ContentPage, __assign({}, __assign(__assign({}, props), { data: __assign(__assign({}, props.data), { keyVisual: __assign(__assign({}, props.data.keyVisual), { buttons: __assign(__assign({}, props.data.keyVisual.buttons), { value: __spreadArray(__spreadArray([], (props.data.keyVisual.buttons.value || []), true), unsubscribeButton, true) }) }) }) }))));
}
