var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { colors } from "@basics/colors";
import { Container, onBreakpoint, rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { customTypography, typography } from "@basics/typography";
import { LocalizedLabel } from "@hooks/LocalizationContext";
import { isDeviceResOrLower, useDeviceType } from "@hooks/useDeviceType";
import { RenderProperty } from "@/src/views/RenderProperty";
import { Button } from "@components/Button/Button";
import { FAIcon, FAIconStyles } from "@components/FAIcon/FAIcon";
import { MenuColumnHeader, NAVI_GROUP_LIST_LEFT_PADDING, } from "@components/Header/MonsterNavigation/MonsterNavigation";
import { Input } from "@components/Input/Input";
import { GenericModalBodyContainer, Modal } from "@components/Modal/Modal";
import { SemanticHeader } from "@components/SemanticHeader/SematicHeader";
import { FormContainer } from "./Forms/FormContainer";
var useStandardThemeStyles = createUseStyles({
    newsletter: __assign(__assign({ backgroundColor: colors.gray20, marginBottom: rem(spacings.m), paddingTop: rem(spacings.sam), paddingBottom: rem(spacings.m) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.l),
        paddingTop: rem(spacings.l),
        paddingBottom: rem(spacings.xl),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xxl),
        paddingTop: rem(spacings.xxl),
        paddingBottom: rem(spacings.xxl),
    })),
    newsletterContent: __assign({ display: "flex", alignItems: "center", flexWrap: "wrap" }, onBreakpoint("md", {
        flexWrap: "nowrap",
    })),
    newsletterText: __assign(__assign({ flexBasis: "100%", marginBottom: rem(spacings.sam) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.m),
    })), onBreakpoint("md", {
        flexBasis: "50%",
        marginBottom: 0,
    })),
    newsletterHeadline: __assign({}, customTypography(__assign(__assign({}, typography.h2), { marginBottom: rem(spacings.xs) }), {}, {
        marginBottom: rem(spacings.xs),
    })),
    newsletterDescription: customTypography(typography.h4, { marginBottom: 0 }, { marginBottom: 0 }),
    newsletterForm: __assign(__assign({ display: "flex", flexBasis: "100%", alignItems: "center" }, onBreakpoint("md", {
        flexBasis: "50%",
    })), { "& input": {
            pointerEvents: "none",
        } }),
    newsletterInput: customTypography(__assign(__assign({}, typography.textDefault), { marginRight: rem(spacings.sam), padding: "14px ".concat(spacings.sam) }), { marginBottom: 0 }, { marginBottom: 0 }),
    icon: {},
    button: {},
});
var useNavigationThemeStyles = createUseStyles({
    newsletter: {},
    newsletterContent: __assign({ textAlign: "center" }, onBreakpoint("sm", {
        textAlign: "left",
    })),
    newsletterText: __assign({ marginBottom: rem(spacings.sam) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.m),
    })),
    newsletterHeadline: __assign({}, typography.h4),
    newsletterDescription: __assign({}, onBreakpoint("sm", {
        paddingLeft: NAVI_GROUP_LIST_LEFT_PADDING,
    })),
    newsletterForm: __assign({}, onBreakpoint("sm", {
        paddingLeft: NAVI_GROUP_LIST_LEFT_PADDING,
    })),
    newsletterInput: {},
    icon: FAIconStyles({
        width: 16,
        height: 16,
        marginRight: rem(spacings.xs),
    }),
    button: {
        width: "100%",
        justifyContent: "center",
    },
});
var useFiltersThemeStyles = createUseStyles({
    newsletter: {},
    newsletterContent: __assign({ textAlign: "center" }, onBreakpoint("sm", {
        textAlign: "left",
    })),
    newsletterText: {},
    newsletterHeadline: __assign({}, customTypography(__assign(__assign({}, typography.h4), { marginBottom: rem(spacings.sam) }), {}, { marginBottom: rem(spacings.m) })),
    newsletterDescription: {},
    newsletterForm: {},
    newsletterInput: {},
    icon: FAIconStyles({
        width: 16,
        height: 16,
        marginRight: rem(spacings.xs),
    }),
    button: {
        width: "100%",
        justifyContent: "center",
    },
});
var useOverlayStyles = createUseStyles({
    content: {
        "& .Form__Element:first-child": {
            marginTop: 0,
            "& h3": {
                marginBottom: 0,
            },
        },
    },
});
var NEWSLETTER_ICON = "mailbox";
export function NewsletterBlock(props) {
    var _a;
    var _b = useState(false), isModalOpened = _b[0], setModalOpened = _b[1];
    var isNavigationTheme = props.theme === "navigation";
    var isFilterTheme = props.theme === "filters";
    var _c = props.data, headline = _c.headline, description = _c.description, formArea = _c.formArea, overlayHeadline = _c.overlayHeadline;
    var deviceType = useDeviceType();
    var navigationThemes = useNavigationThemeStyles();
    var filtersThemes = useFiltersThemeStyles();
    var standardThemes = useStandardThemeStyles();
    var styles = isNavigationTheme
        ? navigationThemes
        : isFilterTheme
            ? filtersThemes
            : standardThemes;
    var isMobile = isDeviceResOrLower(deviceType, "mobile");
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, { isFluid: true, className: styles.newsletter, dataTestid: "NewsletterBlock" },
            React.createElement(Container, { isFluid: isNavigationTheme || isFilterTheme },
                React.createElement("div", { className: styles.newsletterContent },
                    React.createElement("div", { className: styles.newsletterText },
                        isNavigationTheme && !isMobile ? (React.createElement(MenuColumnHeader, { icon: NEWSLETTER_ICON, text: headline.value || "" })) : (React.createElement(SemanticHeader, { className: styles.newsletterHeadline, headerSize: (_a = props.data.headerSize) === null || _a === void 0 ? void 0 : _a.value },
                            isNavigationTheme && (React.createElement(FAIcon, { icon: NEWSLETTER_ICON, className: styles.icon })),
                            React.createElement(RenderProperty, { value: headline }))),
                        !isFilterTheme && (React.createElement("div", { className: styles.newsletterDescription },
                            React.createElement(RenderProperty, { value: description })))),
                    React.createElement("div", { className: styles.newsletterForm },
                        !isNavigationTheme && !isFilterTheme && (React.createElement(Input, { type: "email", theme: "white", label: "Enter your e-mail", placeholder: "your.name@email.com", className: styles.newsletterInput, onClick: function () { return setModalOpened(true); }, disabled: true })),
                        React.createElement(Button, { className: styles.button, type: isNavigationTheme || isFilterTheme ? "primary" : "secondary", onClick: function () { return setModalOpened(true); } },
                            React.createElement(LocalizedLabel, { section: "NewsletterSignupTeaser", label: "Button" })))))),
        React.createElement(NewsletterOverlay, { modalOpened: isModalOpened, closeModal: function () { return setModalOpened(false); }, formArea: formArea, overlayHeadline: overlayHeadline })));
}
export function NewsletterOverlay(props) {
    var _a;
    var styles = useOverlayStyles();
    return (React.createElement(Modal, { modalOpened: props.modalOpened, closeModal: props.closeModal, allowMobile: true },
        React.createElement(GenericModalBodyContainer, { heading: props.overlayHeadline },
            React.createElement("div", { className: styles.content }, ((_a = props.formArea.expandedValue) === null || _a === void 0 ? void 0 : _a[0]) && (React.createElement(FormContainer, { data: props.formArea.expandedValue[0] }))))));
}
