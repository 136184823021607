var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import cn from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { animations } from "@basics/animations";
import { colors } from "@basics/colors";
import { Container, onBefore, onBreakpoint, rem } from "@basics/layout";
import { pseudoSelectorShadow } from "@basics/mixins";
import { spacings } from "@basics/spacings";
import { customTypography, typography } from "@basics/typography";
import VisualHeaderCarousel from "@components/Block/VisualHeaderCarousel/VisualHeaderCarousel";
import { FAIcon, FAIconStyles } from "@components/FAIcon/FAIcon";
import { LanguagePicker } from "@components/LanguagePicker/LanguagePicker";
import { MonsterNavigation } from "./MonsterNavigation/MonsterNavigation";
import Search from "./Search/Search";
import { brandColors } from "@basics/brand-colors";
var HEADER_TOP_CONTENT_HEIGHT = 48;
var useStyles = createUseStyles({
    header: function (_a) {
        var liftHeaderUpByPx = _a.liftHeaderUpByPx;
        return ({
            position: "sticky",
            top: 0,
            zIndex: 99,
            transition: "transform .3s ease",
            transform: "translateY(-".concat(liftHeaderUpByPx, "px)"),
        });
    },
    headerMixins: __assign({}, onBefore("sm", {
        transform: "none !important",
    })),
    headerTop: __assign({ backgroundColor: colors.gray20, display: "none" }, onBreakpoint("sm", {
        display: "block",
    })),
    headerTopContent: {
        height: HEADER_TOP_CONTENT_HEIGHT,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& ul": {
            display: "flex",
            padding: 0,
            "& li": __assign(__assign({ listStyle: "none", margin: "0 ".concat(spacings.sam.divide(2)) }, onBreakpoint("md", {
                margin: "0 ".concat(spacings.sam),
            })), { "& a": __assign({}, customTypography(__assign(__assign(__assign({}, typography.textXSmall), { marginBottom: 0, color: colors.gray100, transition: "color 0.3s ease", "&:hover": {
                        color: colors.black,
                    } }), animations.linkChevronEffect), {}, {
                    marginBottom: 0,
                })) }),
        },
    },
    navLeft: {
        display: "flex",
        alignItems: "center",
        "& li:first-child": {
            marginLeft: 0,
        },
    },
    navRight: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    headerBottom: __assign(__assign(__assign({ display: "flex", alignItems: "center", height: 150, backgroundColor: "transparent", position: "absolute", width: "100%" }, onBreakpoint("sm", {
        height: 130,
        transition: "all .3s",
    })), onBreakpoint("md", {
        height: 180,
    })), { "&:after": __assign(__assign({}, pseudoSelectorShadow("0 4px 6px 0 rgba(0, 0, 0, 0.09)")), { height: 0, opacity: function (_a) {
                var openedMenu = _a.openedMenu, openedSearch = _a.openedSearch;
                return openedMenu || openedSearch ? 0 : 1;
            }, transition: "all .3s" }) }),
    collapsed: __assign(__assign(__assign({ backgroundColor: colors.white, borderBottom: "1px solid ".concat(colors.gray20), height: 64, "&:after": {
            height: 12,
        } }, onBreakpoint("sm", {
        borderBottom: 0,
    })), onBreakpoint("md", {
        height: 80,
    })), { "& $logo": {
            left: 0,
            "& img": __assign(__assign({ width: 42, height: 17, padding: 0, backgroundColor: "transparent" }, onBreakpoint("sm", {
                width: 60,
                height: 24,
            })), onBreakpoint("md", {
                width: 72,
                height: 29,
            })),
        }, "& $mainMenu span": {
            color: colors.gray100,
        }, "& $hamburgerIcon div": {
            background: brandColors.black,
        }, "& $search": {
            color: brandColors.black,
        }, "& $user": {
            color: brandColors.black,
        } }),
    headerBottomContent: {
        display: "flex",
        alignItems: "center",
        position: "relative",
    },
    logo: __assign(__assign({ display: "flex", position: "absolute", left: "calc((max((-1 * (1920px - min(100vw, 1504px))), (-1 * (100vw - min(100vw, 1504px)))) / 2) - 16px)" }, onBreakpoint("sm", {
        left: "calc((max((-1 * (1920px - min(100vw, 1504px))), (-1 * (100vw - min(100vw, 1504px)))) / 2) - 32px)",
        transition: "all .3s",
    })), { "& img": __assign(__assign({ width: 119, height: 67, padding: "0 21px 0 42px", backgroundColor: brandColors.white }, onBreakpoint("sm", {
            width: 166,
            height: 93,
            padding: "0 29px 0 59px",
            transition: "all .3s",
        })), onBreakpoint("md", {
            width: 241,
            height: 135,
            padding: "0 43px 0 86px",
        })) }),
    search: __assign(__assign(__assign({ color: brandColors.white, marginLeft: "auto", marginRight: 36, cursor: "pointer" }, onBreakpoint("sm", {
        transition: "all .3s",
    })), FAIconStyles({
        width: 25,
        height: 25,
    })), onBefore("md", __assign({ marginRight: 24 }, FAIconStyles({
        width: 20,
        height: 20,
    })))),
    userMargin: {
        marginRight: 36,
    },
    user: __assign(__assign(__assign({ color: brandColors.white, cursor: "pointer" }, onBreakpoint("sm", {
        transition: "all .3s",
    })), FAIconStyles({
        width: 25,
        height: 25,
    })), onBefore("md", __assign({}, FAIconStyles({
        width: 20,
        height: 20,
    })))),
    mainMenu: {
        padding: 0,
        marginLeft: function (_a) {
            var isSearchField = _a.isSearchField;
            return (isSearchField ? 0 : "auto");
        },
        border: "none",
        font: "inherit",
        color: "inherit",
        textAlign: "inherit",
        background: "none",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        "& span": __assign(__assign(__assign(__assign({}, typography.textLarge), { color: brandColors.white, marginBottom: 0, display: "none" }), onBreakpoint("sm", {
            marginRight: rem(spacings.sam.divide(2)),
            display: "block",
            transition: "all .3s",
            fontSize: 20,
        })), onBreakpoint("md", {
            fontSize: 24,
        })),
        "& svg": {
            color: brandColors.white,
        },
    },
    hamburgerIcon: function (_a) {
        var openedMenu = _a.openedMenu;
        return ({
            width: 21,
            height: 12,
            position: "relative",
            cursor: "pointer",
            "& div": __assign(__assign({ position: "absolute", height: 2, width: "100%", background: brandColors.white, opacity: 1, left: 0 }, onBreakpoint("sm", {
                transition: "all .3s",
            })), { "&:nth-child(1)": {
                    top: openedMenu ? 7 : 0,
                    left: openedMenu ? "50%" : 0,
                    width: openedMenu ? "0%" : "100%",
                }, "&:nth-child(2)": {
                    top: 7,
                    transform: openedMenu ? "rotate(45deg)" : "rotate(0deg)",
                }, "&:nth-child(3)": {
                    top: 7,
                    transform: openedMenu ? "rotate(-45deg)" : "rotate(0deg)",
                    opacity: openedMenu ? 1 : 0,
                }, "&:nth-child(4)": {
                    top: openedMenu ? 7 : 14,
                    left: openedMenu ? "50%" : 0,
                    width: openedMenu ? "0%" : "100%",
                } }),
        });
    },
    contactUsButtonWrapper: __assign({ marginRight: rem(spacings.m), opacity: 0, transition: "opacity 0.65s ease", pointerEvents: "none" }, onBefore("sm", {
        display: "none",
    })),
    contactUsButtonWrapperShow: {
        opacity: 1,
        pointerEvents: "auto",
    },
});
export function StartPageHeader(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var _j = useState(0), liftHeaderUpByPx = _j[0], setLiftHeaderUpByPx = _j[1];
    var menuToggler = useRef(null);
    var localeSuggestionWrapperRef = useRef(null);
    var scrollY = useRef(0);
    useScrollPosition(function (_a) {
        var _b, _c, _d;
        var currPos = _a.currPos, prevPos = _a.prevPos;
        if (currPos.y === prevPos.y) {
            return;
        }
        var newVisibilityStatus = currPos.y < prevPos.y || currPos.y < 0 || prevPos.y < 0;
        var localeSuggestionWrapperHeight = ((_c = (_b = localeSuggestionWrapperRef === null || localeSuggestionWrapperRef === void 0 ? void 0 : localeSuggestionWrapperRef.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect()) === null || _c === void 0 ? void 0 : _c.height) ||
            0;
        setLiftHeaderUpByPx(newVisibilityStatus || !((_d = props.topBar) === null || _d === void 0 ? void 0 : _d.value)
            ? 0
            : localeSuggestionWrapperHeight + HEADER_TOP_CONTENT_HEIGHT);
        scrollY.current = currPos.y;
        setHeaderElementToggle(function (s) { return ({
            openedMenu: s.openedMenu,
            openedSearch: s.openedSearch,
            menuCollapse: document.body.style.position === "static"
                ? currPos.y > 0
                : s.menuCollapse,
        }); });
    }, [liftHeaderUpByPx], undefined, true, 0);
    var _k = useState({
        openedMenu: false,
        openedSearch: false,
        menuCollapse: false,
    }), headerElementToggle = _k[0], setHeaderElementToggle = _k[1];
    function menuToggle() {
        setHeaderElementToggle(function (s) { return ({
            openedSearch: false,
            openedMenu: !s.openedMenu,
            menuCollapse: scrollY.current === 0 && !headerElementToggle.openedSearch
                ? !s.menuCollapse
                : s.menuCollapse,
        }); });
    }
    function searchToggle() {
        setHeaderElementToggle(function (s) { return ({
            openedSearch: !s.openedSearch,
            openedMenu: false,
            menuCollapse: scrollY.current === 0 && !headerElementToggle.openedMenu
                ? !s.menuCollapse
                : s.menuCollapse,
        }); });
    }
    useEffect(function () {
        headerElementToggle.openedMenu || headerElementToggle.openedSearch
            ? (document.body.style.position = "fixed")
            : (document.body.style.position = "static");
    });
    var searchRef = useRef(null);
    function handleClickOutside(event) {
        if (menuToggler.current && menuToggler.current.contains(event.target)) {
            setHeaderElementToggle(function (s) { return ({
                openedMenu: s.openedMenu,
                openedSearch: false,
                menuCollapse: s.menuCollapse,
            }); });
        }
        else if (searchRef.current && !searchRef.current.contains(event.target)) {
            headerElementToggle.openedSearch &&
                setHeaderElementToggle(function (s) { return ({
                    openedMenu: s.openedMenu,
                    openedSearch: false,
                    menuCollapse: false,
                }); });
        }
    }
    useEffect(function () {
        document.addEventListener("click", handleClickOutside);
        return function () {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
    var openedMenu = headerElementToggle.openedMenu;
    var openedSearch = headerElementToggle.openedSearch;
    var isSearchField = props.searchField.value;
    var styles = useStyles({
        liftHeaderUpByPx: liftHeaderUpByPx,
        openedMenu: openedMenu,
        openedSearch: openedSearch,
        isSearchField: isSearchField,
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("header", { className: cn(styles.header, styles.headerMixins), "data-testid": "header" },
            ((_a = props.topBar) === null || _a === void 0 ? void 0 : _a.value) && (React.createElement("div", { className: styles.headerTop },
                React.createElement(Container, null,
                    React.createElement("div", { className: styles.headerTopContent },
                        React.createElement("nav", { className: styles.navLeft },
                            React.createElement("ul", null, (_b = props.metaNavigationLeft.value) === null || _b === void 0 ? void 0 : _b.map(function (l, idx) { return (React.createElement("li", { key: idx },
                                React.createElement("a", { href: l.href, title: l.title, target: l.target }, l.text))); }))),
                        React.createElement("nav", { className: styles.navRight },
                            React.createElement("ul", null, (_c = props.metaNavigationRight.value) === null || _c === void 0 ? void 0 : _c.map(function (l, idx) { return (React.createElement("li", { key: idx },
                                React.createElement("a", { href: l.href, title: l.title, target: l.target }, l.text))); })),
                            props.languagePicker.value && React.createElement(LanguagePicker, null)))))),
            React.createElement("div", { className: cn(styles.headerBottom, headerElementToggle.menuCollapse ? styles.collapsed : undefined) },
                React.createElement(Container, null,
                    React.createElement("div", { className: styles.headerBottomContent },
                        React.createElement("a", { href: props.logoLink.value ? props.logoLink.value : undefined, className: styles.logo },
                            React.createElement("img", { src: ((_d = props.logo.value) === null || _d === void 0 ? void 0 : _d.url) || undefined, alt: "logo" })),
                        isSearchField && (React.createElement(React.Fragment, null, headerElementToggle.openedSearch ? (React.createElement(FAIcon, { icon: "times", className: styles.search, onClick: searchToggle, "aria-controls": "search" })) : (React.createElement(FAIcon, { icon: "search", className: styles.search, onClick: searchToggle, "aria-controls": "search" })))),
                        ((_e = props.userLogin) === null || _e === void 0 ? void 0 : _e.value) &&
                            props.businessPortalLoginLink.value && (React.createElement("a", { href: props.businessPortalLoginLink.value, className: styles.userMargin },
                            React.createElement(FAIcon, { icon: "user", className: styles.user, "aria-controls": "login" }))),
                        ((_f = props.navigationMenu) === null || _f === void 0 ? void 0 : _f.value) && (React.createElement("button", { "aria-controls": "main-menu", className: styles.mainMenu, ref: menuToggler, onClick: menuToggle, onMouseUp: function () {
                                return menuToggler.current && menuToggler.current.blur();
                            } },
                            React.createElement("span", null, "Menu"),
                            React.createElement("div", { className: styles.hamburgerIcon, "aria-hidden": true },
                                React.createElement("div", null),
                                React.createElement("div", null),
                                React.createElement("div", null),
                                React.createElement("div", null))))))),
            React.createElement(MonsterNavigation, { isOpened: headerElementToggle.openedMenu, languagePicker: props.languagePicker, isStartPage: true }),
            React.createElement("div", { ref: searchRef },
                React.createElement(Search, { isOpened: headerElementToggle.openedSearch, searchPage: props.searchPage, homepageSearch: (_g = props.homepageSearch) === null || _g === void 0 ? void 0 : _g.expandedValue, isStartPage: true }))),
        React.createElement(VisualHeaderCarousel, { items: (_h = props.homepageCarousel) === null || _h === void 0 ? void 0 : _h.expandedValue, menuCollapsed: headerElementToggle.menuCollapse })));
}
