var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { RenderProperty } from "@/src/views/RenderProperty";
import { Row, Col, rem, onBreakpoint, CONTAINER_OFFSET_WIDTHS, Container, onBefore, } from "@basics/layout";
import { createUseStyles } from "react-jss";
import { typography, customTypography } from "@basics/typography";
import cn from "classnames";
import { spacings } from "@basics/spacings";
import { ButtonWrapper } from "@components/Button/Button";
import { AnimatedRevealBlock } from "@components/AnimatedReveal/AnimatedReveal";
import { useStyles } from "./styles";
export function ContentOptionsBlock(props) {
    var styles = useStyles();
    var contentOptionsItems = props.data.contentOptionsItems;
    var options = contentOptionsItems.value;
    var numberOfElements = (options && options.length) || 0;
    var isThree = numberOfElements === 3;
    var isSingle = numberOfElements === 1;
    var isEven = !isSingle && !isThree;
    var evenStyleMap = [
        styles.darkItem,
        styles.lightItem,
        styles.mobileDarkTabletDesktopLightItem,
        styles.mobileLightTabletDesktopLDarkItem,
    ];
    var threeItemStyleMap = [
        styles.darkVeryDarkItem,
        styles.darkItem,
        styles.lightItem,
    ];
    var singleStyleMap = [styles.darkItem];
    var styleMap = isEven
        ? evenStyleMap
        : isThree
            ? threeItemStyleMap
            : singleStyleMap;
    var rows = numberOfElements > 3 ? 2 : 1;
    var divider = isEven ? 2 : 3;
    return (React.createElement("div", { className: styles.element }, new Array(rows).fill("").map(function (_, idx) { return (React.createElement(Container, { key: idx },
        React.createElement(Row, null,
            numberOfElements >= (idx + 1) * 2 && (React.createElement(React.Fragment, null,
                React.createElement(Col, { sm: isThree ? 4 : 6, className: cn(styles.backgroundRowColumn, styles.left, isThree && styles.isThree) },
                    React.createElement("div", { className: styleMap[idx * 2] })),
                React.createElement(Col, { sm: isThree ? 4 : 6, className: cn(styles.backgroundRowColumn, styles.right, isThree && styles.isThree) },
                    React.createElement("div", { className: styleMap[idx * 2 + divider - 1] })))),
            (isSingle || isThree) && (React.createElement(Col, { sm: 10, smStart: 1, md: 8, mdStart: 2, className: cn(styles.backgroundRowColumn, isSingle && styles.isSingle, isThree && styles.isThree, isThree && styles.threeBackgroundItem) },
                React.createElement("div", { className: cn(isThree ? styleMap[1] : styleMap[0]) }))), options === null || options === void 0 ? void 0 :
            options.filter(function (_, iidx) {
                return Math.floor(iidx / divider) === idx;
            }).map(function (item, iidx) {
                var index = idx * 2 + iidx;
                return (React.createElement(Card, __assign({ isSingle: isSingle, isThree: isThree, first: iidx === 0, last: iidx === divider - 1, backgroundStyle: styleMap[index] || "", key: iidx, revealDelay: iidx * 200 }, item)));
            })))); })));
}
var useCardStyles = createUseStyles({
    first: __assign({ gridColumnStart: "auto" }, onBreakpoint("sm", __assign({ gridColumnStart: 1, paddingRight: rem(spacings.sam) }, onBreakpoint("md", {
        paddingRight: 0,
    })))),
    last: {
        "&$isEven": __assign(__assign({ gridColumnStart: "auto" }, onBreakpoint("sm", {
            gridColumnStart: 7,
            paddingLeft: rem(spacings.sam),
        })), onBreakpoint("md", {
            gridColumnStart: 8,
            paddingLeft: 0,
        })),
    },
    isEven: {
        "&$last": onBefore("sm", {
            gridRow: 2,
        }),
    },
    isSingle: __assign(__assign({ gridColumnStart: "auto" }, onBreakpoint("sm", {
        gridColumnStart: 2,
        paddingLeft: rem(spacings.xl),
        paddingRight: rem(spacings.xl),
    })), onBreakpoint("md", {
        gridColumnStart: 4,
        padding: 0,
    })),
    isThree: __assign(__assign({ gridRow: 2, "&$first": {
            gridRow: 1,
        }, "&$last": {
            gridRow: 3,
        } }, onBreakpoint("sm", {
        gridColumnStart: 2,
        paddingLeft: rem(spacings.xl),
        paddingRight: rem(spacings.xl),
    })), onBreakpoint("md", {
        gridRow: 1,
        gridColumnStart: 5,
        "&$first": {
            gridRow: 1,
            gridColumnStart: 1,
        },
        "&$last": {
            gridRow: 1,
            gridColumnStart: 9,
        },
        paddingLeft: rem(spacings.l.add(spacings.xs)),
        paddingRight: rem(spacings.l.add(spacings.xs)),
    })),
    card: __assign(__assign(__assign({ display: "flex", flexDirection: "column", paddingTop: rem(spacings.l), paddingBottom: rem(spacings.l) }, onBreakpoint("sm", {
        paddingTop: rem(spacings.xl),
        paddingBottom: rem(spacings.xl),
        "&:not($isThree)": {
            gridRow: 1,
        },
    })), onBreakpoint("md", {
        paddingTop: rem(spacings.m.times(2)),
        paddingBottom: rem(spacings.m.times(2)),
    })), onBefore("sm", {
        paddingLeft: rem(spacings.s),
        paddingRight: rem(spacings.s),
    })),
    caption: __assign({}, customTypography(__assign(__assign({}, typography.caption), { marginBottom: rem(spacings.xs) }), {}, {
        marginBottom: rem(spacings.s),
    })),
    headline: typography.h2,
    mobileBackground: __assign({ position: "absolute", height: "100%", width: "100vw", top: 0, left: "-".concat(CONTAINER_OFFSET_WIDTHS["small"], "px"), zIndex: -1 }, onBreakpoint("sm", {
        display: "none",
    })),
    description: customTypography(typography.textDefault, { marginBottom: rem(spacings.m) }, { marginBottom: rem(spacings.xl) }, { marginBottom: rem(spacings.m.times(2)) }),
    buttons: {
        marginTop: "auto",
    },
    cardButton: {
        "&:first-of-type": {
            marginRight: rem(spacings.sam),
        },
        "&:not(:first-of-type)": {
            marginTop: rem(spacings.s),
        },
    },
    fullHeight: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
});
function Card(props) {
    var _a;
    var styles = useCardStyles();
    var mdSize = props.isThree ? 4 : props.isSingle ? 6 : 5;
    var isEven = !props.isSingle && !props.isThree;
    return (React.createElement(Col, { sm: isEven ? 6 : 10, md: mdSize, className: cn(props.first && styles.first, props.last && styles.last, props.isSingle && styles.isSingle, props.isThree && styles.isThree, isEven && styles.isEven, props.backgroundStyle, styles.card) },
        React.createElement(AnimatedRevealBlock, { revealDelay: props.revealDelay, className: styles.fullHeight },
            React.createElement("div", { className: cn(styles.mobileBackground, props.backgroundStyle) }),
            React.createElement(RenderProperty, { value: props.caption, className: styles.caption }),
            React.createElement(RenderProperty, { value: props.headline, className: styles.headline }),
            React.createElement(RenderProperty, { value: props.description, className: styles.description }),
            React.createElement("div", { className: styles.buttons }, (_a = props.buttons.value) === null || _a === void 0 ? void 0 : _a.map(function (button, idx) { return (React.createElement(ButtonWrapper, __assign({}, button, { key: idx, className: styles.cardButton }))); })))));
}
