var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import Slider from "react-slick";
import { brandColors } from "@basics/brand-colors";
import { onBefore, rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { CloudinaryPlayerContext } from "@/src/context/cloudinaryPlayerContext";
import { useDeviceType } from "@hooks/useDeviceType";
import { ImageComponent } from "@components/Block/ImageBlock/ImageComponent";
import { VideoComponent } from "@components/Video/VideoComponent";
import { SvgComponent } from "@components/Svg/SvgComponent";
var useStyles = createUseStyles({
    cardCarousel: __assign(__assign({ width: "calc(100% - 80px)" }, onBefore("md", {
        width: function () { return "auto"; },
    })), { margin: "0 auto", "& .slick-track": {
            display: "flex",
            marginBottom: rem(spacings.l),
        }, "& .slick-slide": {
            "& > div": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                margin: "0 ".concat(spacings.xs.add(spacings.xxs)),
            },
            opacity: 1,
            "&.slick-center": {
                opacity: 1,
            },
        }, "& .slick-arrow": {
            width: 48,
            height: 48,
            "&:before": {
                color: brandColors.universalGreen,
                fontSize: "3rem",
            },
        }, "& .slick-prev": {
            left: -50,
            "&:before": {
                fontFamily: "Font Awesome\\ 5 Pro",
                content: '"\\f053"',
            },
        }, "& .slick-next": {
            right: -50,
            "&:before": {
                fontFamily: "Font Awesome\\ 5 Pro",
                content: '"\\f054"',
            },
        }, "& .slick-dots": {
            bottom: 0,
            "& button:before, & .slick-active button:before": {
                fontFamily: "Font Awesome\\ 5 Pro",
                content: '"\\f111"',
                fontWeight: 900,
                fontSize: "1rem",
                color: brandColors.universalGreen,
            },
        }, "& .slick-disabled": {
            cursor: "default",
        } }),
});
export var isVideoBlockCloudinary = function (block) {
    var _a;
    return ((_a = block.contentType) === null || _a === void 0 ? void 0 : _a.at(1)) === "VideoBlockCloudinary";
};
export var isImageBlock = function (block) {
    var _a;
    return ((_a = block.contentType) === null || _a === void 0 ? void 0 : _a.at(1)) === "ImageBlock";
};
export var isSvgBlock = function (block) {
    var _a;
    return ((_a = block.contentType) === null || _a === void 0 ? void 0 : _a.at(1)) === "SvgBlock";
};
export var MediaCarouselComponent = function (props) {
    var _a;
    var media = props.data.media;
    var styles = useStyles();
    var _b = useState(null), player = _b[0], setPlayer = _b[1];
    var deviceType = useDeviceType();
    var isMobile = deviceType === "mobile" || deviceType === "tablet";
    var carouselSettings = {
        dots: true,
        arrows: !isMobile,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        beforeChange: function () {
            if (player) {
                player.stop();
            }
        },
    };
    return (React.createElement(CloudinaryPlayerContext.Provider, { value: { player: player, setPlayer: setPlayer } },
        React.createElement(Slider, __assign({}, carouselSettings, { className: styles.cardCarousel }), (_a = media.expandedValue) === null || _a === void 0 ? void 0 : _a.map(function (item) {
            return React.createElement("div", { key: item.__key__ }, renderMediaComponent(item));
        }))));
};
var renderMediaComponent = function (item) {
    if (isVideoBlockCloudinary(item)) {
        return React.createElement(VideoComponent, { data: item });
    }
    else if (isImageBlock(item)) {
        return React.createElement(ImageComponent, { data: item });
    }
    else if (isSvgBlock(item)) {
        return React.createElement(SvgComponent, { data: item });
    }
    else {
        return null;
    }
};
