var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createUseStyles } from "react-jss";
import { spacings } from "@basics/spacings";
import { colors } from "@basics/colors";
import { onBefore, onBreakpoint, rem } from "@basics/layout";
import { customTypography, typography } from "@basics/typography";
import React, { useState } from "react";
import { RenderProperty } from "@/src/views/RenderProperty";
import { Button } from "@components/Button/Button";
import { LocalizedLabel } from "@hooks/LocalizationContext";
import { FAIcon } from "@components/FAIcon/FAIcon";
import cn from "classnames";
var downloadItemStyles = createUseStyles({
    downloadItem: __assign(__assign({ display: "flex", flexWrap: "wrap", padding: "".concat(spacings.sam, " 0 ").concat(spacings.sam), borderBottom: "1px solid ".concat(colors.gray20) }, onBreakpoint("sm", {
        padding: "".concat(spacings.sam, " 0 ").concat(spacings.m),
    })), { "&:first-child": __assign(__assign({}, onBefore("md", {
            borderTop: "1px solid ".concat(colors.gray20),
        })), onBreakpoint("md", {
            paddingTop: 0,
        })) }),
    infoWrapper: __assign({}, onBefore("sm", {
        flexBasis: "100%",
    })),
    itemInfo: __assign(__assign({}, typography.caption), { flexBasis: "100%", marginBottom: rem(spacings.xs) }),
    title: customTypography(__assign(__assign({}, typography.h4), { marginRight: 0 }), {}, { marginBottom: 0 }),
    downloadButton: __assign({ alignSelf: "center", marginLeft: "auto", marginTop: rem(spacings.xs) }, onBefore("sm", {
        marginLeft: "unset",
    })),
    languagesButton: {
        padding: 0,
        background: "none",
        display: "flex",
        flexBasis: "100%",
        alignItems: "center",
        marginTop: rem(spacings.sam),
        outline: "none",
    },
    buttonText: __assign({}, customTypography(__assign(__assign({}, typography.textSmall), { marginBottom: 0, marginRight: rem(spacings.s) }), {}, { marginBottom: 0 })),
    languagesList: function (_a) {
        var opened = _a.opened;
        return ({
            width: "100%",
            margin: 0,
            maxHeight: opened ? "unset" : 0,
            opacity: opened ? 1 : 0,
            pointerEvents: opened ? "all" : "none",
            transition: "opacity 0.3s ease, max-height 0.3s ease",
            overflow: "hidden",
        });
    },
    divider: {
        margin: "0 ".concat(spacings.xs),
    },
});
function findExtension(url) {
    url = typeof url === "string" ? url : url.value || "";
    return url.split(".").pop();
}
export function DownloadItem(props) {
    var _a, _b, _c, _d, _e;
    var _f = useState(false), opened = _f[0], setOpened = _f[1];
    var styles = downloadItemStyles({ opened: opened });
    return (React.createElement("div", { className: cn(styles.downloadItem, props.className) },
        React.createElement("div", { className: styles.infoWrapper },
            React.createElement("span", { className: styles.itemInfo },
                findExtension(props.url || ""),
                React.createElement("span", { className: styles.divider }, "|"),
                React.createElement(RenderProperty, { value: props.fileSize }),
                ((_a = props.isFeatured) === null || _a === void 0 ? void 0 : _a.value) && !((_b = props.context) === null || _b === void 0 ? void 0 : _b.disableFeaturedOption) && (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: styles.divider }, "|"),
                    React.createElement(LocalizedLabel, { section: "Download", label: "Featured" })))),
            React.createElement("h4", { className: styles.title },
                React.createElement(RenderProperty, { value: ((_c = props.title) === null || _c === void 0 ? void 0 : _c.value) ? props.title : props.name }))),
        React.createElement(Button, { type: "primary", icon: "arrow-to-bottom", className: styles.downloadButton, href: props.url || "", target: "_blank" },
            React.createElement(LocalizedLabel, { section: "Download", label: "Button" })),
        ((_d = props.localizedFiles) === null || _d === void 0 ? void 0 : _d.length) && (React.createElement("button", { className: styles.languagesButton, onClick: function () { return setOpened(function (s) { return !s; }); }, "data-testid": "DownloadItemShowMore" },
            React.createElement("span", { className: styles.buttonText }, opened ? (React.createElement(LocalizedLabel, { section: "Download", label: "ShowLess" })) : (React.createElement(LocalizedLabel, { section: "Download", label: "ShowMore" }))),
            opened ? (React.createElement(FAIcon, { icon: "chevron-up", size: "lg" })) : (React.createElement(FAIcon, { icon: "chevron-down", size: "lg" })))),
        React.createElement("ul", { className: styles.languagesList, "aria-expanded": opened }, (_e = props.localizedFiles) === null || _e === void 0 ? void 0 : _e.map(function (lf, idx) { return (React.createElement(LocalizedDownloadItem, __assign({}, lf, { key: idx }))); }))));
}
var localizedDownloadItemStyles = createUseStyles({
    localizedDownloadItem: {
        marginTop: rem(spacings.sam),
        display: "flex",
        alignItems: "center",
    },
    downloadIcon: {
        padding: 0,
        background: "none",
        outline: "none",
        marginRight: rem(spacings.s),
    },
    itemInfo: __assign(__assign({}, typography.caption), { flexBasis: "100%" }),
    title: customTypography(__assign({}, typography.h5), { marginBottom: 0 }, { marginBottom: 0 }),
    divider: {
        margin: "0 ".concat(spacings.xs),
    },
});
function LocalizedDownloadItem(props) {
    var _a;
    var styles = localizedDownloadItemStyles();
    return (React.createElement("li", { className: styles.localizedDownloadItem },
        React.createElement("a", { className: styles.downloadIcon, href: props.url || "", target: "_blank noreferer noopener" },
            React.createElement(FAIcon, { icon: "arrow-to-bottom", size: "2x" })),
        React.createElement("div", null,
            React.createElement("span", { className: styles.itemInfo },
                findExtension(props.url || ""),
                React.createElement("span", { className: styles.divider }, "|"),
                React.createElement(RenderProperty, { value: props.fileSize })),
            React.createElement("h5", { className: styles.title },
                React.createElement(RenderProperty, { value: ((_a = props.title) === null || _a === void 0 ? void 0 : _a.value) ? props.title : props.name })))));
}
