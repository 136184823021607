var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cn from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";
import { colors } from "@basics/colors";
import { Col, Container, onBreakpoint, rem, Row } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { customTypography, typography } from "@basics/typography";
import { AnimatedRenderProperty, ShowPropertyOnEdit, } from "@/src/views/RenderProperty";
import { FAIcon, FAIconStyles } from "@components/FAIcon/FAIcon";
import { SemanticHeader } from "@components/SemanticHeader/SematicHeader";
var useStyles = createUseStyles({
    quickLinks: __assign(__assign({ position: "relative", marginBottom: rem(spacings.m) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.l),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xxl),
    })),
    textContainer: {
        marginBottom: rem(spacings.s),
    },
    headline: __assign(__assign({}, typography.h2), { textAlign: "center" }),
    description: customTypography(__assign(__assign({}, typography.textDefault), { textAlign: "center" }), { marginBottom: rem(spacings.s) }, { marginBottom: rem(spacings.sam) }, { marginBottom: rem(spacings.m) }),
    cardContainer: __assign(__assign({ display: "flex", justifyContent: "center", flexWrap: function (itemsAmount) { return (itemsAmount > 3 ? "wrap" : "nowrap"); } }, onBreakpoint("sm", {
        flexWrap: function (itemsAmount) { return (itemsAmount > 5 ? "wrap" : "nowrap"); },
    })), onBreakpoint("md", {
        flexWrap: function () { return "nowrap"; },
    })),
    card: __assign(__assign({ flexBasis: function (itemsAmount) {
            switch (itemsAmount) {
                case 5:
                case 6:
                    return "33%";
                case 4:
                    return "50%";
                default:
                    return 200;
            }
        }, maxWidth: 246, marginBottom: rem(spacings.m), padding: "".concat(rem(spacings.m), " ").concat(spacings.s), textAlign: "center", borderLeft: "1px solid ".concat(colors.gray30), cursor: "pointer", backgroundColor: "transparent", transition: "background-color 0.3s ease", "&:hover": {
            backgroundColor: colors.gray20,
            transition: "background-color 0.3s ease",
            "& $cardIcon": {
                transform: "scale(1.1)",
                transition: "transform 0.3s ease",
            },
        }, "&:nth-child(2)": {
            borderRight: function (itemsAmount) {
                return itemsAmount === 4 ? "1px solid ".concat(colors.gray30) : "none";
            },
        }, "&:nth-child(3)": {
            borderRight: function (itemsAmount) {
                return itemsAmount > 4 ? "1px solid ".concat(colors.gray30) : "none";
            },
        }, "&:last-child": {
            borderRight: function () { return "1px solid ".concat(colors.gray30); },
        } }, onBreakpoint("sm", {
        flexBasis: function (itemsAmount) { return (itemsAmount > 5 ? "25%" : 220); },
        maxWidth: "unset",
        hyphens: "auto",
        marginBottom: rem(spacings.l),
        padding: "".concat(rem(spacings.l), " ").concat(spacings.s),
        borderRight: 0,
        "&:nth-child(2), &:nth-child(3)": {
            borderRight: function () { return "none"; },
        },
        "&:nth-child(4)": {
            borderRight: function (itemAmount) {
                return itemAmount > 5 ? "1px solid ".concat(colors.gray30) : "none";
            },
        },
        "&:last-child": {
            borderRight: function () { return "1px solid ".concat(colors.gray30); },
        },
    })), onBreakpoint("md", {
        marginBottom: 0,
        padding: "".concat(rem(spacings.xl), " ").concat(spacings.sam),
        flexBasis: function () { return 288; },
        "&:nth-child(4)": {
            borderRight: function () { return "none"; },
        },
        "&:last-child": {
            borderRight: function () { return "1px solid ".concat(colors.gray30); },
        },
    })),
    cardIcon: __assign({ color: colors.lightGreen40, marginBottom: rem(spacings.m), transform: "scale(1)", transition: "transform 0.3s ease" }, FAIconStyles({
        width: 64,
        height: 64,
    })),
    cardTitle: customTypography(__assign(__assign({}, typography.h4), { hyphens: "auto", wordBreak: "break-all" }), { marginBottom: 0 }, { marginBottom: 0 }, { marginBottom: 0 }),
    homepage: __assign(__assign({ backgroundColor: colors.gray10, paddingTop: rem(spacings.m) }, onBreakpoint("sm", {
        paddingTop: rem(spacings.l),
    })), onBreakpoint("md", {
        paddingTop: rem(spacings.xxl),
        paddingBottom: rem(spacings.xxl),
    })),
    noBorders: {
        border: "none !important",
    },
});
export function QuickLinksBlock(props) {
    var _a, _b, _c, _d;
    var itemsAmount = (_a = props.data.links.value) === null || _a === void 0 ? void 0 : _a.length;
    var styles = useStyles(itemsAmount);
    var isHomepage = props.theme === "homepage";
    var isGrayBg = ((_b = props.data.backgroundColor) === null || _b === void 0 ? void 0 : _b.value) === "gray";
    return (React.createElement(Container, { className: cn(styles.quickLinks, (isHomepage || isGrayBg) && styles.homepage), isFluid: isHomepage || isGrayBg },
        React.createElement(Row, null,
            React.createElement(Col, { sm: 10, smStart: 1, md: 8, mdStart: 2, className: styles.textContainer },
                ShowPropertyOnEdit(props.data.headline) && (React.createElement(SemanticHeader, { className: styles.headline, headerSize: (_c = props.data.headerSize) === null || _c === void 0 ? void 0 : _c.value },
                    React.createElement(AnimatedRenderProperty, { value: props.data.headline }))),
                ShowPropertyOnEdit(props.data.description) && (React.createElement("div", { className: styles.description },
                    React.createElement(AnimatedRenderProperty, { value: props.data.description }))))),
        React.createElement(Row, null,
            React.createElement(Col, null,
                React.createElement("div", { className: styles.cardContainer }, (_d = props.data.links.value) === null || _d === void 0 ? void 0 : _d.map(function (l, idx) { return (React.createElement(Card, __assign({}, l, { itemsAmount: itemsAmount || 0, theme: props.theme, key: idx, revealDelay: 100 * idx }))); }))))));
}
function Card(props) {
    var icon = props.icon, link = props.link, itemsAmount = props.itemsAmount;
    var styles = useStyles(itemsAmount);
    var firstLink = link === null || link === void 0 ? void 0 : link[0];
    return (React.createElement("a", { href: firstLink === null || firstLink === void 0 ? void 0 : firstLink.href, className: cn(styles.card, props.theme === "homepage" && styles.noBorders), target: firstLink === null || firstLink === void 0 ? void 0 : firstLink.target },
        React.createElement(FAIcon, { icon: icon, className: styles.cardIcon }),
        React.createElement("h4", { className: styles.cardTitle },
            React.createElement(AnimatedRenderProperty, { value: firstLink === null || firstLink === void 0 ? void 0 : firstLink.text, revealDelay: props.revealDelay }))));
}
