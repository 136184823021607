import { useState } from "react";
import { isClientSide } from "@basics/isClientSide";
var DEFER_TIME = 500;
export function useDebounce() {
    var setTimer = useState(null)[1];
    function debounce(baseFunc) {
        var newTimer = (isClientSide() &&
            window.setTimeout(function () {
                baseFunc();
                setTimer(null);
            }, DEFER_TIME)) ||
            null;
        setTimer(function (timer) {
            if (timer)
                window.clearTimeout(timer);
            return newTimer;
        });
    }
    function debouncePromise(basePromiseFunction) {
        var outResolve = null;
        var promise = new Promise(function (resolve) {
            outResolve = function () { return resolve(undefined); };
        });
        var newTimer = (isClientSide() &&
            window.setTimeout(function () {
                outResolve && outResolve();
                setTimer(null);
            }, DEFER_TIME)) ||
            null;
        setTimer(function (timer) {
            if (timer)
                window.clearTimeout(timer);
            return newTimer;
        });
        return promise.then(basePromiseFunction);
    }
    return { debounce: debounce, debouncePromise: debouncePromise };
}
