var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { onBreakpoint, rem } from "@basics/layout";
import { createUseStyles } from "react-jss";
import { customTypography, typography } from "@basics/typography";
import { spacings } from "@basics/spacings";
import { ButtonWrapper } from "@components/Button/Button";
import { RenderProperty } from "@/src/views/RenderProperty";
var useStyles = createUseStyles({
    container: __assign({ margin: "".concat(spacings.xs, " 0") }, onBreakpoint("sm", {
        margin: 0,
    })),
    headline: __assign({}, customTypography(__assign(__assign({}, typography.h4), { marginBottom: 0 }), {
        marginTop: rem(spacings.sam),
        marginBottom: 0,
    }, {
        marginTop: rem(spacings.m),
        marginBottom: 0,
    })),
    button: {},
});
var useFilterStyles = createUseStyles({
    container: __assign({ margin: "".concat(spacings.xs, " 0") }, onBreakpoint("sm", {
        margin: 0,
    })),
    headline: __assign({}, customTypography(__assign(__assign({}, typography.h4), { marginBottom: 0 }), {
        marginTop: rem(spacings.sam),
        marginBottom: 0,
    }, {
        marginTop: rem(spacings.m),
        marginBottom: 0,
    })),
    button: __assign({ marginTop: rem(spacings.sam) }, onBreakpoint("sm", {
        marginTop: rem(spacings.m),
    })),
});
export function ImageTeaserBlock(props) {
    var _a;
    var navigationStyles = useStyles();
    var filterStyles = useFilterStyles();
    var styles = props.theme === "filters" ? filterStyles : navigationStyles;
    return (React.createElement("div", { className: styles.container },
        React.createElement(RenderProperty, { value: props.data.imageAdaptive }),
        React.createElement(RenderProperty, { className: styles.headline, value: props.data.headline }), (_a = props.data.buttons.value) === null || _a === void 0 ? void 0 :
        _a.map(function (b, idx) { return (React.createElement(ButtonWrapper, __assign({}, b, { key: idx, className: styles.button }))); })));
}
