var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import cn from "classnames";
import { colors } from "@basics/colors";
import { spacings } from "@basics/spacings";
import { rem } from "@basics/layout";
import { FAIcon, FAIconStyles } from "@components/FAIcon/FAIcon";
import { customTypography, typography } from "@basics/typography";
var THEMES = {
    gray: {
        backgroundColor: colors.gray10,
    },
    white: {
        backgroundColor: colors.white,
    },
    transparent: {
        backgroundColor: "transparent",
    },
};
var useStyles = createUseStyles({
    inputWrapper: function (_a) {
        var isFocused = _a.isFocused, theme = _a.theme;
        return ({
            marginRight: rem(spacings.sam),
            position: "relative",
            overflow: "hidden",
            padding: "10px 40px 10px 12px",
            backgroundColor: THEMES[theme].backgroundColor,
            width: "100%",
            "&:after, &:before": {
                content: "''",
                position: "absolute",
                display: "block",
                bottom: 0,
                left: 0,
                zIndex: 2,
                transform: isFocused ? "translate(0)" : "translate(-100%)",
                transition: "transform 0.2s ease",
                width: "100%",
                height: 2,
                backgroundColor: colors.webGreen20,
            },
            "&:before": {
                backgroundColor: colors.gray80,
                zIndex: 1,
                transform: "none",
            },
            "& svg": {
                color: colors.gray100,
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                right: rem(spacings.sam.divide(2)),
                pointerEvents: "none",
            },
        });
    },
    inputWrapperTypography: __assign({}, customTypography(__assign(__assign({}, typography.textDefault), { lineHeight: "1 !important", fontWeight: "normal", marginBottom: 0 }), {}, {
        marginBottom: 0,
    })),
    input: {
        marginBottom: 0,
        width: "100%",
        height: "100%",
        background: "none",
        border: 0,
        outline: "none",
        fontFamily: "inherit",
        fontSize: "inherit",
        fontWeight: "inherit",
        lineHeight: "inherit",
    },
    labelText: __assign({}, typography.visuallyHidden),
    resetButton: {
        position: "absolute",
        top: 0,
        right: 0,
        border: 0,
        background: "none",
        cursor: "pointer",
        width: "calc(".concat(rem(spacings.sam), " + 20px)"),
        height: "100%",
        pointerEvents: function (_a) {
            var displayResetIcon = _a.displayResetIcon;
            return displayResetIcon ? "all" : "none";
        },
        opacity: function (_a) {
            var displayResetIcon = _a.displayResetIcon;
            return (displayResetIcon ? 1 : 0);
        },
    },
    primaryIcon: {
        opacity: function (_a) {
            var displayResetIcon = _a.displayResetIcon;
            return (displayResetIcon ? 0 : 1);
        },
    },
    icon: __assign({}, FAIconStyles({
        width: 20,
        height: 20,
    })),
    visuallyHidden: __assign({}, typography.visuallyHidden),
});
export function Input(props) {
    var _a = useState(false), isFocused = _a[0], setFocus = _a[1];
    var _b = useState(props.initialValue || ""), value = _b[0], setValue = _b[1];
    var displayResetIcon = value;
    var styles = useStyles({
        isFocused: isFocused,
        theme: props.theme || "gray",
        displayResetIcon: displayResetIcon,
    });
    function onKeyDown(event) {
        if (event.keyCode === 13 && props.onSubmit) {
            props.onSubmit(value);
        }
    }
    function onChange(event) {
        setValue(event.target.value);
        props.getValue && props.getValue(event.target.value);
    }
    function onReset() {
        setValue("");
        props.clearQuery && props.clearQuery();
    }
    return (React.createElement("label", { className: cn(styles.inputWrapper, styles.inputWrapperTypography, props.className), onClick: function () { return props.onClick && props.onClick(); } },
        React.createElement("span", { className: styles.labelText }, props.label),
        React.createElement("input", { className: styles.input, ref: props.inputRef, type: props.type || "text", disabled: props.disabled, value: value, placeholder: props.placeholder, onFocus: function () { return setFocus(true); }, onBlur: function () { return setFocus(false); }, onKeyDown: function (e) { return onKeyDown(e); }, onChange: onChange }),
        displayResetIcon && (React.createElement("button", { className: styles.resetButton, onClick: onReset },
            React.createElement("span", { className: styles.visuallyHidden }, "Reset"),
            React.createElement(FAIcon, { icon: "times", className: styles.icon })))));
}
