var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { spacings } from "@basics/spacings";
import { rem } from "@basics/layout";
import { LocaleContext } from "@hooks/LocaleContext";
import { customTypography, typography } from "@basics/typography";
import { colors } from "@basics/colors";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
export function LanguagePicker() {
    var _a, _b, _c;
    var useStyles = createUseStyles({
        languagePicker: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            position: "relative",
            marginLeft: rem(spacings.s),
            zIndex: 11,
        },
        currentLanguage: __assign(__assign({}, customTypography(__assign(__assign({ paddingLeft: rem(spacings.xs) }, typography.textSmall), { marginBottom: 0, color: colors.gray100, transition: "color 0.3s ease" }), {}, {
            marginBottom: 0,
        })), { "&:hover": {
                color: colors.black,
            } }),
        flagImage: {
            width: 22,
            height: 16,
        },
        languageSelect: {
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            padding: 0,
            border: "none",
            font: "inherit",
            color: "inherit",
            textAlign: "inherit",
            background: "none",
            transition: "filter 0.3s ease",
            filter: "grayscale(0) contrast(1)",
            "&:hover": {
                transition: "filter 0.3s ease",
                filter: "grayscale(0) contrast(1.3)",
            },
        },
        visuallyHidden: __assign({}, typography.visuallyHidden),
    });
    var setIsLocaleOverlayOpened = useContext(LocaleContext).setIsLocaleOverlayOpened;
    var _d = useContext(GlobalContentStoreContext), getStartPageData = _d.getStartPageData, getImmediateStartPageData = _d.getImmediateStartPageData, getWebsiteData = _d.getWebsiteData;
    var websiteData = getWebsiteData();
    var _e = useState(getImmediateStartPageData()), startPageData = _e[0], setStartPageData = _e[1];
    useEffect(function () {
        !startPageData &&
            getStartPageData().then(function (response) {
                setStartPageData(response);
            });
    }, []);
    var styles = useStyles();
    if (!startPageData)
        return null;
    var currentLanguage = ((_a = websiteData === null || websiteData === void 0 ? void 0 : websiteData.currentLanguage) === null || _a === void 0 ? void 0 : _a.displayName) || "International";
    return (React.createElement("div", { className: styles.languagePicker, "data-testid": "languagepicker" },
        React.createElement("button", { className: styles.languageSelect, onClick: function () { return setIsLocaleOverlayOpened(true); } },
            React.createElement("img", { src: ((_c = (_b = startPageData.localeIcon) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.url) || "", className: styles.flagImage, alt: "Change language" }),
            currentLanguage && (React.createElement("span", { "aria-hidden": true, className: styles.currentLanguage },
                React.createElement("span", { className: styles.visuallyHidden }, "Current language:"),
                currentLanguage)))));
}
