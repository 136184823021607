var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createUseStyles } from "react-jss";
import { colors } from "@basics/colors";
import { onBefore, onBreakpoint, rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { customTypography, typography } from "@basics/typography";
import { FAIconStyles } from "@components/FAIcon/FAIcon";
import { richTextBlock } from "@components/Block/RichTextBlock/styles";
export var useLocationCardStyles = createUseStyles({
    contactCol: {
        marginBottom: rem(spacings.m),
    },
    contact: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: colors.white,
        borderRadius: 10,
        cursor: "pointer",
        filter: "drop-shadow(0px 1px 7px rgba(0,0,0,.1))",
        transition: "filter 0.3s ease",
        "&:hover": {
            filter: "drop-shadow(0px 1px 7px rgba(0,0,0,.2))",
            transition: "filter 0.3s ease",
        },
        position: "relative",
    },
    bioSection: __assign(__assign({ display: "flex", backgroundColor: colors.gray10, minHeight: 80, borderTopLeftRadius: 10, borderTopRightRadius: 10 }, onBreakpoint("sm", {
        minHeight: 96,
    })), onBreakpoint("md", {
        minHeight: 128,
    })),
    nameWrapper: {
        marginRight: "auto",
    },
    name: customTypography(__assign(__assign({}, typography.h4), { marginLeft: rem(spacings.s) }), { marginTop: rem(spacings.s) }, {}, { margin: "".concat(rem(spacings.m), " 0 ").concat(rem(spacings.s), " ").concat(spacings.m) }),
    nameAdditionalMargin: {
        marginBottom: rem(spacings.sam),
    },
    photo: {
        "& img": __assign(__assign({ width: 88, height: 88, borderTopRightRadius: 10, marginLeft: rem(spacings.s), objectFit: "cover" }, onBreakpoint("sm", {
            width: 114,
            height: 114,
        })), onBreakpoint("md", {
            width: 146,
            height: 146,
        })),
    },
    infoSection: {
        display: "flex",
        flexWrap: "wrap",
        flexGrow: 1,
    },
    infoWrapper: __assign({ padding: "".concat(rem(spacings.s), " ").concat(rem(spacings.s), " ").concat(rem(spacings.m)) }, onBreakpoint("md", {
        padding: "".concat(rem(spacings.sam), " ").concat(rem(spacings.m), " ").concat(rem(spacings.l)),
    })),
    role: __assign({}, customTypography(__assign(__assign({}, typography.textDefault), { color: colors.gray60, marginLeft: rem(spacings.s), marginBottom: rem(spacings.s) }), {}, {}, { marginLeft: rem(spacings.m), marginBottom: rem(spacings.l) })),
    address: __assign(__assign(__assign(__assign({ extend: richTextBlock }, typography.textDefault), { marginBottom: 0, paddingRight: rem(spacings.s), flexBasis: function (inContactList) { return (inContactList ? "50%" : "35%"); } }), onBefore("sm", {
        flexBasis: function () { return "auto"; },
        paddingRight: 0,
    })), { "& p": __assign({ extend: typography.textDefault }, onBreakpoint("sm", {
            marginBottom: 0,
        })), "& span:last-child": {
            display: "block",
        } }),
    cityWrapper: {
        display: "flex",
    },
    contactNumbers: __assign(__assign(__assign(__assign({}, typography.textDefault), { marginBottom: 0, marginRight: "auto", paddingRight: rem(spacings.s), flexBasis: function (inContactList) { return (inContactList ? "50%" : "auto"); }, display: "flex", flexDirection: "column" }), onBefore("sm", {
        display: "none",
    })), { "& a": {
            color: colors.gray100,
            paddingLeft: rem(spacings.xxs),
        }, "& a:hover": {
            color: colors.lightGreen40,
            textDecoration: "underline",
        } }),
    description: __assign(__assign({}, typography.textDefault), { display: "flex" }),
    ctaButtons: __assign(__assign({ display: "flex", flexWrap: "wrap", flexBasis: function (inContactList) { return (inContactList ? "100%" : "auto"); }, justifyContent: "space-between", alignItems: "flex-end" }, onBefore("sm", {
        flexBasis: function () { return "100%"; },
    })), { "& > button, & > a": __assign({ marginTop: rem(spacings.s) }, onBefore("sm", {
            marginRight: rem(spacings.s),
        })) }),
    iconWrapperMobile: __assign(__assign({ marginTop: rem(spacings.s), marginLeft: "auto", display: "flex" }, onBreakpoint("sm", {
        display: "none",
    })), { "& button, & a": {
            backgroundColor: colors.gray20,
            "&$primaryCta": {
                backgroundColor: colors.webGreen20,
                color: colors.webGreen40,
            },
            padding: "11px 12px",
            borderRadius: "50%",
            marginRight: rem(spacings.xs),
            "&:last-child": {
                marginRight: 0,
            },
        } }),
    primaryCta: {},
    icon: __assign({}, FAIconStyles({
        color: colors.gray90,
        width: 24,
        height: 24,
        cursor: "pointer",
    })),
    accessibilityText: typography.visuallyHidden,
    //for overlay only
    infoSectionOverlay: {
        flexDirection: "column",
        flexWrap: "nowrap",
    },
    contacts: __assign(__assign(__assign({}, typography.textDefault), { marginTop: rem(spacings.m), marginRight: "auto", marginBottom: 0, display: "flex" }), onBefore("sm", {
        marginTop: rem(spacings.s),
    })),
    contactLabels: {
        display: "flex",
        color: colors.gray60,
        flexDirection: "column",
    },
    addressOverlay: {
        flexBasis: function () { return "100%"; },
        flexShrink: 2,
    },
    contactNumbersOverlay: {
        display: "flex",
        flexBasis: function () { return "100%"; },
        "& a": {
            color: colors.gray100,
        },
    },
    websiteAddress: {
        display: "none",
    },
});
