var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Col, Container, onBreakpoint, rem, Row } from "@basics/layout";
import { colors } from "@basics/colors";
import { spacings } from "@basics/spacings";
import { customTypography, typography } from "@basics/typography";
import { FAIcon } from "@components/FAIcon/FAIcon";
import cn from "classnames";
import { LocaleContext } from "@hooks/LocaleContext";
import { animations } from "@basics/animations";
import { Modal } from "@components/Modal/Modal";
import { addAcceptableUrlPrefix } from "@components/LocaleSuggestion/LocaleSuggestion";
import Cookies from "js-cookie";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
import { LocalizedLabel } from "@hooks/LocalizationContext";
import { Button } from "@components/Button/Button";
var useStyles = createUseStyles(__assign(__assign(__assign({}, animations.simpleFadeIn), animations.simpleFadeOut), { modalBox: __assign({ display: "flex", flexDirection: "column", backgroundColor: colors.gray10, padding: rem(spacings.sam), maxHeight: "80vh", overflow: "auto" }, onBreakpoint("sm", {
        padding: rem(spacings.m),
    })), headlineSection: {
        marginBottom: rem(spacings.sam),
        borderBottom: "2px solid ".concat(colors.gray20),
    }, headline: __assign({}, customTypography(__assign(__assign({}, typography.h4), { marginBottom: rem(spacings.sam) }), {}, {
        marginBottom: rem(spacings.sam),
    })), buttonInfo: {
        display: "inline-flex",
        color: colors.webGreen40,
        fontSize: "1rem",
        alignSelf: "flex-start",
        marginBottom: rem(spacings.sam),
        padding: "".concat(rem(spacings.xs), " ").concat(rem(spacings.s)),
        border: "2px solid ".concat(colors.webGreen20),
        "& svg": {
            color: colors.webGreen40,
        },
    }, region: {
        position: "relative",
        borderBottom: "2px solid ".concat(colors.gray20),
        marginBottom: rem(spacings.sam),
        "&:last-child": {
            borderBottom: 0,
            marginBottom: 0,
        },
    }, regionName: __assign({}, customTypography(__assign(__assign({}, typography.h5), { flexBasis: "100%" }), {}, {
        marginBottom: rem(spacings.s),
    })), countries: __assign({ display: "flex", flexWrap: "wrap", height: 0, overflow: "hidden", transition: "all .3s ease-in-out", opacity: 0 }, onBreakpoint("sm", {
        height: "auto",
        opacity: 1,
    })), oneCountry: {
        display: "flex",
        alignItems: "center",
        marginBottom: rem(spacings.sam),
        "& svg": {
            color: colors.webGreen20,
        },
    }, country: __assign(__assign(__assign({ display: "flex", alignItems: "center", flexBasis: "100%", marginBottom: rem(spacings.sam) }, onBreakpoint("sm", {
        flexBasis: "50%",
    })), onBreakpoint("md", {
        flexBasis: "33%",
    })), { "&:hover $countryFlag": {
            transition: "filter 0.3s ease",
            filter: "grayscale(0) contrast(1.3)",
        } }), countryFlag: {
        width: 24,
        transition: "filter 0.3s ease",
        filter: "grayscale(0) contrast(1)",
    }, countryName: __assign({}, customTypography(__assign(__assign({}, typography.textSmall), { marginLeft: rem(spacings.s), marginBottom: 0 }), {}, {
        marginBottom: 0,
    })), arrowIcon: __assign({ position: "absolute", top: 0, right: 0 }, onBreakpoint("sm", {
        display: "none",
    })), active: {
        opacity: 1,
        height: "auto",
    }, sectionIconButton: __assign({ padding: 0, border: "none", font: "inherit", color: "inherit", textAlign: "inherit", background: "none" }, onBreakpoint("sm", {
        display: "none",
    })), visuallyHidden: __assign({}, typography.visuallyHidden) }));
var LOCALE_CHOSEN_COOKIE_KEY = "localeChosen";
export function isLocaleChosen() {
    return Boolean(Cookies.get(LOCALE_CHOSEN_COOKIE_KEY));
}
export function setLocaleChosen() {
    Cookies.set(LOCALE_CHOSEN_COOKIE_KEY, "true");
}
function getLocalizedPage(pageId) {
    return fetch("/api/hdiglobal/getlocalisedpage/".concat(pageId)).then(function (r) { return r.json(); });
}
export function LocaleOverlay(props) {
    var _a, _b;
    var _c = useState(null), activeRegion = _c[0], setActiveRegion = _c[1];
    var _d = useState([]), fullLocalizedUrls = _d[0], setFullLocalizedUrls = _d[1];
    var _e = useContext(LocaleContext), isLocaleOverlayOpened = _e.isLocaleOverlayOpened, setIsLocaleOverlayOpened = _e.setIsLocaleOverlayOpened;
    var getMainPageContentLink = useContext(GlobalContentStoreContext).getMainPageContentLink;
    var contentLink = getMainPageContentLink();
    var openAccordion = function (region) {
        setActiveRegion(region);
    };
    var onRegionSelection = function (chosenLanguageCode) {
        setLocaleChosen();
        addAcceptableUrlPrefix(chosenLanguageCode);
    };
    useEffect(function () {
        contentLink &&
            getLocalizedPage(contentLink.id).then(function (response) { return response && setFullLocalizedUrls(response); });
    }, []);
    var styles = useStyles();
    return (React.createElement(Modal, { modalOpened: isLocaleOverlayOpened, closeModal: function () {
            setIsLocaleOverlayOpened(false);
        }, allowMobile: true },
        React.createElement(Container, null,
            React.createElement(Row, null,
                React.createElement(Col, { sm: 10, smStart: 1, md: 8, mdStart: 2 },
                    React.createElement("div", { className: styles.modalBox },
                        React.createElement("div", { className: styles.headlineSection },
                            React.createElement("h4", { className: styles.headline }, props.data.headline.value),
                            React.createElement(Button, { type: "link", href: (_a = fullLocalizedUrls === null || fullLocalizedUrls === void 0 ? void 0 : fullLocalizedUrls.find(function (u) { return u.LanguageCode === "en"; })) === null || _a === void 0 ? void 0 : _a.Url, onClick: function () { return onRegionSelection("international"); }, className: styles.buttonInfo },
                                React.createElement(LocalizedLabel, { section: "LocaleSelector", label: "InternationalInfo" }))), (_b = props.data.section.value) === null || _b === void 0 ? void 0 :
                        _b.map(function (r, idx) { return (React.createElement(Region, __assign({}, r, { key: idx, className: idx === activeRegion ? styles.active : undefined, fullLocalizedUrls: fullLocalizedUrls, openAccordion: idx !== activeRegion
                                ? function () { return openAccordion(idx); }
                                : function () { return openAccordion(null); }, icon: idx === activeRegion ? (React.createElement(FAIcon, { icon: "chevron-up", size: "lg", className: styles.arrowIcon })) : (React.createElement(FAIcon, { icon: "chevron-down", size: "lg", className: styles.arrowIcon })), onRegionSelection: onRegionSelection }))); })))))));
}
function Region(props) {
    var _a, _b;
    var styles = useStyles();
    var intLocale = props.locales.filter(function (l) { return l.languageCode === "en"; })[0];
    return (React.createElement(React.Fragment, null, intLocale ? (React.createElement("div", { className: styles.region },
        React.createElement("a", { href: intLocale.externalUrl
                ? intLocale.externalUrl
                : (_b = (_a = props.fullLocalizedUrls) === null || _a === void 0 ? void 0 : _a.find(function (u) { return u.LanguageCode === intLocale.languageCode; })) === null || _b === void 0 ? void 0 : _b.Url, className: styles.oneCountry, onClick: function () { return props.onRegionSelection("international"); } },
            React.createElement(FAIcon, { icon: "globe" }),
            React.createElement("span", { className: styles.countryName }, intLocale.label)))) : (React.createElement("div", { className: styles.region },
        React.createElement("h5", { className: styles.regionName }, props.headline),
        React.createElement("button", { onClick: props.openAccordion, className: styles.sectionIconButton }, props.icon),
        React.createElement("div", { className: cn(styles.countries, props.className) }, props.locales.map(function (l, idx) {
            var _a, _b;
            return (React.createElement("a", { href: l.externalUrl
                    ? l.externalUrl
                    : (_b = (_a = props.fullLocalizedUrls) === null || _a === void 0 ? void 0 : _a.find(function (u) { return u.LanguageCode === l.languageCode; })) === null || _b === void 0 ? void 0 : _b.Url, className: styles.country, key: idx, onClick: function () { return props.onRegionSelection(l.languageCode); }, target: l.externalUrl && "_blank" },
                React.createElement("img", { src: l.flagUrl, className: styles.countryFlag }),
                React.createElement("span", { className: styles.countryName }, l.label)));
        }))))));
}
