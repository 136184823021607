var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { brandColors } from "@basics/brand-colors";
import { rem, onBreakpoint } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { typography, customTypography } from "@basics/typography";
import { FAIconStyles } from "@components/FAIcon/FAIcon";
import { createUseStyles } from "react-jss";
export var useStyles = createUseStyles({
    element: __assign(__assign({ marginBottom: rem(spacings.m) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.l),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xxl),
    })),
    accordionHeadline: __assign(__assign({}, typography.h2), { textAlign: "center" }),
    accordionTitle: customTypography(typography.h4, { marginTop: rem(spacings.s), marginBottom: rem(spacings.s) }, { marginTop: rem(spacings.s), marginBottom: rem(spacings.s) }, { marginTop: rem(spacings.s), marginBottom: rem(spacings.s) }),
    icon: FAIconStyles({
        width: 24,
        height: 24,
    }),
    items: {
        display: "flex",
        flexDirection: "column",
    },
    item: {
        borderTop: "2px solid ".concat(brandColors.lightGrey),
        "&:last-child": {
            borderBottom: "2px solid ".concat(brandColors.lightGrey),
        },
    },
    accordionButton: {
        padding: 0,
        border: "none",
        font: "inherit",
        color: "inherit",
        textAlign: "inherit",
        background: "none",
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
    },
    visuallyHidden: __assign({}, typography.visuallyHidden),
});
