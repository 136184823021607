var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { Col, Container, onBreakpoint, rem, Row } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { typography } from "@basics/typography";
import { RenderProperty } from "@/src/views/RenderProperty";
import { SemanticHeader } from "@components/SemanticHeader/SematicHeader";
import { isContactPersonBlock, isGenericBlock } from "./CardOverlay";
import { ContactListOverlay } from "./ContactListOverlay";
import { GenericCard } from "./GenericCard";
import { LocationCard } from "./LocationCard";
import { PersonCard } from "./PersonCard";
var contactStyles = createUseStyles({
    contactList: __assign(__assign({ marginBottom: rem(spacings.s) }, onBreakpoint("sm", {
        display: "block",
        marginBottom: rem(spacings.sam),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.m),
    })),
    headline: __assign(__assign({}, typography.h2), { textAlign: "center" }),
});
export function ContactListBlock(props) {
    var _a;
    return (React.createElement(ContactListBlockComponent, { headline: props.data.headline, headerSize: props.data.headerSize, contacts: ((_a = props.data.contacts) === null || _a === void 0 ? void 0 : _a.expandedValue) || null }));
}
export function ContactListBlockComponent(props) {
    var _a, _b, _c, _d;
    var isLocationCard = (_a = props.contacts) === null || _a === void 0 ? void 0 : _a.map(function (c) { return c.contentType[1]; }).includes("ContactLocationBlock");
    var styles = contactStyles(isLocationCard);
    var contactsAmount = (_b = props.contacts) === null || _b === void 0 ? void 0 : _b.filter(function (c) {
        return c.contentType[1] === "ContactPersonBlock" ||
            c.contentType[1] === "ContactGenericBlock";
    }).length;
    var _e = useState(false), modalOpened = _e[0], setModalOpened = _e[1];
    var _f = useState(0), slide = _f[0], setSlide = _f[1];
    var closeModal = function () {
        setModalOpened(false);
    };
    function openModal(slide) {
        setModalOpened(true);
        setSlide(slide);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, { className: styles.contactList },
            props.headline && (React.createElement(Row, null,
                React.createElement(Col, { sm: 10, smStart: 1, md: 8, mdStart: 2 },
                    React.createElement(SemanticHeader, { headerSize: (_c = props.headerSize) === null || _c === void 0 ? void 0 : _c.value, className: styles.headline },
                        React.createElement(RenderProperty, { value: props.headline }))))),
            React.createElement(Row, null, (_d = props.contacts) === null || _d === void 0 ? void 0 : _d.map(function (contact, idx) {
                if (isContactPersonBlock(contact)) {
                    return (React.createElement(PersonCard, __assign({}, contact, { key: idx, contactsAmount: contactsAmount || 0, itemNumber: idx, openModal: openModal, isWide: props.isWide })));
                }
                else if (isGenericBlock(contact)) {
                    return (React.createElement(GenericCard, __assign({}, contact, { key: idx, contactsAmount: contactsAmount || 0, itemNumber: idx, openModal: openModal, isWide: props.isWide })));
                }
                else {
                    return (React.createElement(LocationCard, __assign({}, contact, { key: idx, itemNumber: idx, inContactList: true, openModal: openModal })));
                }
            }))),
        props.contacts && (React.createElement(ContactListOverlay, { contacts: props.contacts, modalOpened: modalOpened, closeModal: closeModal, initialSlide: slide }))));
}
