var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export function getValidators(validators) {
    if (validators.length === 0)
        return function (_) { return true; };
    function requiredValidator(value, message) {
        return value === null || value === "" ? message : true;
    }
    function regexValidator(value, message, pattern) {
        if (!pattern)
            return true;
        var regex = new RegExp(pattern);
        return value && regex.test(String(value))
            ? true
            : message.replace("{0}", pattern);
    }
    return function (value) {
        return validators
            .map(function (validator) {
            var _a;
            switch (validator.type) {
                case "RequiredValidator":
                    return requiredValidator(value, validator.errorMsg);
                case "RegularExpressionValidator":
                case "EmailValidator":
                case "IntegerValidator":
                case "PositiveIntegerValidator":
                case "DateDDMMYYYYValidator":
                case "DateMMDDYYYYValidator":
                case "DateYYYYMMDDValidator":
                    return regexValidator(value, validator.errorMsg, (_a = validator.model) === null || _a === void 0 ? void 0 : _a.jsPattern);
                default:
                    return true;
            }
        })
            .reduce(function (acc, validatorResult) {
            return validatorResult === true
                ? acc
                : Array.isArray(acc)
                    ? __spreadArray(__spreadArray([], acc, true), [validatorResult], false) : [validatorResult];
        }, true);
    };
}
