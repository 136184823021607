import { EpiContext } from "@hooks/EpiContext";
import React from "react";
import { useContext } from "react";
import { createUseStyles } from "react-jss";
var useStyles = createUseStyles({
    display: {
        display: "contents",
    },
});
export var EpiEdit = function (_a) {
    var _b;
    var children = _a.children, className = _a.className, property = _a.property;
    var isEditable = useContext(EpiContext).isEditable;
    var display = useStyles().display;
    var field = isEditable ? (_b = property.value) === null || _b === void 0 ? void 0 : _b["__key__"] : "";
    return (React.createElement("div", { "data-epi-edit": isEditable ? field : undefined, className: className ? className : display }, children));
};
