import { nonLocaleCompare } from "./nonLocaleCompare";
var INTERNATIONAL_LOCALE = "en";
export function getCountryOptions(websiteData) {
    return ((websiteData === null || websiteData === void 0 ? void 0 : websiteData.languages) || [])
        .map(function (locale) {
        var _a;
        return ({
            label: locale.displayName,
            value: ((_a = locale.link) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || "",
        });
    })
        .sort(function (l, r) {
        return l.value === INTERNATIONAL_LOCALE
            ? -1
            : r.value === INTERNATIONAL_LOCALE
                ? 1
                : nonLocaleCompare(l.label, r.label);
    });
}
export function getCurrentLocale(websiteData, initialQueryParametrs) {
    var _a, _b;
    return (initialQueryParametrs.get("locale") ||
        ((_a = websiteData === null || websiteData === void 0 ? void 0 : websiteData.currentLanguage.link) === null || _a === void 0 ? void 0 : _a.toLowerCase()) ||
        ((_b = getCountryOptions(websiteData)[0]) === null || _b === void 0 ? void 0 : _b.value) ||
        "en");
}
