/* eslint-disable @typescript-eslint/no-empty-function */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import isEqual from "lodash/isEqual";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import Slider from "react-slick";
import { useCarouselSettings } from "@basics/carouselSettings";
import { colors } from "@basics/colors";
import { Col, Container, onBefore, rem, Row } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { ContactListOverlay } from "@components/Block/ContactListBlock/ContactListOverlay";
import { LocationCard } from "@components/Block/ContactListBlock/LocationCard";
var useStyles = createUseStyles({
    cardCarousel: __assign(__assign({ width: function (_a) {
            var itemsAmount = _a.itemsAmount;
            return itemsAmount < 5 ? undefined : "calc(100% - 80px)";
        } }, onBefore("md", {
        width: function () { return "auto"; },
    })), { margin: "0 auto", "& .slick-list": {
            padding: "".concat(spacings.xs, " 0"),
        }, "& .slick-track": {
            display: "flex",
            marginBottom: rem(spacings.l),
        }, "& .slick-slide": {
            height: "auto",
            "& > div": {
                height: "100%",
                margin: "0 ".concat(spacings.xs.add(spacings.xxs)),
            },
        }, "& .slick-arrow": {
            width: 48,
            height: 48,
            "&:before": {
                color: colors.black,
                fontSize: "3rem",
            },
        }, "& .slick-prev": {
            left: -50,
            "&:before": {
                fontFamily: "Font Awesome\\ 5 Pro",
                content: '"\\f053"',
            },
        }, "& .slick-next": {
            right: -50,
            "&:before": {
                fontFamily: "Font Awesome\\ 5 Pro",
                content: '"\\f054"',
            },
        }, "& .slick-dots": {
            bottom: -10,
            "& button:before, & .slick-active button:before": {
                fontFamily: "Font Awesome\\ 5 Pro",
                content: '"\\f111"',
                fontWeight: 900,
                fontSize: "1rem",
                color: colors.black,
            },
        }, "& .slick-disabled": {
            cursor: "default",
        } }),
});
export var LocationCardSlider = function (_a) {
    var offices = _a.offices, onClose = _a.onClose, officeClickedOnMap = _a.officeClickedOnMap;
    var cardCarousel = useStyles({
        isGrayBg: "gray",
        itemsAmount: 2,
    }).cardCarousel;
    var _b = useState(false), isModalOpened = _b[0], setIsModalOpened = _b[1];
    var _c = useState(officeClickedOnMap), officeClicked = _c[0], setOfficeClicked = _c[1];
    var carouselSettings = useCarouselSettings();
    var initialSlide = offices.findIndex(function (office) {
        return isEqual(officeClicked, office);
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(ContactListOverlay, { contacts: offices, modalOpened: isModalOpened, closeModal: function () {
                setIsModalOpened(false);
            }, initialSlide: initialSlide }),
        React.createElement(Container, { style: {
                margin: "0",
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
            } },
            React.createElement(Row, null,
                React.createElement(Col, { md: 6, mdStart: 3 },
                    React.createElement(Slider, __assign({}, carouselSettings, { className: cardCarousel, initialSlide: initialSlide }), offices.map(function (office, index) {
                        return (React.createElement(LocationCard, __assign({}, office, { itemNumber: 0, openModal: function () {
                                setIsModalOpened(true);
                                setOfficeClicked(office);
                            }, onClose: onClose, key: JSON.stringify(office) + index })));
                    })))))));
};
