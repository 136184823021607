var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createUseStyles } from "react-jss";
import { onBreakpoint, rem } from "./layout";
import { spacings } from "./spacings";
export var styles = {
    pointer: {
        cursor: "pointer",
    },
    defaultPointer: {
        cursor: "default",
    },
    componentMargin: __assign(__assign({ marginBottom: rem(spacings.m) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.l),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xxl),
    })),
};
export var useBasicStyles = createUseStyles(styles);
