import cn from "classnames";
import React, { useRef } from "react";
import { Col, Container, Row } from "@basics/layout";
import { RenderProperty, ShowPropertyOnEdit } from "@/src/views/RenderProperty";
import { SemanticHeader } from "@components/SemanticHeader/SematicHeader";
import { useRichTextStyles } from "./styles";
export default function RichTextBlock(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var hasImage = ((_b = (_a = props.data.imageAdaptive) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.urlLarge) ||
        ((_d = (_c = props.data.imageAdaptive) === null || _c === void 0 ? void 0 : _c.value) === null || _d === void 0 ? void 0 : _d.urlMedium) ||
        ((_f = (_e = props.data.imageAdaptive) === null || _e === void 0 ? void 0 : _e.value) === null || _f === void 0 ? void 0 : _f.urlSmall);
    var imageWrapperRef = useRef(null);
    var isGrayBg = ((_g = props.data.backgroundColor) === null || _g === void 0 ? void 0 : _g.value) === "gray";
    var styles = useRichTextStyles({
        isGrayBg: isGrayBg,
    });
    return (React.createElement(Container, { dataTestid: "RichTextBlock", isFluid: isGrayBg, className: cn(styles.richTextContainer, isGrayBg ? styles.grayBg : undefined) },
        React.createElement(Row, { className: isGrayBg ? styles.bgRow : undefined },
            React.createElement(Col, { sm: 10, smStart: 1, md: 8, mdStart: 2 },
                ShowPropertyOnEdit(props.data.headline) && (React.createElement(SemanticHeader, { className: styles.headline, headerSize: (_h = props.data.headerSize) === null || _h === void 0 ? void 0 : _h.value },
                    React.createElement(RenderProperty, { value: props.data.headline }))),
                hasImage && (React.createElement("div", { className: cn(styles.richTextWrapper, ((_j = props.data.imageAlignment) === null || _j === void 0 ? void 0 : _j.value) === "left"
                        ? styles.imageLeft
                        : styles.imageRight), ref: imageWrapperRef },
                    React.createElement(RenderProperty, { value: props.data.imageAdaptive, className: styles.richTextImage }),
                    React.createElement(RenderProperty, { value: props.data.imageCaption, className: styles.imageCaption }))),
                React.createElement(RenderProperty, { value: props.data.richText })))));
}
export function RichTextWithTypographyRenderer(props) {
    var styles = useRichTextStyles({});
    return (React.createElement("div", { "data-epi-edit": props.dataEpiEdit, className: cn(props.className, styles.richTextBlock), dangerouslySetInnerHTML: { __html: props.value } }));
}
