var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { brandColors } from "@basics/brand-colors";
import { fonts } from "@basics/fonts";
import { BREAKPOINTS, rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { typography } from "@basics/typography";
import { FAIconStyles } from "@components/FAIcon/FAIcon";
import { createUseStyles } from "react-jss";
export var useStyles = createUseStyles({
    accessibilityText: typography.visuallyHidden,
    container: {
        maxWidth: "".concat(BREAKPOINTS.xs, "px"),
        margin: "0 auto",
        marginBottom: rem(spacings.xl),
    },
    heroSection: {
        display: "grid",
        gridTemplateAreas: "\n    \"logo image-right\"\n    \"image-left image-bottom-right\"\n    \"contactPhoto contactPhoto\"",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "1fr 2fr 0.5fr",
        width: "100%",
    },
    backgroundImage: {
        gridColumn: "image-left / image-bottom-right",
        gridRow: "image-right / image-bottom-right",
    },
    backgroundLogo: {
        gridArea: "logo",
        alignSelf: "end",
        width: "50%",
        marginTop: "15%",
    },
    contactPhotoWrapper: {
        display: "contents",
    },
    contactPhoto: {
        gridColumn: "image-left / image-bottom-right",
        gridRow: "image-left / contactPhoto",
        margin: "8%",
        justifySelf: "center",
        width: "40%",
        "& img": {
            border: "6px",
            borderRadius: "100%",
            borderColor: brandColors.white,
            maxHeight: "192px",
            maxWidth: "192px",
        },
    },
    bioSection: {
        display: "flex",
        flexDirection: "column",
        gap: rem(spacings.sam),
        alignItems: "center",
        padding: "0 ".concat(rem(spacings.s), " 0"),
    },
    fullName: __assign(__assign({}, fonts.medium), { fontSize: "1.5rem", lineHeight: 1.2, color: brandColors.universalGreen, textAlign: "center", margin: 0 }),
    jobTitleDetails: __assign(__assign({}, fonts.book), { fontSize: "19px", color: brandColors.black, textAlign: "center" }),
    icons: {
        display: "flex",
        justifyContent: "center",
        gap: rem(spacings.m),
        alignItems: "center",
    },
    iconLink: {
        color: brandColors.black,
        padding: rem(spacings.s),
        borderRadius: "100%",
        background: brandColors.mediumGrey,
    },
    icon: __assign({}, FAIconStyles({
        width: "24px",
        height: "24px",
    })),
    companyDetails: {
        display: "flex",
        flexDirection: "column",
        gap: rem(spacings.xs),
    },
    addressDetails: {
        "& > p": {
            marginBottom: rem(spacings.xs),
            textAlign: "center",
        },
    },
    companyName: __assign(__assign({}, fonts.medium), { fontSize: "19px", color: brandColors.black, textAlign: "center" }),
    streetName: __assign(__assign({}, fonts.book), { fontSize: "19px", color: brandColors.black, textAlign: "center" }),
    cityDetails: __assign(__assign({}, fonts.book), { fontSize: "19px", color: brandColors.black, textAlign: "center" }),
    countryName: __assign(__assign({}, fonts.book), { fontSize: "19px", color: brandColors.grey, textAlign: "center" }),
    errorMessage: __assign(__assign({}, fonts.medium), { fontSize: "19px", color: brandColors.black, textAlign: "center", padding: "0 ".concat(rem(spacings.s)) }),
});
