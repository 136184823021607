var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { createUseStyles } from "react-jss";
import { Col, Container, onBreakpoint, rem, Row } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { RenderProperty } from "@/src/views/RenderProperty";
import { typography } from "@basics/typography";
import { colors } from "@basics/colors";
import cn from "classnames";
import { AnimatedRevealBlock } from "@components/AnimatedReveal/AnimatedReveal";
import { ButtonWrapper } from "@components/Button/Button";
import { SemanticHeader } from "@components/SemanticHeader/SematicHeader";
import { fonts } from "@basics/fonts";
var useStyles = createUseStyles({
    quote: __assign(__assign({ marginBottom: rem(spacings.m) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.l),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xxl),
    })),
    description: __assign(__assign(__assign(__assign({}, typography.textDefault), typography.grayBackgroundFix), fonts.book), { "& p": __assign({}, fonts.book) }),
    textContainer: __assign(__assign(__assign({ minHeight: 230, padding: rem(spacings.m), paddingRight: 0, backgroundColor: colors.gray10, display: "flex", flexDirection: "column", "@media (min-width: 500px)": {
            paddingRight: rem(spacings.m),
        } }, onBreakpoint("sm", {
        marginTop: rem(spacings.l),
        padding: rem(spacings.l),
    })), onBreakpoint("md", {
        marginTop: rem(spacings.xxl),
        padding: rem(spacings.xxl),
        paddingRight: rem(spacings.l),
    })), { "&:after": __assign({ content: '""', position: "absolute", top: 0, right: "-25%", width: "100%", height: "100%", backgroundColor: colors.gray10, zIndex: -1 }, onBreakpoint("sm", {
            right: "-15%",
        })) }),
    narrow: __assign(__assign({}, onBreakpoint("sm", {
        "&:after": {
            right: "-12%",
        },
    })), onBreakpoint("md", {
        "&:after": {
            right: "-35%",
        },
    })),
    imageContainer: __assign({ position: "absolute", bottom: 32, width: "100%" }, onBreakpoint("sm", {
        width: "auto",
        position: "static",
        marginBottom: rem(spacings.xxl),
    })),
    buttonsWrapper: {
        marginTop: "auto",
    },
    buttons: {
        display: "inline-block",
        "&:not(:last-child)": {
            marginRight: rem(spacings.m),
        },
    },
    h4: __assign(__assign({}, typography.h4), { wordBreak: "break-word" }),
});
export function TextAndImageBlock(props) {
    var _a, _b, _c;
    var _d = props.data, image = _d.image, layout = _d.layout, richText = _d.richText;
    var styles = useStyles();
    var isNarrow = layout.value === "narrow";
    return (React.createElement(AnimatedRevealBlock, null,
        React.createElement(Container, { className: styles.quote, dataTestid: "TextAndImageBlock" },
            React.createElement(Row, { style: { position: "relative" } },
                React.createElement(Col, { xs: 3, sm: isNarrow ? 9 : 7, md: isNarrow ? 6 : 7, mdStart: isNarrow ? 1 : null, className: cn(styles.textContainer, isNarrow && styles.narrow) },
                    React.createElement(SemanticHeader, { className: styles.h4, headerSize: (_a = props.data.headerSize) === null || _a === void 0 ? void 0 : _a.value },
                        React.createElement(RenderProperty, { value: props.data.headline })),
                    React.createElement(RenderProperty, { value: richText, className: styles.description }),
                    React.createElement("div", { className: styles.buttonsWrapper }, (_c = (_b = props.data.buttons) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.map(function (button, idx) { return (React.createElement(ButtonWrapper, __assign({ key: idx }, button, { className: styles.buttons }))); }))),
                React.createElement(Col, { xs: 1, xsStart: 3, sm: isNarrow ? 3 : 5, smStart: isNarrow ? 9 : 7, md: isNarrow ? 4 : 5, mdStart: 7, className: styles.imageContainer },
                    React.createElement(RenderProperty, { value: image }))))));
}
