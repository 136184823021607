import React from "react";
import { createUseStyles } from "react-jss";
var useStyles = createUseStyles({
    spinner: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        pointerEvents: "none",
        zIndex: 10,
    },
    spinnerRing: {
        display: "inline-block",
        position: "relative",
        width: 64,
        height: 64,
        color: "#9da0af",
        "& div": {
            boxSizing: "border-box",
            display: "block",
            position: "absolute",
            width: 51,
            height: 51,
            margin: 6,
            borderWidth: 6,
            borderStyle: "solid",
            borderRadius: "50%",
            animation: "$lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite",
            borderTopColor: "inherit",
            borderLeftColor: "transparent",
            borderRightColor: "transparent",
            borderBottomColor: "transparent",
            "&:nth-child(1)": {
                animationDelay: "-0.45s",
            },
            "&:nth-child(2)": {
                animationDelay: "-0.3s",
            },
            "&:nth-child(3)": {
                animationDelay: "-0.15s",
            },
        },
    },
    "@keyframes lds-ring": {
        "0%": {
            transform: "rotate(0deg)",
        },
        "100%": {
            transform: "rotate(360deg)",
        },
    },
});
export function Spinner() {
    var styles = useStyles();
    return (React.createElement("div", { className: styles.spinner },
        React.createElement("div", { className: styles.spinnerRing },
            React.createElement("div", null),
            React.createElement("div", null),
            React.createElement("div", null),
            React.createElement("div", null))));
}
