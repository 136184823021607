var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { Container, Row, Col, rem, onBreakpoint } from "@basics/layout";
import { createUseStyles } from "react-jss";
import { spacings } from "@basics/spacings";
import { FormContainer } from "./FormContainer";
var useStyles = createUseStyles({
    element: __assign(__assign({ marginBottom: rem(spacings.l) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.xl),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xxl),
    })),
});
export function FormContainerBlock(props) {
    var styles = useStyles();
    return (React.createElement(Container, { dataTestid: "FormContainerBlock", className: styles.element },
        React.createElement(Row, null,
            React.createElement(Col, { sm: 10, smStart: 1, md: 8, mdStart: 2 },
                React.createElement(FormContainer, __assign({}, props))))));
}
