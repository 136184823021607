var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cn from "classnames";
import React, { useRef } from "react";
import { createUseStyles } from "react-jss";
import { onBreakpoint, Container, Row, Col } from "@basics/layout";
import { RenderProperty } from "@/src/views/RenderProperty";
import { Accordion, AccordionItem } from "@components/Accordion/Accordion";
import { AdaptiveImageComponent } from "@components/AdaptiveImageComponent/AdaptiveImageComponent";
import { SemanticHeader } from "@components/SemanticHeader/SematicHeader";
import { RichTextWithTypographyRenderer } from "@components/Block/RichTextBlock/RichTextBlock";
import { useRichTextStyles } from "@components/Block/RichTextBlock/styles";
import { useStyles } from "./styles";
var useItemStyles = createUseStyles({
    itemContent: __assign({}, onBreakpoint("sm", {
        marginRight: "56px",
    })),
});
export var AccordionBlock = function (props) {
    var _a;
    var styles = useStyles({});
    var _b = props.data, headerSize = _b.headerSize, headline = _b.headline, accordionItems = _b.accordionItems;
    return (React.createElement(Container, { dataTestid: "AccordionBlock", className: styles.element },
        React.createElement(Row, null,
            React.createElement(Col, { sm: 10, smStart: 1, md: 8, mdStart: 2 },
                headline && (React.createElement(SemanticHeader, { className: styles.accordionHeadline, headerSize: headerSize === null || headerSize === void 0 ? void 0 : headerSize.value },
                    React.createElement(RenderProperty, { value: headline.value }))),
                React.createElement(Accordion, { className: styles.items }, (_a = accordionItems.value) === null || _a === void 0 ? void 0 : _a.map(function (item, idx) { return (React.createElement(AccordionListSingleItem, __assign({ key: idx }, item))); }))))));
};
export var AccordionListSingleItem = function (props) {
    var imageWrapperRef = useRef(null);
    var styles = useItemStyles();
    var richTextStyles = useRichTextStyles({ isGrayBg: false });
    var imageAdaptive = props.imageAdaptive, imageAlignment = props.imageAlignment, imageCaption = props.imageCaption, richText = props.richText.value;
    var _a = imageAdaptive.value || {}, urlLarge = _a.urlLarge, urlMedium = _a.urlMedium, urlSmall = _a.urlSmall, alternateText = _a.alternateText;
    var hasImage = urlLarge || urlMedium || urlSmall;
    return (React.createElement(AccordionItem, { headline: props.title.value || "" },
        React.createElement("div", { className: styles.itemContent },
            hasImage && (React.createElement("div", { className: cn(richTextStyles.richTextWrapper, imageAlignment.value === "left"
                    ? richTextStyles.imageLeft
                    : richTextStyles.imageRight), ref: imageWrapperRef },
                React.createElement(AdaptiveImageComponent, { large: urlLarge, medium: urlMedium, small: urlSmall, alt: alternateText }),
                React.createElement(RenderProperty, { value: imageCaption, className: richTextStyles.imageCaption }))),
            richText && React.createElement(RichTextWithTypographyRenderer, { value: richText }))));
};
