import { useState, useEffect } from "react";
export function useLocationHash() {
    var _a = useState(window.location.hash), hash = _a[0], setHash = _a[1];
    useEffect(function () {
        function updateHash() {
            hash !== window.location.hash && setHash(window.location.hash);
        }
        window.addEventListener("hashchange", updateHash);
        updateHash();
        return function () { return window.removeEventListener("hashchange", updateHash); };
    });
    return hash;
}
