var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { brandColors } from "@basics/brand-colors";
import { fonts } from "@basics/fonts";
import { onBreakpoint, rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { customTypography, typography } from "@basics/typography";
import { subscriptionItems, interests } from "./utils";
export var commonStyles = {
    display: "flex",
    alignItems: "center",
    "& .lp-required": {
        position: "relative",
        top: 35,
        left: 5,
    },
};
var gridLayout = __assign(__assign({ display: "grid", gridTemplateColumns: "1fr" }, onBreakpoint("sm", {
    gridTemplateColumns: "1fr 1fr",
})), { gap: rem(spacings.xs) });
var gridFullWidthLayout = __assign({ gridColumn: "span 1 / span 1" }, onBreakpoint("sm", {
    gridColumn: "span 2 / span 2",
}));
export var containerWrapperStyles = {
    "& .containerWrapper": __assign(__assign({ display: "grid", gap: "".concat(rem(spacings.s)), gridTemplateColumns: "repeat(1, minmax(0, 1fr))" }, onBreakpoint("sm", {
        gridAutoFlow: "column",
        gridAutoColumns: "minmax(0, 1fr)",
        gap: "".concat(rem(spacings.sam)),
    })), { "& .columnContainer": __assign(__assign({ display: "flex", flexDirection: "column" }, onBreakpoint("sm", {
            gap: rem(spacings.m),
        })), { gap: rem(spacings.s) }), 
        // Example:
        // .containerWrapper
        //     div <- that div is empty and it's controlled by MSDynamics
        //     div.columnContainer <- content
        //     div.columnContainer <- content
        //     div <- that div is empty and it's controlled by MSDynamics
        // That empty div needs to be hidden so that grid does not take into account
        "& > div": {
            display: "none",
        } }),
};
export var inputStyles = {
    "& input, & textarea": __assign(__assign({ boxSizing: "border-box", display: "inline" }, customTypography(typography.textDefault, { marginBottom: 0 }, { marginBottom: 0 }, { marginBottom: 0 })), { background: brandColors.ultraLightGrey, border: 0, borderRadius: 0, height: "3.5rem", padding: "0 ".concat(rem(spacings.s)), width: "100%", outline: "none", borderBottom: "2px solid ".concat(brandColors.black), "&:hover": {
            borderBottom: "2px solid ".concat(brandColors.universalGreenHover),
            transition: "all .3s ease-out",
        }, "&:focus": {
            borderBottom: "2px solid ".concat(brandColors.universalGreenHover),
            transition: "all .3s ease-out",
            "&:invalid": {
                borderBottom: "2px solid ".concat(brandColors.red),
            },
        } }),
};
var tickIconStyles = {
    position: "relative",
    "&:after": {
        fontFamily: "Font Awesome\\ 5 Pro",
        content: '"\\f078"',
        fontSize: "1.25rem",
        color: "#333",
        right: "16px",
        position: "absolute",
        pointerEvents: "none",
    },
};
export var selectStyles = {
    "& select": __assign(__assign({ cursor: "pointer", width: "100%", height: "3.5rem", padding: "0 ".concat(rem(spacings.s)), fontWeight: "normal", backgroundColor: brandColors.ultraLightGrey, textAlign: "left", alignItems: "center", border: 0, borderBottom: "2px solid ".concat(brandColors.black), outline: "none", appearance: "none", borderRadius: "0px" }, customTypography(typography.textDefault, {
        marginBottom: 0,
        color: brandColors.grey,
    }, {
        marginBottom: 0,
    })), { "&:focus": {
            borderBottom: "2px solid ".concat(brandColors.universalGreenHover),
        } }),
    "& div:has(> select)": tickIconStyles,
    // Fallback for FireFox as that browser does not support `has`
    "& [data-editorblocktype=Field-dropdown] .lp-form-field": tickIconStyles,
};
export var buttonStyles = {
    "& button": __assign(__assign({}, fonts.bold), { fontSize: "19px", display: "inline-flex", float: "right", alignItems: "center", textAlign: "center", position: "relative", outline: "none", flexShrink: "0", cursor: "pointer", color: brandColors.white, padding: "10px 25px", textDecoration: "none", border: "2px solid transparent", backgroundColor: brandColors.universalGreen, transition: "background-color 0.3s ease", "&:hover": {
            transition: "backgroundColor 0.3s ease",
            backgroundColor: brandColors.universalGreenHover,
        }, "&:disabled": {
            color: brandColors.grey,
            backgroundColor: brandColors.lightGrey,
        }, "&:focus": {
            outline: "2px solid ".concat(brandColors.lightBlue),
        } }),
};
export var checkboxStyles = {
    "& input[type=checkbox]": {
        boxSizing: "border-box",
        display: "block",
        flexShrink: "0",
        width: "32px",
        height: "32px",
        border: "2px solid ".concat(brandColors.black),
        borderRadius: "0px",
        appearance: "none",
        background: brandColors.white,
        position: "relative",
        padding: "0px",
        margin: "0px",
        "& + .lp-ellipsis": __assign(__assign({}, customTypography(typography.textDefault, { marginBottom: "0px" }, { marginBottom: "0px" }, { marginBottom: "0px" })), { "& .lp-required": {
                position: "static",
            } }),
        "&:checked": {
            "&:after": {
                opacity: 1,
            },
        },
        "&:after": {
            boxSizing: "border-box",
            position: "absolute",
            content: '""',
            top: "0px",
            left: "8px",
            height: "20px",
            width: "12px",
            cursor: "pointer",
            border: "2px solid ".concat(brandColors.black),
            borderLeft: "0px",
            borderTop: "0px",
            opacity: 0,
            transform: "rotate(45deg)",
        },
        "& + label": __assign(__assign({ display: "block", height: "fit-content" }, fonts.medium), { lineHeight: 1.5, color: brandColors.black, marginTop: "0px", marginLeft: "".concat(rem(spacings.s)) }),
        "&:hover": {
            borderBottom: "2px solid ".concat(brandColors.black),
            transition: "none",
        },
        "&:focus-visible": {
            transition: "none",
            borderBottom: "2px solid ".concat(brandColors.black),
            outline: "2px solid ".concat(brandColors.lightBlue),
            "&:invalid": {
                border: "2px solid ".concat(brandColors.red),
            },
            outlineOffset: "0px",
        },
    },
};
export var radioButtonStyles = {
    "& input[type=radio]": {
        boxSizing: "border-box",
        display: "block",
        flexShrink: "0",
        width: "32px",
        height: "32px",
        border: "2px solid ".concat(brandColors.black),
        borderRadius: "100%",
        appearance: "none",
        background: brandColors.white,
        position: "relative",
        padding: "0px",
        margin: "0px",
        "& + .lp-ellipsis": __assign(__assign({}, customTypography(typography.textDefault, { marginBottom: "0px" }, { marginBottom: "0px" }, { marginBottom: "0px" })), { "& .lp-required": {
                position: "static",
            } }),
        "&:checked": {
            "&:after": {
                opacity: 1,
            },
        },
        "&:after": {
            boxSizing: "border-box",
            position: "absolute",
            content: '""',
            cursor: "pointer",
            border: "2px solid ".concat(brandColors.black),
            borderRadius: "100%",
            height: 32,
            width: 32,
            left: -2,
            top: -2,
            transition: "all .1s ease-out",
            background: brandColors.universalGreenHover,
            boxShadow: "inset 0 0 0 6px ".concat(brandColors.white),
            opacity: 0,
        },
        "& + label": __assign(__assign({ display: "block", height: "fit-content" }, fonts.medium), { lineHeight: 1.5, color: brandColors.black, marginTop: "0px", marginLeft: "".concat(rem(spacings.s)) }),
        "&:hover": {
            borderBottom: "2px solid ".concat(brandColors.darkGrey),
            transition: "none",
        },
        "&:focus": {
            outline: "2px solid ".concat(brandColors.lightBlue),
            borderBottom: "2px solid ".concat(brandColors.darkGrey),
            transition: "none",
            "&:invalid": {
                border: "2px solid ".concat(brandColors.red),
            },
        },
        "&:focus-visible": {
            outlineOffset: "0px",
        },
    },
};
export var textAreaStyles = {
    "& textarea": {
        resize: "none",
        minHeight: "200px",
        padding: rem(spacings.s),
    },
};
export var descriptionStyles = {
    "& p": __assign({}, customTypography(typography.textDefault, { marginBottom: "0px" }, { marginBottom: "0px" }, { marginBottom: "0px" })),
};
export var datetimeStyles = {
    "& input[type=datetime-local]": {
        flexGrow: "1",
        "&::-webkit-date-and-time-value": {
            textAlign: "left",
        },
    },
};
export var errorStyles = {
    "& .dialogProviderContainerStyle": __assign(__assign({}, customTypography(typography.textDefault, { marginBottom: 0 }, { marginBottom: 0 }, { marginBottom: 0 })), { display: "flex", alignItems: "center", width: "100%", background: brandColors.yellowOchre, "&:before": {
            fontFamily: "Font Awesome\\ 5 Pro",
            fontWeight: "200",
            content: '"\\f05a"',
            fontSize: "4rem",
            color: brandColors.white,
            pointerEvents: "none",
            background: brandColors.yellowOchre,
            height: "100%",
            paddingLeft: rem(spacings.m),
        }, "& .dialogProviderInternalContainerStyle": {
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: rem(spacings.s),
            padding: rem(spacings.m),
            background: brandColors.yellowOchre,
        }, "& .dialogProviderLinkButtonContainerStyle": {
            display: "none",
        }, "& *": {
            color: "".concat(brandColors.white, " !important"),
        }, "& a": __assign(__assign({ color: "".concat(brandColors.white), textDecoration: "none" }, fonts.bold), { "&:hover": {
                color: "".concat(brandColors.white),
            } }), "& img": {
            display: "none",
        } }),
};
export var successStyles = {
    "& .onFormSubmittedFeedback": {
        display: "flex",
        padding: rem(spacings.m),
        "& img": {
            display: "none",
        },
        "& .onFormSubmittedFeedbackInternalContainerStyle": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flexGrow: "1",
            gap: rem(spacings.s),
        },
        "& .onFormSubmittedFeedbackMessage": __assign({}, customTypography(typography.textDefault, { marginBottom: 0 }, { marginBottom: 0 }, { marginBottom: 0 })),
    },
};
export var generateStylesForToggle = function (subscriptionListId) {
    var _a;
    return _a = {},
        _a["& input[type=checkbox][id='".concat(subscriptionListId, "']")] = {
            position: "relative",
            display: "block",
            width: "48px",
            height: "22px",
            borderRadius: "50px",
            border: "1px solid ".concat(brandColors.grey),
            cursor: "pointer",
            "& + label": {
                display: "flex",
                boxSizing: "border-box",
            },
            "&:after": {
                display: "block",
                backgroundColor: brandColors.grey,
                boxSizing: "border-box",
                position: "absolute",
                top: "2px",
                left: "2px",
                width: "16px",
                height: "16px",
                borderRadius: "50px",
                cursor: "pointer",
                opacity: 1,
                transform: "rotate(0)",
                border: "none",
            },
            "&:checked:after": {
                display: "block",
                backgroundColor: brandColors.universalGreen,
                boxSizing: "border-box",
                position: "absolute",
                top: "3px",
                width: "16px",
                height: "16px",
                borderRadius: "50px",
                cursor: "pointer",
                borderLeft: "0px",
                borderTop: "0px",
                opacity: 1,
                transform: "rotate(0) translateX(160%)",
                border: "none",
            },
            "&:checked": {
                background: brandColors.universalGreen40,
                border: "none",
            },
            "&:hover": {
                outline: "none",
            },
            "&:focus-visibile": {
                borderBottom: "none",
                outline: "2px solid ".concat(brandColors.lightBlue),
            },
        },
        _a;
};
var generateStylesForSubscriptionListWithIcon = function () {
    return __assign({ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: rem(spacings.s), margin: "auto", justifyContent: "center" }, onBreakpoint("sm", {
        justifyContent: "normal",
    }));
};
export var generateStylesForClickableIcon = function (subscriptionListId, icon) {
    var _a;
    return _a = {},
        _a["& input[type=checkbox][id='".concat(subscriptionListId, "']")] = {
            width: "177px",
            height: "167px",
            border: "none",
            boxShadow: "0 2px 12px 0 rgba(0, 0, 0, 0.2)",
            "& + label": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxSizing: "border-box",
                position: "absolute",
                bottom: 0,
                width: 177 - 32,
                margin: "0px ".concat(rem(spacings.s), " ").concat(rem(spacings.xs), " ").concat(rem(spacings.s)),
                textAlign: "center",
            },
            "&:after": {
                display: "block",
                fontFamily: "Font Awesome\\ 5 Pro",
                fontSize: "56px",
                fontWeight: "100",
                content: "'\\".concat(icon, "'"),
                color: brandColors.universalGreen,
                boxSizing: "border-box",
                position: "static",
                marginTop: rem(spacings.xs),
                textAlign: "center",
                width: "100%",
                height: "auto",
                cursor: "pointer",
                borderLeft: "0px",
                borderTop: "0px",
                opacity: 1,
                transform: "rotate(0)",
                transition: "transform 0.3s ease",
                border: "none",
            },
            "&:hover": {
                "&:after": {
                    transform: "scale(1.1)",
                    transition: "transform 0.3s ease",
                },
                borderBottom: "none",
                outline: "none",
                boxShadow: "0 2px 12px 0 rgba(0, 0, 0, 0.3)",
                opacity: 1,
            },
            "&:checked": {
                background: brandColors.universalGreen40,
                boxShadow: "none",
                "&:hover": {
                    "&:after": {
                        transform: "scale(1)",
                        transition: "none",
                    },
                },
            },
            "&:focus-visible": {
                borderBottom: "none",
                outline: "2px solid ".concat(brandColors.lightBlue),
            },
        },
        _a;
};
export var clickableIconsStyles = Object.assign.apply(Object, __spreadArray([{}], Object.entries(subscriptionItems).map(function (_a) {
    var key = _a[0], value = _a[1];
    return generateStylesForClickableIcon(key, value);
}), false));
export var preferencesStyles = Object.assign.apply(Object, __spreadArray([{}], interests.map(function (item) {
    return generateStylesForToggle(item);
}), false));
export var subscriptionCenterStyles = {
    "& form.subscriptionCenter": __assign(__assign(__assign({ "& .containerWrapper": {
            "& .columnContainer": {
                gap: rem(spacings.s),
                "&.marketingPreferences": {
                    gap: rem(spacings.xs),
                },
            },
        }, "& > div": __assign(__assign({}, onBreakpoint("sm", {
            gap: rem(spacings.xxl),
        })), { gap: rem(spacings.m) }), "& .subscriptionListWithIcon": __assign({}, onBreakpoint("sm", generateStylesForSubscriptionListWithIcon())), "& .marketing-subscription-list": __assign(__assign({}, commonStyles), { flexDirection: "row", position: "relative", width: "fit-content" }) }, onBreakpoint("sm", __assign({}, clickableIconsStyles))), preferencesStyles), { "& .marketingPreferences": {
            extend: gridLayout,
            borderRadius: "10px",
            paddingBottom: rem(spacings.m),
            boxShadow: "0 2px 12px 0 rgba(0, 0, 0, 0.2)",
            "& .marketing-subscription-list": {
                paddingLeft: rem(spacings.m),
                paddingRight: rem(spacings.m),
                height: "100%",
            },
            "& div[data-editorblocktype=Text]": {
                extend: gridFullWidthLayout,
                padding: "0 ".concat(rem(spacings.m), " ").concat(rem(spacings.s), " ").concat(rem(spacings.m)),
                "&:first-child": {
                    padding: rem(spacings.m),
                    background: brandColors.lightGrey,
                    marginBottom: rem(spacings.sam),
                },
            },
        }, "& .newsletters": {
            "& div[data-editorblocktype=Text]": {
                "&:nth-child(n+3)": {
                    "& p": {
                        color: brandColors.grey,
                        fontSize: "95%",
                    },
                },
            },
        } }),
};
export var dividerStyles = {
    "& div.dividerWrapper": {
        height: "1px",
        background: brandColors.darkGrey,
    },
};
export var textStyles = {
    "& div[data-editorblocktype=Text]": {
        width: "100%",
    },
};
