import { EpiEdit } from "@basics/EpiEdit";
import { AdaptiveImageComponent } from "@components/AdaptiveImageComponent/AdaptiveImageComponent";
import React from "react";
import { useStyles } from "./styles";
export var ContactPhoto = function (_a) {
    var photo = _a.photo;
    var cardPhoto = photo.value ? photo.value : null;
    var _b = useStyles(), contactPhoto = _b.contactPhoto, contactPhotoWrapper = _b.contactPhotoWrapper;
    if (!cardPhoto)
        return null;
    return (React.createElement(EpiEdit, { property: photo, className: contactPhotoWrapper },
        React.createElement(AdaptiveImageComponent, { large: cardPhoto.urlLarge, medium: cardPhoto.urlMedium, small: cardPhoto.urlSmall, alt: cardPhoto.alternateText, className: contactPhoto })));
};
