var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { animations } from "@basics/animations";
import { colors } from "@basics/colors";
import { Col, Container, Row, onBreakpoint, rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { typography } from "@basics/typography";
import { CloudinaryVideoPlayer, } from "@components/CloudinaryVideoPlayer/CloudinaryVideoPlayer";
import { Modal } from "@components/Modal/Modal";
import { Spinner } from "@components/Spinner/Spinner";
import { SvgRenderer } from "@components/Block/SvgBlock/SvgRenderer";
var modalStyles = createUseStyles(__assign(__assign({}, animations.grow(0.75)), { imageCarousel: {
        width: "100%",
        margin: "0 auto",
        "& .image-gallery-slide": {
            outline: "none",
            "&.center": {
                opacity: 1,
                transform: "scale(1) !important",
                transition: "all 0.8s ease !important",
            },
            "&.left, &.right": {
                opacity: 0,
                transform: "scale(0.8) !important",
                transition: "all 0.4s ease !important",
            },
            "& > div": {
                position: "absolute",
                width: "100%",
                height: "100%",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            },
        },
        "& .image-gallery-image": {
            width: "auto",
            maxWidth: "100%",
            objectFit: "scale-down",
            filter: "drop-shadow(0px 0px 10px rgba(0,0,0,.4))",
            animation: "$grow 0.3s ease",
        },
        "& .image-gallery-description": __assign(__assign(__assign({}, typography.textSmall), { display: "block", width: "100%", marginTop: rem(spacings.xs), marginBottom: 0, padding: 0, position: "static", backgroundColor: "transparent" }), onBreakpoint("md", {
            marginTop: rem(spacings.sam),
        })),
        "& .image-gallery-icon": {
            opacity: 0.85,
            transition: "opacity 0.3s ease",
            color: colors.white,
            padding: "0 10px",
            filter: "none",
            "&:hover": {
                opacity: 1,
                transition: "opacity 0.3s ease",
            },
            "&:after": {
                fontFamily: "Font Awesome\\ 5 Pro",
                fontSize: "3.9rem",
                lineHeight: 1,
            },
        },
        "& .image-gallery-left-nav": {
            left: -40,
            "&:after": {
                content: '"\\f104"',
            },
        },
        "& .image-gallery-right-nav": {
            right: -40,
            "&:after": {
                content: '"\\f105"',
            },
        },
        "& .image-gallery-left-nav, & .image-gallery-right-nav": {
            outline: "none",
            border: "1px solid transparent",
            "&:focus": {
                border: "1px solid ".concat(colors.white),
                borderRadius: "5px",
            },
        },
        "& .image-gallery-thumbnails-wrapper, & .image-gallery-svg": {
            display: "none",
        },
    }, videoElementWrapper: {
        display: "flex",
        justifyContent: "center",
    }, videoElement: {
        width: "min(calc(80vh*16/9), 100%)",
        filter: "drop-shadow(0px 0px 10px rgba(0,0,0,.4))",
    }, imageDescription: {
        color: colors.white,
        userSelect: "text",
    }, svgElement: {
        width: "100%",
        height: "100%",
    } }));
export function ModalWithCarousel(props) {
    var _a;
    var styles = modalStyles({
        isOpened: props.modalOpened,
    });
    function transformImages(images) {
        return images.map(function (img) {
            var _a, _b, _c, _d;
            return ({
                original: ((_a = img.image) === null || _a === void 0 ? void 0 : _a.urlOriginal) ||
                    ((_b = img.image) === null || _b === void 0 ? void 0 : _b.urlLarge) ||
                    ((_c = img.image) === null || _c === void 0 ? void 0 : _c.urlMedium) ||
                    ((_d = img.image) === null || _d === void 0 ? void 0 : _d.urlSmall) ||
                    "",
                description: (React.createElement("div", { className: styles.imageDescription, dangerouslySetInnerHTML: { __html: img.description } })),
            });
        });
    }
    var _b = useState(null), ImageGallery = _b[0], setImageGallery = _b[1];
    useEffect(function () {
        import(
        /* webpackChunkName: "zza.react-image-gallery" */ "react-image-gallery").then(function (exportObject) { return setImageGallery(function () { return exportObject.default; }); });
    }, []);
    return (React.createElement(Modal, __assign({}, props),
        React.createElement(Container, null,
            React.createElement(Row, null,
                React.createElement(Col, null,
                    props.images && (React.createElement(React.Fragment, null, ImageGallery ? (React.createElement(ImageGallery, { items: transformImages(props.images), slideDuration: 200, showFullscreenButton: false, showPlayButton: false, additionalClass: styles.imageCarousel })) : (React.createElement(Spinner, null)))),
                    props.video && (React.createElement("div", { className: styles.videoElementWrapper },
                        React.createElement("div", { className: styles.videoElement },
                            React.createElement(CloudinaryVideoPlayer, __assign({}, props.video, { thumbnailUrl: undefined }))))),
                    props.svg && (React.createElement(SvgRenderer, { url: ((_a = props.svg.value) === null || _a === void 0 ? void 0 : _a.url) || "", className: styles.svgElement })),
                    props.children)))));
}
