var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext, useEffect, useState } from "react";
import { GenericModalBodyContainer, Modal } from "@components/Modal/Modal";
import { createUseStyles } from "react-jss";
import { typography } from "@basics/typography";
import { Button } from "@components/Button/Button";
import { LocalizationContext, localizedLabelString, } from "@hooks/LocalizationContext";
var useStyles = createUseStyles({
    content: __assign(__assign({}, typography.textDefault), { margin: "0 !important", "& p": {
            marginTop: 0,
        } }),
    buttonWrapper: {
        textAlign: "right",
    },
});
function wasUnsupportedBrowserNoticeDisplayed() {
    return Boolean(localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem("unsupportedBrowserNoticeDisplayed"));
}
function setUnsupportedBrowserNoticeDisplayed() {
    return localStorage === null || localStorage === void 0 ? void 0 : localStorage.setItem("unsupportedBrowserNoticeDisplayed", "true");
}
function isIETest() {
    var ua = navigator.userAgent;
    return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
}
export function UnsupportedBrowserNotice() {
    var _a = useState(false), modalOpened = _a[0], setModalOpened = _a[1];
    var localizationCtx = useContext(LocalizationContext);
    useEffect(function () {
        if (!wasUnsupportedBrowserNoticeDisplayed()) {
            setModalOpened(isIETest());
        }
    }, []);
    function close() {
        setUnsupportedBrowserNoticeDisplayed();
        setModalOpened(false);
    }
    var styles = useStyles();
    var headingLabel = localizedLabelString({
        section: "UnsupportedBrowserNotice",
        label: "Heading",
        localizationCtx: localizationCtx,
    });
    var contentLabel = localizedLabelString({
        section: "UnsupportedBrowserNotice",
        label: "Content",
        localizationCtx: localizationCtx,
    });
    var acceptButtonLabel = localizedLabelString({
        section: "UnsupportedBrowserNotice",
        label: "AcceptButton",
        localizationCtx: localizationCtx,
    });
    return (React.createElement(Modal, { modalOpened: modalOpened, closeModal: function () { return close(); } },
        React.createElement(GenericModalBodyContainer, { heading: headingLabel },
            React.createElement("div", { className: styles.content },
                React.createElement("p", null, contentLabel),
                React.createElement("div", { className: styles.buttonWrapper },
                    React.createElement(Button, { onClick: function () { return close(); } }, acceptButtonLabel))))));
}
