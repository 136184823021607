var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import cn from "classnames";
import { geoMercator } from "d3-geo";
import isEqual from "lodash/isEqual";
import uniqBy from "lodash/uniqBy";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { ComposableMap, Geographies, Geography, ZoomableGroup, } from "react-simple-maps";
import { colors } from "@basics/colors";
import { convertPascalCaseToCamelCase } from "@basics/convertCases";
import { Container, onBreakpoint, rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { styles, useBasicStyles } from "@basics/styles";
import { RenderComponent } from "@/src/views/RenderComponent";
import { LocationCardSlider } from "./LocationCardSlider";
import { LocationMarkers } from "./LocationMarker";
import dataService from "./MapDataService";
import { RegionsBarNavigation } from "./RegionsBarNavigation";
import { ZoomOutButton } from "./ZoomOutButton";
import { features } from "./features";
import { calculateAreaSize, calculateCenterPointInProjection, findCountryAndRegion, getCountryColor, getFeatures, handleCountryClick, isCountryClickable, onCountryPointerEnter, onCountryPointerLeave, zoomToSelection, } from "./regionsExtentions";
var useStyles = createUseStyles({
    interactiveMap: __assign({ display: "flex", flexDirection: "column", gap: rem(spacings.s), position: "relative", background: colors.gray10, "& path.rsm-geography": {
            outline: "none",
            transition: "all .1s",
        } }, styles.componentMargin),
    interactiveMapContent: {
        padding: "0 ".concat(rem(spacings.s), " ").concat(rem(spacings.s), " ").concat(rem(spacings.s)),
    },
    mapCards: {
        position: "absolute",
        left: "0",
        top: "0",
        width: "100%",
        height: "100%",
    },
    mapAreaContainer: {
        flexGrow: 1,
        height: "100%",
    },
    mapArea: {
        height: "100%",
        position: "relative",
    },
    sticky: {
        position: "sticky",
        bottom: rem(spacings.s),
        width: "fit-content",
    },
    mapWrapper: __assign({ display: "none" }, onBreakpoint("md", {
        display: "block",
    })),
    alternativeContentWrapper: __assign({ display: "block" }, onBreakpoint("md", {
        display: "none",
    })),
    path: {
        vectorEffect: "non-scaling-stroke",
    },
    showMarker: {
        display: "block",
    },
    hideMarker: {
        display: "none",
    },
});
export var markerColors = {
    "dark-red": "#8D1429",
    "light-orange": "#FF9900",
    "light-blue": "#00A3A8",
};
export var markerBackgroundColors = {
    "dark-red": "#FCE5E7",
    "light-orange": "#FFF4E5",
    "light-blue": "#e5f6f6",
};
export var markerIcons = [
    "building",
    "map-marked",
    "map-marked-alt",
    "map-marker-alt",
    "thumbtack",
    "location",
    "industry-alt",
    "shield-check",
    "tools",
    "ship",
    "truck",
    "cars",
    "phone-laptop",
    "pills",
    "briefcase-medical",
    "microscope",
    "plane-departure",
    "user-friends",
    "futbol",
    "umbrella",
    "money-check-edit",
    "balance-scale",
    "street-view",
    "user-tie",
    "conveyor-belt-alt",
    "user-chart",
];
export var InteractiveMapBlock = function (props) {
    var _a, _b;
    var WIDTH = 1500;
    var _c = useBasicStyles(), pointer = _c.pointer, defaultPointer = _c.defaultPointer;
    var _d = useStyles(), interactiveMap = _d.interactiveMap, mapCards = _d.mapCards, mapArea = _d.mapArea, mapAreaContainer = _d.mapAreaContainer, sticky = _d.sticky, mapWrapper = _d.mapWrapper, alternativeContentWrapper = _d.alternativeContentWrapper, path = _d.path, showMarker = _d.showMarker, hideMarker = _d.hideMarker;
    var geoFeatures = getFeatures();
    var projection = geoMercator()
        .fitWidth(WIDTH, geoFeatures)
        .rotate([-10, 0, 0]);
    var _e = calculateAreaSize(geoFeatures, projection), mapHeight = _e[1];
    var centerOfMapAsCoordinates = calculateCenterPointInProjection(geoFeatures, projection);
    var _f = useState({
        coordinates: centerOfMapAsCoordinates,
        zoom: 1,
    }), position = _f[0], setPosition = _f[1];
    var _g = useState(props.regions), mapData = _g[0], setMapData = _g[1];
    var _h = useState(null), currentHoverRegion = _h[0], setCurrentHoverRegion = _h[1];
    var _j = useState(null), currentHoverCountry = _j[0], setCurrentHoverCountry = _j[1];
    var _k = useState(null), currentOffice = _k[0], setCurrentOffice = _k[1];
    var _l = useState(null), selectedRegion = _l[0], setSelectedRegion = _l[1];
    var _m = useState(false), showMarkers = _m[0], setShowMarkers = _m[1];
    var getData = (_a = props.getData) !== null && _a !== void 0 ? _a : dataService.getData;
    useEffect(function () {
        var _a;
        ((_a = props.data.contentLink) === null || _a === void 0 ? void 0 : _a.id) &&
            getData(props.data.contentLink.id).then(function (response) {
                return response && setMapData(convertPascalCaseToCamelCase(response));
            });
    }, [props.data.contentLink.id, getData]);
    useEffect(function () {
        setShowMarkers(true);
    }, [position]);
    var handleRegionHover = function (region) {
        setCurrentHoverRegion(region);
    };
    var handleRegionLeave = function () {
        setCurrentHoverRegion(null);
    };
    var handleRegionClick = function (region) {
        var position = zoomToSelection(region, projection, geoFeatures.features);
        if (position) {
            var defaultOffices = region.defaultOffices;
            setSelectedRegion(region);
            handlePostionChange(position);
            defaultOffices && defaultOffices[0]
                ? setCurrentOffice(defaultOffices[0])
                : setCurrentOffice(null);
        }
    };
    var handleCountryHover = function (country) {
        if (selectedRegion) {
            setCurrentHoverCountry(country);
        }
    };
    var handlePostionChange = function (position) {
        setPosition(position);
    };
    var zoomOut = function () {
        setShowMarkers(false);
        setPosition({ coordinates: centerOfMapAsCoordinates, zoom: 1 });
        setCurrentOffice(null);
        setSelectedRegion(null);
    };
    var getStylesForGeo = function (geo, mapData) {
        var pointerClasses = isCountryClickable(geo, mapData, selectedRegion)
            ? pointer
            : defaultPointer;
        return [pointerClasses, path];
    };
    var getOffices = function (region) {
        var offices = region.countries.flatMap(function (country) {
            return country.offices || [];
        });
        var defaultOffices = region.defaultOffices || [];
        var allOffices = uniqBy(__spreadArray(__spreadArray([], defaultOffices, true), offices, true), function (office) {
            return office.contentLink.id;
        });
        if (currentOffice) {
            return allOffices;
        }
        else {
            return [];
        }
    };
    var offices = selectedRegion ? getOffices(selectedRegion) : [];
    var findRegionsWithUSA = function () {
        return ((mapData === null || mapData === void 0 ? void 0 : mapData.regions.filter(function (region) {
            return region.countries.find(function (country) { return country.id === "USA"; });
        })) || []);
    };
    var extendRegionWithAlaska = function () {
        var regionsWithUSA = findRegionsWithUSA();
        return regionsWithUSA.map(function (region) {
            var usa = region.countries.find(function (country) { return country.id === "USA"; });
            var alaska = {
                id: "US-AK",
                zoomable: false,
                customColor: usa.customColor,
                disabled: usa.disabled,
                offices: null,
            };
            region.countries = uniqBy(__spreadArray(__spreadArray([], region.countries, true), [alaska], false), "id");
            return region;
        });
    };
    extendRegionWithAlaska();
    if (!mapData) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: mapWrapper },
            React.createElement(Container, { isFluid: true },
                React.createElement("div", { className: interactiveMap },
                    React.createElement(RegionsBarNavigation, { regions: mapData.regions, onClick: handleRegionClick, selectedRegion: selectedRegion, onMouseEnter: handleRegionHover, onMouseLeave: handleRegionLeave, currentHoverRegion: currentHoverRegion }),
                    React.createElement("div", { className: mapAreaContainer },
                        React.createElement(Container, { style: { height: "100%" } },
                            React.createElement("div", { className: mapArea },
                                React.createElement(ComposableMap, { width: WIDTH, height: mapHeight, projection: projection },
                                    React.createElement(ZoomableGroup, { zoom: position.zoom, maxZoom: 25, center: position.coordinates, filterZoomEvent: function () { return false; } },
                                        React.createElement(Geographies, { geography: features }, function (_a) {
                                            var geographies = _a.geographies;
                                            return geographies.map(function (geo) {
                                                var _a;
                                                var isHighlighted = !selectedRegion ||
                                                    isEqual((_a = findCountryAndRegion(geo, mapData.regions)) === null || _a === void 0 ? void 0 : _a.region, selectedRegion);
                                                var countryColor = getCountryColor(mapData, currentHoverRegion, currentHoverCountry, geo, selectedRegion);
                                                var stroke = position.zoom <= 2 ? 0.2 : 0.6;
                                                return (React.createElement(React.Fragment, { key: geo.rsmKey },
                                                    React.createElement(Geography, { className: cn(getStylesForGeo(geo, mapData)), key: geo.rsmKey, geography: geo, onMouseEnter: function () {
                                                            onCountryPointerEnter(mapData, geo, handleRegionHover, handleCountryHover);
                                                        }, onMouseLeave: function () {
                                                            onCountryPointerLeave(handleRegionLeave, handleCountryHover);
                                                        }, onClick: function () {
                                                            handleCountryClick(mapData, geo, handleRegionClick, selectedRegion);
                                                        }, fill: countryColor, stroke: "white", strokeWidth: stroke, opacity: isHighlighted ? "1" : "0.8" })));
                                            });
                                        }),
                                        React.createElement("g", { className: showMarkers ? showMarker : hideMarker },
                                            React.createElement(LocationMarkers, { selectedRegion: selectedRegion, regions: mapData.regions, position: position, setCurrentOffice: setCurrentOffice })))),
                                currentOffice && (React.createElement("div", { className: mapCards },
                                    React.createElement(LocationCardSlider, { offices: offices, onClose: function () { return setCurrentOffice(null); }, officeClickedOnMap: currentOffice })))),
                            React.createElement("div", { className: sticky },
                                React.createElement(ZoomOutButton, { onClick: function () { return zoomOut(); }, disabled: !selectedRegion }))))))),
        React.createElement("div", { className: alternativeContentWrapper }, (_b = props.data.alternativeContent.expandedValue) === null || _b === void 0 ? void 0 : _b.map(function (item) {
            return (React.createElement(RenderComponent, { contentLink: item.contentLink, key: item.contentLink.guidValue }));
        }))));
};
