var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { onBreakpoint } from "@basics/layout";
import { Button } from "@components/Button/Button";
import { FAIcon } from "@components/FAIcon/FAIcon";
var useStyles = createUseStyles({
    buttonUp: function () { return (__assign(__assign({ position: "fixed", bottom: "28px", right: "16px" }, onBreakpoint("sm", {
        bottom: "36px",
        right: "40px",
    })), { zIndex: 100 })); },
});
var useScrollPosition = function () {
    var _a = useState(window.scrollY), scrollPosition = _a[0], setScrollPosition = _a[1];
    useEffect(function () {
        var handleScroll = function () { return setScrollPosition(window.scrollY); };
        window.addEventListener("scroll", handleScroll);
        return function () {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return scrollPosition;
};
export var ButtonUp = function () {
    var styles = useStyles();
    var scrollPosition = useScrollPosition();
    var showButtonUp = scrollPosition > 0 ? true : false;
    var handleScrollToTop = function () {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    return (React.createElement(React.Fragment, null, showButtonUp && (React.createElement(Button, { type: "primary", className: styles.buttonUp, onClick: handleScrollToTop, shape: "square" },
        React.createElement(FAIcon, { icon: "chevron-up" })))));
};
