import cn from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";
var useStyles = createUseStyles({
    object: {
        // in order not to add line-break after the element caused by display: inline-block applied by <button> as its default style
        display: "block",
        pointerEvents: "none",
    },
});
export var SvgRenderer = function (_a) {
    var url = _a.url, className = _a.className;
    var styles = useStyles();
    return (React.createElement("object", { type: "image/svg+xml", data: url || "", className: cn(className, styles.object) }));
};
