var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { brandColors } from "@basics/brand-colors";
import { onBreakpoint } from "@basics/layout";
import { typography } from "@basics/typography";
import React from "react";
import { createUseStyles } from "react-jss";
export var useStyles = createUseStyles({
    videoClickArea: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "transparent",
        cursor: "pointer",
        zIndex: 3,
        "&:focus, &:hover": {
            "& $videoClickBackground": {
                transform: "translate(-50%, -50%) scale(1.04)",
                transition: "transform 0.3s ease, background-color 0.3s ease",
                backgroundColor: brandColors.white,
            },
            "& $videoClickTriangle": {
                transform: "translate(-50%, -50%) scale(1.04)  rotate(-45deg) skew(15deg, 15deg)",
                transition: "transform 0.3s ease",
            },
        },
    },
    videoClickBackground: __assign(__assign({ content: '""', width: 100, height: 100, maxHeight: "60%", maxWidth: "calc(60% * 9 / 16)" }, onBreakpoint("sm", {
        width: 140,
        height: 140,
    })), { backgroundColor: brandColors.ultraLightGrey, position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", transition: "transform 0.3s ease, background-color 0.3s ease", borderRadius: "50%" }),
    videoClickContainer: {},
    videoClickTriangle: __assign({ top: "50%", left: "39%", maxHeight: "37%", maxWidth: "37%", position: "absolute", transform: "translate(-50%, -50%) scale(1) rotate(-45deg) skew(15deg, 15deg)", transition: "transform 0.3s ease 0s", overflow: "hidden", background: "linear-gradient(to bottom right, transparent 0%, transparent 50%, ".concat(brandColors.darkGrey, " 50%, ").concat(brandColors.darkGrey, " 100%)"), content: '""', width: 32, height: 32 }, onBreakpoint("sm", {
        left: "37%",
        width: 52,
        height: 52,
    })),
    visuallyHidden: __assign({}, typography.visuallyHidden),
});
export var VideoClickableArea = function (props) {
    var onClick = props.onClick, onMouseOut = props.onMouseOut, onMouseOver = props.onMouseOver, onFocus = props.onFocus, onBlur = props.onBlur;
    var styles = useStyles();
    return (React.createElement("button", { className: styles.videoClickArea, onClick: onClick, onMouseOver: onMouseOver, onMouseOut: onMouseOut, onFocus: onFocus, onBlur: onBlur },
        React.createElement("div", { className: styles.videoClickBackground },
            React.createElement("div", { className: styles.videoClickContainer },
                React.createElement("div", { className: styles.videoClickTriangle }))),
        React.createElement("span", { className: styles.visuallyHidden }, "Click to play the video")));
};
