import React from "react";
import { RenderProperty } from "@/src/views/RenderProperty";
import { createUseStyles } from "react-jss";
import { spacings } from "@basics/spacings";
import cn from "classnames";
import { rem } from "@basics/layout";
import { FAIconStyles, FAIcon } from "@components/FAIcon/FAIcon";
import { colors } from "@basics/colors";
var useStyles = createUseStyles({
    flatList: {
        display: "flex",
        flexDirection: "column",
        marginTop: "1rem",
        "& a": {
            marginBottom: 10,
            color: colors.gray80,
            transition: "color 0.2s ease",
            "&:hover": {
                color: colors.gray100,
                transition: "color 0.2s ease",
            },
        },
    },
    mobilePadding: {
        paddingTop: rem(spacings.m),
    },
    mobileArea: {
        width: "100%",
        padding: 0,
        border: "none",
        font: "inherit",
        color: "inherit",
        textAlign: "inherit",
        background: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    headline: {
        color: colors.gray100,
    },
    blockPadding: {
        marginBottom: rem(spacings.m),
    },
    icon: FAIconStyles({
        width: 24,
        height: 24,
    }),
});
export default function FooterLinkBlock(props) {
    var styles = useStyles();
    var guid = props.data.contentLink.guidValue;
    return props.context ? (React.createElement("div", { className: styles.mobilePadding },
        React.createElement("button", { onClick: function () {
                var _a, _b;
                return ((_a = props.context) === null || _a === void 0 ? void 0 : _a.setOpenSection) &&
                    ((_b = props.context) === null || _b === void 0 ? void 0 : _b.setOpenSection(function (section) {
                        return section === guid ? "" : guid || "";
                    }));
            }, className: styles.mobileArea },
            React.createElement("b", null,
                React.createElement(RenderProperty, { className: cn(styles.headline, props.className), value: props.data.headline })),
            !props.context.uncollapsed &&
                (props.context.openSection === guid ? (React.createElement(FAIcon, { icon: "chevron-up", className: styles.icon })) : (React.createElement(FAIcon, { icon: "chevron-down", className: styles.icon })))),
        (props.context.openSection === guid || props.context.uncollapsed) && (React.createElement(RenderProperty, { className: cn(props.className, styles.flatList), value: props.data.links })))) : (React.createElement("div", { className: styles.blockPadding },
        React.createElement("b", null,
            React.createElement(RenderProperty, { className: cn(styles.headline, props.className), value: props.data.headline })),
        React.createElement(RenderProperty, { value: props.data.links, className: cn(props.className, styles.flatList) })));
}
