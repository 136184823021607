var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cn from "classnames";
import React, { useContext } from "react";
import { AdaptiveImageComponent } from "@components/AdaptiveImageComponent/AdaptiveImageComponent";
import { AnimatedRevealBlock, AnimatedRevealInline, } from "@components/AnimatedReveal/AnimatedReveal";
import { Link } from "@components/Shared/Link";
import { EpiContext } from "@hooks/EpiContext";
// That is really important that RichTextWithTypographyRenderer is imported earlier than RenderComponent
// There is issue with the order of CSS
import { RichTextWithTypographyRenderer } from "@components/Block/RichTextBlock/RichTextBlock";
import { RenderComponent } from "./RenderComponent";
var isJest = process.env.NODE_ENV === "test";
var isDevelopment = process.env.NODE_ENV === "development";
function checkIfIContentProperty(prop) {
    if (prop &&
        prop.propertyDataType &&
        typeof prop.propertyDataType === "string") {
        return true;
    }
    return false;
}
export function RenderProperty(props) {
    var _a, _b;
    var _c = useContext(EpiContext), isDebug = _c.isDebug, isEditable = _c.isEditable;
    // INFO(mkarol): In edit mode, properties are annotated with Proxy in GlobalContentStore
    var field = isEditable ? (_a = props.value) === null || _a === void 0 ? void 0 : _a["__key__"] : "";
    if (!props.value && typeof props.value !== "string") {
        if (props.fallbackValue)
            return React.createElement(RenderProperty, { value: props.fallbackValue });
        if (!isDebug)
            return null;
        return (React.createElement("div", { style: { display: "none" }, "data-epi-edit": field }, "Property not present"));
    }
    var PropertyTypeNotKnown = function (propType) {
        return isDebug ? (React.createElement("div", { className: "alert alert-warning", "data-epi-edit": field },
            "Property type ",
            propType,
            " with value ",
            JSON.stringify(props),
            " not supported")) : null;
    };
    if (!checkIfIContentProperty(props.value) &&
        typeof props.value !== "string") {
        return PropertyTypeNotKnown(typeof props);
    }
    if (!checkIfIContentProperty(props.value)) {
        return (React.createElement("span", { className: props.className, "data-epi-edit": isEditable ? field : undefined }, props.value));
    }
    var fallbackValue = props.fallbackValue && checkIfIContentProperty(props.fallbackValue)
        ? (_b = props.fallbackValue) === null || _b === void 0 ? void 0 : _b.value
        : props.fallbackValue;
    var propValue = props.value.value || (props.fallbackValue && fallbackValue) || null;
    var propType = props.value.propertyDataType;
    try {
        switch (propType) {
            case "PropertyString":
            case "PropertyLongString":
                return (React.createElement("span", { className: props.className, "data-epi-edit": isEditable ? field : undefined }, propValue));
            case "PropertyUrl":
                return (React.createElement("a", { href: propValue, className: props.className, "data-epi-edit": isEditable ? field : undefined }, props.children || propValue));
            case "PropertyDecimal":
            case "PropertyNumber":
            case "PropertyFloatNumber":
                return (React.createElement("span", { className: cn(["number", props.className]), "data-epi-edit": isEditable ? field : undefined }, propValue));
            case "PropertyXhtmlString":
                return (React.createElement(RichTextWithTypographyRenderer, { className: props.className, dataEpiEdit: isEditable ? field : undefined, value: propValue }));
            case "PropertyLinkCollection": {
                return (React.createElement("div", { "data-epi-edit": isEditable ? field : undefined, className: props.className }, propValue.map(function (link, idx) {
                    if (link.href.includes("__run:function__")) {
                        var newLink = __assign(__assign({}, link), { href: window.location.pathname +
                                window.location.search +
                                "#open-privacy-overlay" });
                        return React.createElement(Link, __assign({}, newLink, { key: idx }));
                    }
                    else {
                        return React.createElement(Link, __assign({}, link, { key: idx }));
                    }
                })));
            }
            case "PropertyContentReference":
            case "PropertyContentReferenceList":
            case "PropertyPageReference":
            case "PropertyContentArea": {
                var item = propValue &&
                    (Array.isArray(propValue) ? (propValue.map(function (propValueSingle, idx) { return (React.createElement(RenderComponent, { contentLink: propValueSingle.contentLink, className: props.className, key: idx, context: props.context, theme: props.theme })); })) : (React.createElement(RenderComponent, { contentLink: propValue, className: props.className, context: props.context, theme: props.theme })));
                return (React.createElement("div", { "data-epi-edit": isEditable ? field : undefined, className: props.className }, item));
            }
            case "AdaptiveImageProperty": {
                var adaptiveData = propValue;
                return (React.createElement("div", { "data-epi-edit": isEditable ? field : undefined },
                    React.createElement(AdaptiveImageComponent, { large: adaptiveData.urlLarge, medium: adaptiveData.urlMedium, small: adaptiveData.urlSmall, alt: adaptiveData.altSmall })));
            }
            case "SingleImageProperty": {
                var adaptiveData = propValue;
                return (React.createElement("div", { "data-epi-edit": isEditable ? field : undefined },
                    React.createElement(AdaptiveImageComponent, { large: adaptiveData.urlLarge, medium: adaptiveData.urlMedium, small: adaptiveData.urlSmall, alt: adaptiveData.alternateText, className: props.className })));
            }
            case "CaptionHeadlineProperty": {
                return (React.createElement("span", { className: props.className, "data-epi-edit": isEditable ? field : undefined }, propValue));
            }
            default:
                return PropertyTypeNotKnown(propType);
        }
    }
    catch (error) {
        if (isJest || isDevelopment) {
            !isJest && console.error(error);
            throw error;
        }
        return null;
    }
}
export var AnimatedRenderProperty = function (props) { return (React.createElement(AnimatedRevealInline, __assign({}, props, { revealDelay: props.revealDelay || 0 }),
    React.createElement(RenderProperty, __assign({}, props)))); };
export var AnimatedBlockRenderProperty = function (props) { return (React.createElement(AnimatedRevealBlock, __assign({}, props, { revealDelay: props.revealDelay || 0 }),
    React.createElement(RenderProperty, __assign({}, props)))); };
export function ShowPropertyOnEdit(property) {
    var isEditable = useContext(EpiContext).isEditable;
    return Boolean(property &&
        (isEditable ||
            typeof property === "string" ||
            (checkIfIContentProperty(property) && property.value)));
}
