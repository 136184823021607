var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { Footer } from "@components/Shared/Footer";
import { AnimatedRenderProperty, RenderProperty, } from "@/src/views/RenderProperty";
import { ContactExpert } from "@components/ContactExpert/ContactExpert";
import { isCypressTest } from "@basics/isCypressTest";
import { StartPageHeader } from "@components/Header/StartPageHeader";
export function StartPage(props) {
    var _a;
    return (React.createElement(React.Fragment, null,
        !isCypressTest() && React.createElement(StartPageHeader, __assign({}, props.data)),
        React.createElement("main", { "data-testid": "StartPage" },
            React.createElement(AnimatedRenderProperty, { value: props.data.quickLinks, theme: "homepage" }),
            React.createElement(RenderProperty, { value: props.data.mainContent, theme: "homepage" }),
            React.createElement(RenderProperty, { value: props.data.additionalContent }),
            ((_a = props.data.contactCta) === null || _a === void 0 ? void 0 : _a.value) === "enabled" && (React.createElement(ContactExpert, __assign({}, props.data)))),
        React.createElement(Footer, __assign({}, props.data))));
}
