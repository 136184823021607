import { useContext } from "react";
import { GlobalContentStoreContext } from "./GlobalContentStore";
export var useCurrentPage = function () {
    var _a = useContext(GlobalContentStoreContext), getMainPageContentLink = _a.getMainPageContentLink, getByContentLinkId = _a.getByContentLinkId;
    var mainPageContentLink = getMainPageContentLink();
    if (mainPageContentLink) {
        return getByContentLinkId(mainPageContentLink === null || mainPageContentLink === void 0 ? void 0 : mainPageContentLink.id.toString());
    }
    else {
        return null;
    }
};
