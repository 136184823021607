var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { colors } from "@basics/colors";
import { fonts } from "@basics/fonts";
import { onBefore, onBreakpoint, getGridColumnWidth, CONTAINER_OFFSET_WIDTHS, MARGIN_OF_CONTAINER_LIMITED, rem, } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { typography, customTypography } from "@basics/typography";
import { createUseStyles } from "react-jss";
export var useStyles = createUseStyles({
    textImageContainer: __assign(__assign({}, onBefore("sm", {
        marginTop: "42vw",
    })), { marginTop: "calc(100vw / 16 * 10 - ".concat(spacings.l, ")") }),
    textVideoContainer: __assign(__assign({}, onBefore("sm", {
        marginTop: "42vw",
    })), { marginTop: "calc(100vw / 16 * 9 - ".concat(spacings.l, ")") }),
    textContainer: __assign(__assign({}, onBreakpoint("sm", {
        marginTop: 0,
        position: "absolute",
        height: "100%",
        width: "100%",
        top: "calc(".concat(getGridColumnWidth("sm"), ")"),
    })), onBreakpoint("md", {
        top: "calc(".concat(getGridColumnWidth("md"), ")"),
    })),
    itemSectionColumn: {
        gridRow: 1,
        width: "100%",
        gridColumnStart: function (isEven) { return (isEven ? 1 : undefined); },
    },
    leftItem: __assign(__assign({}, onBreakpoint("sm", {
        marginLeft: -CONTAINER_OFFSET_WIDTHS["big"],
    })), onBreakpoint("md", {
        // INFO(mkarol): CSS Calc magic here :) We are moving to the left by value of margin:auto of container minus single padding
        marginLeft: "calc(-1 * ".concat(MARGIN_OF_CONTAINER_LIMITED, " - ").concat(CONTAINER_OFFSET_WIDTHS["big"], "px)"),
    })),
    rightItem: __assign(__assign({}, onBreakpoint("sm", {
        marginRight: -CONTAINER_OFFSET_WIDTHS["big"],
    })), onBreakpoint("md", {
        // INFO(mkarol): CSS Calc magic here :) We are moving to the left by value of margin:auto of container minus single padding
        marginRight: "calc(-1 * ".concat(MARGIN_OF_CONTAINER_LIMITED, " - ").concat(CONTAINER_OFFSET_WIDTHS["big"], "px)"),
    })),
    itemSection: {
        "& div[class^='videoClickBackground']": __assign(__assign({}, onBreakpoint("sm", {
            width: 85,
            height: 85,
        })), onBreakpoint("md", {
            width: 115,
            height: 115,
        })),
        "& div:not([class^='videoClickBackground'])": {
            height: "100%",
        },
        "& div[class^='vjs-poster']": {
            backgroundPosition: "center !important",
        },
        "& img": {
            objectFit: "cover",
        },
    },
    h2: __assign(__assign({}, typography.h2), { wordBreak: "break-word" }),
    textDefault: customTypography(__assign({ extend: typography.textDefault }, fonts.book), { marginBottom: 0 }, { marginBottom: 0 }, { marginBottom: 0 }),
    teaserItemBg: __assign(__assign({ width: "100vw", backgroundColor: colors.gray10, paddingTop: "62.5%" }, onBefore("sm", {
        position: "absolute",
        width: "100vw",
        marginLeft: -CONTAINER_OFFSET_WIDTHS["small"],
    })), onBreakpoint("sm", {
        width: "calc(".concat(MARGIN_OF_CONTAINER_LIMITED, " + ").concat(CONTAINER_OFFSET_WIDTHS["big"], "px + 100%)"),
    })),
    teaserItem: __assign(__assign({ position: "absolute", top: 0, display: "flex", flexDirection: "column", padding: "".concat(spacings.l, " ").concat(spacings.sam), zIndex: 1 }, onBreakpoint("sm", {
        padding: function (isEven) {
            return isEven
                ? "".concat(spacings.l, " 0 ").concat(spacings.l, " 15%")
                : "".concat(spacings.l, " calc(").concat(getGridColumnWidth("sm"), " + ").concat(spacings.s, ") ").concat(spacings.l, " ").concat(spacings.m);
        },
    })), onBreakpoint("md", {
        padding: function (isEven) {
            return isEven
                ? "".concat(spacings.xxl, " 0 ").concat(spacings.xxl, " 15%")
                : "".concat(spacings.xxl, " calc(").concat(getGridColumnWidth("md"), " + ").concat(spacings.s, ") ").concat(spacings.xxl, " calc(").concat(MARGIN_OF_CONTAINER_LIMITED, " + ").concat(CONTAINER_OFFSET_WIDTHS["big"], "px)");
        },
    })),
    button: {
        alignSelf: "flex-start",
    },
    icon: {
        marginLeft: rem(spacings.s),
    },
    fullHeight: {
        height: "100%",
    },
    descriptionBox: __assign(__assign(__assign({ display: "none", position: "relative", overflow: "hidden", flex: 1 }, onBreakpoint("sm", {
        display: "block",
        marginBottom: rem(spacings.m),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xl),
    })), { "&:after": {
            content: '""',
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: rem(spacings.m.times(2)),
            background: "linear-gradient(to bottom, ".concat(colors.gray10, "00, ").concat(colors.gray10, "FF 100%)"),
        } }),
});
