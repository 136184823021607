var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { createUseStyles } from "react-jss";
import { Container, onBreakpoint, rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { MediaCarouselComponent, } from "./MediaCarouselComponent";
var useStyles = createUseStyles({
    element: __assign(__assign({ marginBottom: rem(spacings.m) }, onBreakpoint("sm", {
        display: "block",
        marginBottom: rem(spacings.l),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xxl),
    })),
});
export var MediaCarouselBlock = function (props) {
    var element = useStyles().element;
    return (React.createElement(Container, { className: element },
        React.createElement(MediaCarouselComponent, __assign({}, props))));
};
