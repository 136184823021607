import React from "react";
import cn from "classnames";
import { createUseStyles } from "react-jss";
var useStyles = createUseStyles({
    link: {
        "a&": {
            textDecoration: "none",
            "&:hover": {
                color: "inherit",
            },
            "&:focus": {
                color: "inherit",
            },
        },
    },
});
export var Link = function (props) {
    var styles = useStyles();
    return (React.createElement("a", { href: props.href, title: props.title, target: props.target, className: cn(styles.link, props.className), rel: props.target === "_blank" ? "noreferer noopener" : undefined },
        props.text,
        props.children));
};
