import { Link } from "@components/Shared/Link";
import React from "react";
import { createUseStyles } from "react-jss";
import { rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
var useStyles = createUseStyles({
    socialIcon: {
        display: "block",
        marginRight: rem(spacings.s),
        filter: "brightness(0%)",
        transition: "filter 0.3s ease",
        "&:hover": {
            filter: "brightness(100%)",
            transition: "filter 0.3s ease",
        },
    },
    socialIconImage: {
        height: 40,
        width: 40,
        pointerEvents: "none",
    },
});
export function FooterIconBlock(props) {
    var _a, _b;
    var styles = useStyles();
    var link = (_a = props.data.link.value) === null || _a === void 0 ? void 0 : _a[0];
    return (React.createElement(Link, { href: (link === null || link === void 0 ? void 0 : link.href) || "", className: styles.socialIcon, title: link === null || link === void 0 ? void 0 : link.title, target: link === null || link === void 0 ? void 0 : link.target },
        React.createElement("img", { src: ((_b = props.data.image.value) === null || _b === void 0 ? void 0 : _b.url) || "", className: styles.socialIconImage, title: props.data.label.value || "", alt: props.data.label.value || "", loading: "lazy" })));
}
