var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext } from "react";
export function InitLocalizationContext(localizationData) {
    return {
        get: function (section, label) {
            var _a;
            return ((localizationData &&
                ((_a = localizationData.HdiGlobal.Cms.Localization.Translations[section]) === null || _a === void 0 ? void 0 : _a[label])) ||
                undefined);
        },
    };
}
export var LocalizationContext = React.createContext(null);
export function localizedLabelString(props) {
    if (props.localizationCtx === null)
        return "Localization data missing";
    return (props.localizationCtx.get(props.section, props.label) ||
        "$".concat(props.section, ".$").concat(props.label));
}
export function LocalizedLabel(props) {
    var localizationCtx = useContext(LocalizationContext);
    var localizedLabel = localizedLabelString(__assign(__assign({}, props), { localizationCtx: localizationCtx }));
    return React.createElement(React.Fragment, null, localizedLabel);
}
