var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { createUseStyles } from "react-jss";
import { colors } from "@basics/colors";
import { Col, Container, onBreakpoint, rem, Row } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { customTypography, typography } from "@basics/typography";
import { LocalizedLabel } from "@hooks/LocalizationContext";
import { useTracking } from "@hooks/useTracking";
import { AnimatedBlockRenderProperty, AnimatedRenderProperty, RenderProperty, } from "@/src/views/RenderProperty";
import { AnimatedRevealBlock, AnimatedRevealInline, } from "@components/AnimatedReveal/AnimatedReveal";
import { BreadcrumbComponent } from "@components/BreadcrumbComponent/BreadcrumbComponent";
import { Button, ButtonWrapper } from "@components/Button/Button";
import { FAIcon, FAIconStyles } from "@components/FAIcon/FAIcon";
var useStyles = createUseStyles({
    keyVisual: {
        margin: "".concat(spacings.m, " 0 ").concat(spacings.xxl),
    },
    title: typography.h1,
    description: __assign({}, customTypography(__assign(__assign({}, typography.textDefault), { marginBottom: 0 }), {}, { marginBottom: 0 })),
    imageWrapper: {
        position: "relative",
        boxSizing: "content-box",
        "&:nth-child(2)": __assign({ width: "26%", marginTop: "-26%", top: "-60%", right: -16, border: "16px solid white", borderRightWidth: 0, marginLeft: "auto" }, onBreakpoint("sm", {
            marginTop: "-48%",
            top: 0,
            width: "79%",
            borderWidth: 24,
            left: "calc(-20% - 27px)",
            marginLeft: "unset",
        })),
        "&:nth-child(3)": __assign(__assign({ display: "none", width: "calc(40% + 16px)", right: -32, marginTop: "calc(-35% - 1vw)", marginLeft: "auto" }, onBreakpoint("sm", {
            display: "block",
        })), onBreakpoint("lg", {
            marginTop: "-35%",
        })),
    },
    textColumn: __assign({ order: 1 }, onBreakpoint("sm", {
        order: 0,
    })),
    highlights: {
        marginTop: rem(spacings.sam),
    },
    highlightItem: __assign({}, customTypography(__assign(__assign({}, typography.textDefault), { color: colors.gray60, width: "100%", display: "flex", alignItems: "center", marginBottom: rem(spacings.xs) }), {}, {
        marginBottom: rem(spacings.xs),
    })),
    highlightIcon: __assign(__assign({}, FAIconStyles({
        width: 32,
        height: 32,
    })), { marginRight: rem(spacings.s), color: colors.lightGreen40 }),
    buttons: {
        display: "inline-block",
        marginTop: rem(spacings.m),
        "&:not(:last-child)": {
            marginRight: rem(spacings.m),
        },
    },
    contactUsButton: {
        marginRight: rem(spacings.m),
        marginTop: rem(spacings.xl),
    },
    mobileAndTabletOnly: __assign({ display: "block" }, onBreakpoint("md", {
        display: "none",
    })),
    desktopOnly: __assign({ display: "none" }, onBreakpoint("md", {
        display: "flex",
    })),
});
export function KeyVisual(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var styles = useStyles();
    var tracking = useTracking();
    var checkIfValidImage = function (adaptiveimage) {
        var _a, _b, _c;
        return ((_a = adaptiveimage === null || adaptiveimage === void 0 ? void 0 : adaptiveimage.value) === null || _a === void 0 ? void 0 : _a.urlLarge) ||
            ((_b = adaptiveimage === null || adaptiveimage === void 0 ? void 0 : adaptiveimage.value) === null || _b === void 0 ? void 0 : _b.urlMedium) ||
            ((_c = adaptiveimage === null || adaptiveimage === void 0 ? void 0 : adaptiveimage.value) === null || _c === void 0 ? void 0 : _c.urlSmall);
    };
    var images = __spreadArray(__spreadArray(__spreadArray([], (checkIfValidImage(props.primaryImage) ? [props.primaryImage] : []), true), (checkIfValidImage(props.secondaryImage) ? [props.secondaryImage] : []), true), (checkIfValidImage(props.tertiaryImage) ? [props.tertiaryImage] : []), true);
    var primaryButton = (_b = (_a = props.buttons) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.find(function (b) { return b.buttonType === "primary"; });
    useEffect(function () {
        var _a, _b, _c;
        (!primaryButton && props.isUserRetention) ||
            (((_a = props.buttons) === null || _a === void 0 ? void 0 : _a.value) && ((_c = (_b = props.buttons) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.length) > 0)
            ? tracking.event("Key Visual", "CTA show", "Contact us")
            : undefined;
    }, [window._paq]);
    var headerPortalElement = (_c = props.headerButtonPortalRef) === null || _c === void 0 ? void 0 : _c.current;
    var HeaderPortalWrapper = headerPortalElement
        ? function (props) {
            return createPortal(React.createElement(React.Fragment, null, props.children), headerPortalElement);
        }
        : function () { return null; };
    return (React.createElement(Container, null,
        React.createElement("div", { className: styles.keyVisual, "data-testid": "KeyVisual" },
            React.createElement(Container, { isFluid: true },
                React.createElement(Row, null,
                    React.createElement(Col, { className: styles.mobileAndTabletOnly },
                        React.createElement(BreadcrumbComponent, null)),
                    React.createElement(Col, { sm: 6, className: styles.textColumn },
                        React.createElement(BreadcrumbComponent, { className: styles.desktopOnly }),
                        React.createElement("h1", { className: styles.title },
                            React.createElement(AnimatedRenderProperty, { value: props.headline })),
                        React.createElement(Row, null,
                            React.createElement(Col, { sm: 10 },
                                props.description && (React.createElement(AnimatedBlockRenderProperty, { value: props.description, className: styles.description, revealDelay: 100, movement: false })),
                                (((_e = (_d = props.highlights) === null || _d === void 0 ? void 0 : _d.value) === null || _e === void 0 ? void 0 : _e.length) || 0) > 0 && (React.createElement(AnimatedRevealBlock, { revealDelay: 200, movement: false },
                                    React.createElement("div", { className: styles.highlights }, (_g = (_f = props.highlights) === null || _f === void 0 ? void 0 : _f.value) === null || _g === void 0 ? void 0 : _g.map(function (item, idx) { return (React.createElement("div", { key: idx, className: styles.highlightItem },
                                        React.createElement(FAIcon, { icon: (item.icon || undefined), className: styles.highlightIcon }),
                                        item.text)); })))),
                                React.createElement("div", { ref: props.keyVisualRef },
                                    !primaryButton && props.isUserRetention && (React.createElement(AnimatedRevealInline, { revealDelay: 300, movement: false },
                                        React.createElement(Button, { onClick: function () {
                                                var _a;
                                                tracking.event("Key Visual", "CTA click", "Contact us");
                                                (_a = props.contactListRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
                                                    behavior: "smooth",
                                                });
                                            }, className: styles.contactUsButton },
                                            React.createElement(LocalizedLabel, { section: "Contact", label: "ContactUs" })))), (_j = (_h = props.buttons) === null || _h === void 0 ? void 0 : _h.value) === null || _j === void 0 ? void 0 :
                                    _j.map(function (button, idx) { return (React.createElement(AnimatedRevealBlock, { key: idx, revealDelay: 300 + 50 * idx, className: styles.buttons },
                                        React.createElement(ButtonWrapper, __assign({}, button, { trackingFunction: function () {
                                                var _a, _b;
                                                return tracking.event("Key Visual", "CTA click", ((_b = (_a = button.link) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.text) || "Missing button text");
                                            } })))); }))))),
                    React.createElement(Col, { smStart: 7, sm: 5 },
                        React.createElement(AnimatedRevealBlock, { movement: false, className: styles.imageWrapper, revealDelay: 300 }, images.map(function (image, idx) { return (React.createElement("div", { className: styles.imageWrapper, key: idx },
                            React.createElement(RenderProperty, { value: image }))); })))))),
        React.createElement(HeaderPortalWrapper, null,
            !primaryButton && props.isUserRetention && (React.createElement(Button, { onClick: function () {
                    var _a;
                    tracking.event("Key Visual", "CTA click", "Contact us");
                    (_a = props.contactListRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
                        behavior: "smooth",
                    });
                } },
                React.createElement(LocalizedLabel, { section: "Contact", label: "ContactUs" }))),
            primaryButton ? (React.createElement(ButtonWrapper, __assign({}, primaryButton, { trackingFunction: function () {
                    var _a, _b;
                    return tracking.event("Key Visual", "CTA click", ((_b = (_a = primaryButton.link) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.text) || "Missing button text");
                } }))) : null)));
}
