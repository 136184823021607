var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import { isDeviceResOrLower, useDeviceType } from "@hooks/useDeviceType";
import { ModalWithCarousel } from "@components/ModalWithCarousel/ModalWithCarousel";
import { VideoClickableArea } from "./VideoClickableArea";
import { CloudinaryVideoPlayerBasic } from "./CloudinaryVideoPlayerBasic";
export var CloudinaryVideoPlayer = function (props) {
    var forcePlay = props.forcePlay, setForcePlay = props.setForcePlay, withOverlay = props.withOverlay;
    var deviceType = useDeviceType();
    var isMobile = isDeviceResOrLower(deviceType, "mobile");
    var _a = useState(false), modalOpened = _a[0], setModalOpened = _a[1];
    var _b = useState(false), isOverlayFocused = _b[0], setOverlayFocused = _b[1];
    var _c = useState(false), isOverlayHovered = _c[0], setOverlayHovered = _c[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(CloudinaryVideoPlayerBasic, __assign({}, props, { isOverlayFocused: isOverlayFocused, isOverlayHovered: isOverlayHovered, modalOpened: modalOpened, setModalOpened: setModalOpened }), withOverlay && (React.createElement(VideoClickableArea, { onClick: function () { return setModalOpened(true); }, onMouseOver: function () { return setOverlayHovered(true); }, onMouseOut: function () { return setOverlayHovered(false); }, onFocus: function () { return setOverlayFocused(true); }, onBlur: function () { return setOverlayFocused(false); } }))),
        withOverlay && !isMobile && (React.createElement(ModalWithCarousel, { video: __assign(__assign({}, props), { withOverlay: false, showControls: true, autoplay: true, forcePlay: undefined, setForcePlay: undefined }), modalOpened: forcePlay || modalOpened, closeModal: function () {
                setModalOpened(false);
                setForcePlay && setForcePlay(false);
            } }))));
};
