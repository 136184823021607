var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cn from "classnames";
import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import { onBefore } from "@basics/layout";
import { isDeviceResOrLower, useDeviceType } from "@hooks/useDeviceType";
import "cloudinary-video-player/dist/cld-video-player.light.min.css";
import "cloudinary-video-player/dist/cld-video-player.light.min.js";
import { useCloudinaryPlayer } from "@hooks/useCloudinaryPlayer";
var useStyles = createUseStyles({
    disableControlBar: {
        "& .cld-video-player .vjs-control-bar": {
            display: "none",
        },
        "& .cld-video-player-floater.cld-video-player-floating .vjs-big-play-button": {
            top: "50%",
        },
    },
    videoElement: {
        position: "relative",
        "& .video-js": {
            backgroundColor: "transparent",
            overflow: "hidden",
        },
        "& .vjs-fullscreen .vjs-poster": {
            width: "100vw",
            height: "calc(100vw / 16 * 9)",
            top: "calc((100vh - (100vw / 16 * 9)) / 2)",
        },
        "& .vjs-poster": {
            transform: function (_a) {
                var isOverlayFocused = _a.isOverlayFocused, isOverlayHovered = _a.isOverlayHovered;
                return isOverlayFocused || isOverlayHovered ? "scale(1.04)" : "scale(1)";
            },
            transition: "transform 0.3s ease",
        },
        "& .cld-video-player .vjs-big-play-button .vjs-icon-placeholder:before": {
            borderLeftWidth: "30px !important",
            borderTopWidth: "20px !important",
            borderBottomWidth: "20px !important",
            marginLeft: "-30px !important",
            marginTop: "-20px !important",
        },
    },
    inHeaderCarousel: {
        position: "relative",
        "& > div": __assign({ maxHeight: 750, paddingTop: "39% !important" }, onBefore("sm", {
            height: "300px !important",
            objectFit: "cover",
        })),
        "& video": {
            maxHeight: 750,
            objectFit: "cover",
        },
    },
});
export var CloudinaryVideoPlayerBasic = function (props) {
    var _a = props.showControls, showControls = _a === void 0 ? true : _a, children = props.children, isOverlayFocused = props.isOverlayFocused, isOverlayHovered = props.isOverlayHovered, forcePlay = props.forcePlay, setForcePlay = props.setForcePlay, modalOpened = props.modalOpened, setModalOpened = props.setModalOpened;
    var deviceType = useDeviceType();
    var isMobile = isDeviceResOrLower(deviceType, "mobile");
    var _b = useCloudinaryPlayer(props), videoPlayRef = _b.videoPlayRef, videoElementRef = _b.videoElementRef;
    var styles = useStyles({ isOverlayFocused: isOverlayFocused, isOverlayHovered: isOverlayHovered });
    var videoShouldBePlayedOnFullScreen = isMobile && (modalOpened || forcePlay);
    useEffect(function () {
        if (videoPlayRef.current && videoShouldBePlayedOnFullScreen) {
            videoPlayRef.current.play();
            videoPlayRef.current.maximize();
            videoPlayRef.current.on("fullscreenchange", function () {
                if (videoPlayRef.current && !videoPlayRef.current.isMaximized()) {
                    videoPlayRef.current.pause();
                    videoPlayRef.current.videojs.currentTime(0);
                    videoPlayRef.current.videojs.hasStarted(false);
                    videoPlayRef.current.videojs.triggerReady();
                    setModalOpened(false);
                    setForcePlay && setForcePlay(false);
                }
            });
        }
    }, [
        videoPlayRef,
        setForcePlay,
        setModalOpened,
        videoShouldBePlayedOnFullScreen,
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: cn(props.inHeaderCarousel
                ? styles.inHeaderCarousel
                : styles.videoElement, !showControls &&
                !videoShouldBePlayedOnFullScreen &&
                styles.disableControlBar) },
            React.createElement("video", { ref: videoElementRef }),
            children)));
};
