var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext, useEffect, useState } from "react";
import { Footer } from "@components/Shared/Footer";
import { AnimatedRenderProperty, RenderProperty, } from "@/src/views/RenderProperty";
import { ContactExpert } from "@components/ContactExpert/ContactExpert";
import { isCypressTest } from "@basics/isCypressTest";
import { StartPageHeader } from "@components/Header/StartPageHeader";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
import { DynamicDownloadBlock } from "@components/Block/DynamicDownloadBlock";
export function SpecialtyStartPage(props) {
    var _a, _b;
    var _c = useContext(GlobalContentStoreContext), getStartPageData = _c.getStartPageData, getImmediateStartPageData = _c.getImmediateStartPageData;
    var _d = useState(getImmediateStartPageData()), startPageData = _d[0], setStartPageData = _d[1];
    var isUserRetention = ((_a = props.data.contactCta) === null || _a === void 0 ? void 0 : _a.value) === "enabled";
    useEffect(function () {
        !startPageData &&
            getStartPageData().then(function (response) {
                setStartPageData(response);
            });
    }, []);
    if (!startPageData)
        return null;
    var contactExpertData = ((_b = props.data.contactsUseDefaultContent) === null || _b === void 0 ? void 0 : _b.value)
        ? startPageData
        : props.data;
    return (React.createElement(React.Fragment, null,
        !isCypressTest() && (React.createElement(StartPageHeader, __assign({}, props.data, { searchPage: startPageData === null || startPageData === void 0 ? void 0 : startPageData.searchPage, homepageSearch: startPageData === null || startPageData === void 0 ? void 0 : startPageData.homepageSearch, logo: startPageData === null || startPageData === void 0 ? void 0 : startPageData.logo, logoLink: startPageData === null || startPageData === void 0 ? void 0 : startPageData.logoLink, metaNavigationLeft: startPageData === null || startPageData === void 0 ? void 0 : startPageData.metaNavigationLeft, metaNavigationRight: startPageData === null || startPageData === void 0 ? void 0 : startPageData.metaNavigationRight, businessPortalLoginLink: startPageData === null || startPageData === void 0 ? void 0 : startPageData.businessPortalLoginLink, userLogin: startPageData === null || startPageData === void 0 ? void 0 : startPageData.userLogin }))),
        React.createElement("main", { "data-testid": "SpecialtyStartPage" },
            React.createElement(AnimatedRenderProperty, { value: props.data.quickLinks, theme: "homepage" }),
            React.createElement(RenderProperty, { value: props.data.mainContent, theme: "homepage" }),
            React.createElement(RenderProperty, { value: props.data.additionalContent }),
            React.createElement(DynamicDownloadBlock, { downloadCategoriesFilter: props.data.downloadCategoriesFilter, downloadsManual: props.data.downloadsManual }),
            isUserRetention && React.createElement(ContactExpert, __assign({}, contactExpertData))),
        React.createElement(Footer, __assign({}, props.data, { column1: startPageData === null || startPageData === void 0 ? void 0 : startPageData.column1, column2: startPageData === null || startPageData === void 0 ? void 0 : startPageData.column2, column3: startPageData === null || startPageData === void 0 ? void 0 : startPageData.column3, column4: startPageData === null || startPageData === void 0 ? void 0 : startPageData.column4, legalLinks: startPageData === null || startPageData === void 0 ? void 0 : startPageData.legalLinks, socialMedia: startPageData === null || startPageData === void 0 ? void 0 : startPageData.socialMedia, footerLanguagePicker: startPageData === null || startPageData === void 0 ? void 0 : startPageData.footerLanguagePicker }))));
}
