var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { onBreakpoint, rem } from "@basics/layout";
import { createUseStyles } from "react-jss";
import { spacings } from "@basics/spacings";
import { ButtonWrapper } from "@components/Button/Button";
import { RenderProperty } from "@/src/views/RenderProperty";
import { colors } from "@basics/colors";
import { FAIcon, FAIconStyles } from "@components/FAIcon/FAIcon";
import cn from "classnames";
import { customTypography, typography } from "@basics/typography";
var useStyles = createUseStyles({
    container: __assign({ backgroundColor: function (color) {
            switch (color) {
                case "yellow":
                    return colors.alertsYellow40;
                case "light_blue":
                    return colors.lightBlue40;
                case "dark_blue":
                    return colors.darkBlue70;
                case "dark_red":
                    return colors.darkRed70;
                case "green":
                    return colors.darkGreen70;
                default:
                    return colors.lightBlue40;
            }
        }, margin: "".concat(spacings.xs, " 0"), padding: rem(spacings.sam) }, onBreakpoint("sm", {
        margin: 0,
    })),
    headlineWrapper: __assign({ display: "flex", flexDirection: "row" }, onBreakpoint("sm", {
        flexDirection: "column",
    })),
    headline: __assign({}, customTypography(__assign(__assign({}, typography.textLarge), { marginRight: rem(spacings.sam), color: "".concat(colors.white, " !important") }), {}, {
        marginRight: 0,
    })),
    icon: __assign({}, FAIconStyles(__assign({ width: 56, height: 56, color: colors.white, marginLeft: "auto", marginBottom: rem(spacings.sam), alignSelf: "center", order: 1 }, onBreakpoint("sm", {
        width: 80,
        height: 80,
        marginLeft: "unset",
        order: 0,
    })))),
    whiteLink: {
        color: colors.white,
        "& svg": {
            color: colors.white,
        },
    },
});
export function TextTeaserBlock(props) {
    var _a;
    var styles = useStyles(props.data.color.value);
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles.headlineWrapper },
            React.createElement(FAIcon, { icon: props.data.icon.value, className: styles.icon }),
            React.createElement(RenderProperty, { className: styles.headline, value: props.data.headline })), (_a = props.data.buttons.value) === null || _a === void 0 ? void 0 :
        _a.map(function (b, idx) { return (React.createElement(ButtonWrapper, __assign({}, b, { key: idx, className: cn(b.buttonType === "link" && styles.whiteLink) }))); })));
}
