var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cn from "classnames";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { colors } from "@basics/colors";
import { BREAKPOINTS, Col, Container, onBreakpoint, rem, Row, } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { customTypography, typography } from "@basics/typography";
import { isDeviceResOrLower, useDeviceType } from "@hooks/useDeviceType";
import { RenderProperty } from "@/src/views/RenderProperty";
import { AdaptiveImageComponent } from "@components/AdaptiveImageComponent/AdaptiveImageComponent";
import { FAIcon, FAIconStyles } from "@components/FAIcon/FAIcon";
import { ModalWithCarousel } from "@components/ModalWithCarousel/ModalWithCarousel";
import { SemanticHeader } from "@components/SemanticHeader/SematicHeader";
import { useSlider } from "@components/Slider/Slider";
import { RichTextWithTypographyRenderer } from "./RichTextBlock/RichTextBlock";
var imageStyles = createUseStyles({
    imageGallery: __assign(__assign({ marginBottom: rem(spacings.m) }, onBreakpoint("sm", {
        display: "block",
        marginBottom: rem(spacings.l),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xxl),
    })),
    textContainer: {
        marginBottom: rem(spacings.s),
    },
    headline: __assign(__assign({}, typography.h2), { textAlign: "center" }),
    description: customTypography(__assign(__assign({}, typography.textDefault), { textAlign: "center" }), { marginBottom: rem(spacings.s) }, { marginBottom: rem(spacings.sam) }, { marginBottom: rem(spacings.m) }),
    image: __assign(__assign({ position: "relative", cursor: "pointer", overflow: "hidden", background: "none", border: 0, padding: 0, width: "100%" }, onBreakpoint("sm", {
        "&:after": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: 0,
            transition: "opacity 0.3s ease",
            backgroundColor: colors.darkGreen100,
        },
        "&:hover, &:focus": {
            "&:after": {
                opacity: 0.2,
                transition: "opacity 0.3s ease",
            },
            "& $zoomWatermark": {
                opacity: 1,
                transform: "translate(-50%, -50%)",
            },
            "& $imageInner": {
                transform: "scale(1.04)",
            },
        },
    })), { "& img": {
            width: "100%",
            display: "block",
        } }),
    imageMobile: {
        height: "250px",
        "& img": {
            objectFit: "contain",
        },
    },
    imageInner: {
        width: "100%",
        height: "100%",
        transform: "scale(1)",
        transition: "transform 0.3s ease",
    },
    imageCaption: {
        marginTop: rem(spacings.xxs),
        "& p": __assign(__assign({}, typography.textXSmall), { color: colors.gray60 }),
    },
    zoomWatermark: __assign({}, FAIconStyles({
        position: "absolute",
        color: colors.gray10,
        opacity: 0,
        width: 96,
        height: 96,
        transition: "transform 0.3s ease, opacity 0.3s ease",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -25%)",
        zIndex: 1,
    })),
    galleryPreviewMobile: {
        "& .slick-prev, & .slick-next": {
            zIndex: 1,
            background: "rgba(0, 0, 0, 0.3)",
            padding: 8,
            borderRadius: 3,
            width: "auto",
            height: "auto",
            "&:before": {
                fontFamily: "Font Awesome\\ 5 Pro",
            },
        },
        "& .slick-prev": {
            left: 10,
            paddingRight: 10,
            "&:before": {
                content: '"\\f053"',
            },
        },
        "& .slick-next": {
            right: 10,
            paddingLeft: 10,
            "&:before": {
                content: '"\\f054"',
            },
        },
        "& .slick-slide": {
            opacity: 0,
            transition: "opacity 0.3s ease",
        },
        "& .slick-current": {
            opacity: 1,
        },
    },
    grayBg: __assign(__assign({ backgroundColor: function (isGrayBg) { return isGrayBg && colors.gray10; }, padding: "".concat(spacings.m, " ").concat(spacings.sam, " ").concat(spacings.m) }, onBreakpoint("sm", {
        padding: "".concat(spacings.l, " ").concat(spacings.m, " ").concat(spacings.l),
    })), onBreakpoint("md", {
        padding: "".concat(spacings.xxl, " ").concat(spacings.l, " ").concat(spacings.xxl),
    })),
    bgRow: {
        maxWidth: BREAKPOINTS["xl"],
        margin: "0 auto",
    },
});
export function ImageGalleryBlock(props) {
    var _a, _b, _c, _d, _e;
    var _f = props.data, images = _f.images, title = _f.title, description = _f.description;
    var _g = useState(false), modalOpened = _g[0], setModalOpened = _g[1];
    var closeModal = function () {
        setModalOpened(false);
    };
    var imageItems = (_a = images === null || images === void 0 ? void 0 : images.value) === null || _a === void 0 ? void 0 : _a.map(function (img) { return ({
        image: img.image,
        description: img.imageCaption,
    }); });
    var _h = useState((_b = images === null || images === void 0 ? void 0 : images.value) === null || _b === void 0 ? void 0 : _b[0]), currentPreviewImage = _h[0], setCurrentPreviewImage = _h[1];
    var deviceType = useDeviceType();
    var isMobile = isDeviceResOrLower(deviceType, "mobile");
    var carouselSettings = {
        dots: !isMobile,
        arrows: isMobile,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: function (currentSlide) {
            if (images.value && images.value[currentSlide]) {
                setCurrentPreviewImage(images.value[currentSlide]);
            }
        },
    };
    var isGrayBg = ((_c = props.data.backgroundColor) === null || _c === void 0 ? void 0 : _c.value) === "gray";
    var styles = imageStyles(isGrayBg);
    var Slider = useSlider();
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, { isFluid: isGrayBg, className: cn(styles.imageGallery, isGrayBg ? styles.grayBg : undefined) },
            ((title === null || title === void 0 ? void 0 : title.value) || (description === null || description === void 0 ? void 0 : description.value)) && (React.createElement(Row, { className: isGrayBg ? styles.bgRow : undefined },
                React.createElement(Col, { sm: 10, smStart: 1, md: 8, mdStart: 2, className: styles.textContainer },
                    React.createElement(SemanticHeader, { className: styles.headline, headerSize: (_d = props.data.headerSize) === null || _d === void 0 ? void 0 : _d.value },
                        React.createElement(RenderProperty, { value: title })),
                    React.createElement("div", { className: styles.description },
                        React.createElement(RenderProperty, { value: description }))))),
            React.createElement(Row, null,
                React.createElement(Col, { sm: 8, smStart: 2, md: 6, mdStart: 3 },
                    isMobile ? (React.createElement(React.Fragment, null, Slider && (React.createElement(Slider, __assign({}, carouselSettings, { className: styles.galleryPreviewMobile }), (_e = images.value) === null || _e === void 0 ? void 0 : _e.map(function (item, idx) { return (React.createElement("div", { className: cn(styles.image, styles.imageMobile), key: idx },
                        React.createElement(AdaptiveImageComponent, { className: styles.imageInner, large: item.image.urlLarge, medium: item.image.urlMedium, small: item.image.urlSmall, alt: item.image.alternateText }))); }))))) : (React.createElement("button", { className: styles.image, onClick: function () { return setModalOpened(true); } },
                        currentPreviewImage && (React.createElement(AdaptiveImageComponent, { className: styles.imageInner, large: currentPreviewImage.image.urlLarge, medium: currentPreviewImage.image.urlMedium, small: currentPreviewImage.image.urlSmall, alt: currentPreviewImage.image.alternateText })),
                        React.createElement(FAIcon, { icon: "images", className: styles.zoomWatermark }))),
                    React.createElement(RichTextWithTypographyRenderer, { value: (currentPreviewImage === null || currentPreviewImage === void 0 ? void 0 : currentPreviewImage.imageCaption) || "", className: styles.imageCaption })))),
        !isMobile && (React.createElement(ModalWithCarousel, { images: imageItems, modalOpened: modalOpened, closeModal: closeModal }))));
}
