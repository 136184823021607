import React from "react";
export function SemanticHeader(props) {
    switch (props.headerSize) {
        case "h2":
            return React.createElement("h2", { className: props.className }, props.children);
        case "h3":
            return React.createElement("h3", { className: props.className }, props.children);
        case "h4":
            return React.createElement("h4", { className: props.className }, props.children);
        case "h5":
            return React.createElement("h5", { className: props.className }, props.children);
        default:
            return React.createElement("h2", { className: props.className }, props.children);
    }
}
