import "intersection-observer";
import React from "react";
import ReactDOM from "react-dom";
import { App, SSRApp } from "./App";
import { isClientSide } from "./basics/isClientSide";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import "./wdyr";
global.SSRApp = SSRApp;
if (isClientSide()) {
    var renderingFunction = window.__SSR__ ? ReactDOM.hydrate : ReactDOM.render;
    renderingFunction(React.createElement(React.StrictMode, null,
        React.createElement(App, null)), document.getElementById("root"), function () {
        var _a;
        var ssrStyles = document.getElementById("ssr-jss-styles");
        if (ssrStyles)
            (_a = ssrStyles.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(ssrStyles);
    });
    serviceWorker.unregister();
}
