var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { brandColors } from "@basics/brand-colors";
import { fonts } from "@basics/fonts";
import { onBreakpoint, onBefore, rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { typography, customTypography } from "@basics/typography";
import { createUseStyles } from "react-jss";
export var useStyles = createUseStyles({
    visualHeaderCarousel: {
        position: "relative",
        maxWidth: 1920,
        margin: "0 auto ".concat(spacings.xl.times(2)),
        "&:after": __assign(__assign({ content: "''", display: function (_a) {
                var menuCollapsed = _a.menuCollapsed;
                return (menuCollapsed ? "none" : "block");
            }, position: "absolute", top: 0, right: 0, width: 500, height: 200, background: "linear-gradient(216deg, rgba(0,0,0,0.65) 0%, transparent 35%)" }, onBreakpoint("sm", {
            width: 700,
            height: 270,
            background: "linear-gradient(200deg, rgba(0,0,0,0.65) 0%, transparent 40%)",
        })), onBreakpoint("md", {
            width: 900,
            height: 400,
            background: "linear-gradient(205eg, rgba(0,0,0,0.65) 0%, transparent 50%)",
        })),
        "& .slick-arrow": {
            width: 21,
            height: 42,
            top: function (_a) {
                var imageHeight = _a.imageHeight;
                return "".concat(imageHeight + 50, "px");
            },
            zIndex: 10,
            "&:before": {
                fontFamily: "'Font Awesome 5 Pro'",
                color: brandColors.grey,
                fontSize: "2.6rem",
            },
            "&.slick-prev": {
                left: "unset",
                right: 85,
                "@media (min-width: 1800px)": {
                    right: 115,
                },
                "&:before": {
                    content: "'\\f053'",
                },
            },
            "&.slick-next": {
                left: "unset",
                right: 30,
                "@media (min-width: 1800px)": {
                    right: 60,
                },
                "&:before": {
                    content: "'\\f054'",
                },
            },
        },
        "& .slick-dots": {
            bottom: -55,
            "& li": {
                margin: "0 15px",
                "&.slick-active button": {
                    border: "1px solid ".concat(brandColors.universalGreen),
                    borderRadius: "50%",
                    "&:before": {
                        opacity: 1,
                    },
                },
                "& button": {
                    padding: 0,
                    "&:before": {
                        width: 14,
                        fontSize: 14,
                        color: brandColors.universalGreen,
                        top: 1,
                        left: 3,
                        opacity: 0.4,
                    },
                },
            },
        },
    },
    carouselImage: {
        "& img": __assign({}, onBefore("sm", {
            height: 300,
            objectFit: "cover",
        })),
    },
    carouselVideoWrapper: __assign({}, onBefore("sm", {
        display: "none",
    })),
    carouselSVGWrapper: __assign({ position: "relative", paddingTop: "39%" }, onBefore("sm", {
        display: "none",
    })),
    carouselSVG: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        maxHeight: "750px",
        objectFit: "cover",
    },
    carouselStaticMobileImage: __assign({ "& img": {
            height: 300,
            objectFit: "cover",
        } }, onBreakpoint("sm", {
        display: "none",
    })),
    carouselCardContainer: __assign({}, onBefore("sm", {
        padding: 0,
    })),
    carouselCard: __assign(__assign({ position: "relative", zIndex: 20, backgroundColor: brandColors.ultraLightGrey, marginTop: 0, padding: "".concat(spacings.m, " ").concat(spacings.m, " ").concat(spacings.xl) }, onBreakpoint("sm", {
        marginTop: -120,
    })), onBreakpoint("md", {
        marginTop: -200,
        "&.center": {
            textAlign: "center",
        },
    })),
    carouselCardHeadline: {
        extend: typography.h3,
    },
    carouselCardDescription: __assign({}, customTypography(__assign({ 
        // Styles are extended with font in order to follow ODS
        extend: typography.textDefault }, fonts.book), {}, {
        marginBottom: rem(spacings.m),
    })),
    rightContentColumn: {
        "@media (min-width: 1440px)": {
            gridColumnStart: 6,
        },
        "@media (min-width: 1700px)": {
            gridColumnStart: 7,
        },
    },
});
