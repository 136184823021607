var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { brandColors } from "@basics/brand-colors";
import { fonts } from "@basics/fonts";
import { rem, onBreakpoint, GUTTER_WIDTHS, BREAKPOINTS } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { typography, customTypography } from "@basics/typography";
import { createUseStyles } from "react-jss";
export var richTextBlock = {
    "& h1": {
        extend: typography.h1,
    },
    "& h2": {
        extend: typography.h2,
    },
    "& h3": {
        extend: typography.h3,
    },
    "& h4": {
        extend: typography.h4,
    },
    "& h5": {
        extend: typography.h5,
    },
    "& h6": {
        extend: typography.h6,
    },
    "& p": __assign(__assign({ 
        // Styles are extended with font in order to follow ODS
        extend: typography.textDefault }, fonts.book), { "&.large": __assign({ extend: typography.textLarge }, fonts.book), "&.caption": __assign({ 
            // Styles are extended with font in order to follow ODS
            extend: typography.caption }, fonts.book) }),
    "& ul": {
        // Styles are extended with font in order to follow ODS
        extend: typography.uList,
        "& li": __assign({}, fonts.book),
    },
    "& ol": {
        // Styles are extended with font in order to follow ODS
        extend: typography.oList,
        "& li": __assign({}, fonts.book),
    },
    "& > *:last-child": {
        marginBottom: 0,
    },
};
export var useRichTextStyles = createUseStyles({
    richTextContainer: __assign(__assign({ display: "flex", flexDirection: "column", marginBottom: rem(spacings.m) }, onBreakpoint("sm", {
        display: "block",
        marginBottom: rem(spacings.l),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xxl),
    })),
    richTextBlock: richTextBlock,
    headline: {
        extend: typography.h2,
        textAlign: "center",
    },
    richTextWrapper: __assign({ marginBottom: rem(spacings.s), position: "relative" }, onBreakpoint("sm", {
        // marginTop: 7, //INFO(mkarol): Fixing image-text line aligmnet
        marginBottom: rem(spacings.sam),
        width: "50%",
    })),
    richTextImage: __assign({ height: "calc(100vw / 4 * 3)", position: "relative", display: "block", "& img": {
            position: "absolute",
            top: 0,
            left: -GUTTER_WIDTHS["small"],
            width: "100vw",
        } }, onBreakpoint("sm", {
        height: "auto",
        "& img": {
            position: "initial",
            width: "100%",
        },
    })),
    imageLeft: __assign(__assign({}, onBreakpoint("sm", {
        float: "left",
        paddingRight: GUTTER_WIDTHS["small"],
    })), onBreakpoint("md", {
        paddingRight: GUTTER_WIDTHS["big"],
    })),
    imageRight: __assign(__assign({}, onBreakpoint("sm", {
        float: "right",
        paddingLeft: GUTTER_WIDTHS["small"],
    })), onBreakpoint("md", {
        paddingLeft: GUTTER_WIDTHS["big"],
    })),
    imageCaption: __assign({}, customTypography(__assign(__assign({}, typography.textXSmall), { color: brandColors.grey, marginTop: rem(spacings.xxs), textAlign: "right" }), {}, {
        marginBottom: 0,
    })),
    grayBg: __assign(__assign({ backgroundColor: function (_a) {
            var isGrayBg = _a.isGrayBg;
            return isGrayBg && brandColors.ultraLightGrey;
        }, padding: "".concat(spacings.m, " ").concat(spacings.sam, " ").concat(spacings.m) }, onBreakpoint("sm", {
        padding: "".concat(spacings.l, " ").concat(spacings.m, " ").concat(spacings.l),
    })), onBreakpoint("md", {
        padding: "".concat(spacings.xxl, " ").concat(spacings.l, " ").concat(spacings.xxl),
    })),
    bgRow: {
        maxWidth: BREAKPOINTS["xl"],
        margin: "0 auto",
    },
});
