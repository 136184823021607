import * as d3 from "d3-selection";
import React, { useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { Marker } from "react-simple-maps";
import { LocationIcon } from "./LocationIcon";
import { getOffices, isMarkerClickable } from "./regionsExtentions";
var useStyles = createUseStyles({
    clickable: {
        cursor: "pointer",
    },
    notClickable: {
        pointerEvents: "none",
        cursor: "default",
    },
});
export var LocationMarker = function (_a) {
    var office = _a.office, position = _a.position, selectedRegion = _a.selectedRegion, setCurrentOffice = _a.setCurrentOffice;
    var _b = useStyles(), clickable = _b.clickable, notClickable = _b.notClickable;
    var ref = useRef(null);
    var _c = useState(false), markerHover = _c[0], setMarkerHover = _c[1];
    var ICON_SIZE = 25;
    var ICON_SIZE_FACTOR = position.zoom <= 1 ? 3 : 1;
    var markerSize = ICON_SIZE / position.zoom / ICON_SIZE_FACTOR;
    var isClickable = isMarkerClickable(selectedRegion);
    var markerStyles = isClickable ? clickable : notClickable;
    var markerColor = office.color.value;
    var handleMarkerClick = function () {
        if (isClickable) {
            setCurrentOffice(office);
        }
    };
    if (!office.lon.value || !office.lat.value) {
        return null;
    }
    var handleMouseOver = function () {
        d3.select(ref.current).raise();
    };
    return (React.createElement(Marker, { className: markerStyles, coordinates: [office.lon.value, office.lat.value], key: JSON.stringify(office), onClick: handleMarkerClick, onMouseEnter: function () {
            handleMouseOver();
            setMarkerHover(true);
        }, onMouseLeave: function () { return setMarkerHover(false); }, ref: ref },
        React.createElement(LocationIcon, { size: markerSize, color: markerColor, isHovered: markerHover, icon: office.icon.value, hasAnimation: isClickable })));
};
export var LocationMarkers = function (_a) {
    var position = _a.position, setCurrentOffice = _a.setCurrentOffice, selectedRegion = _a.selectedRegion, regions = _a.regions;
    var officesToShow = getOffices(regions).filter(function (office) { return office.showOnZoomOut.value; });
    var offices = selectedRegion ? getOffices([selectedRegion]) : officesToShow;
    var result = offices.map(function (office, index) {
        return (React.createElement(LocationMarker, { office: office, position: position, setCurrentOffice: setCurrentOffice, key: JSON.stringify(office) + index, selectedRegion: selectedRegion }));
    });
    return React.createElement(React.Fragment, null, result);
};
