export function isNonChromiumEdge() {
    return !( /*@cc_on!@*/false || !!document.documentMode) && !!window.StyleMedia; // prettier-ignore
}
export function isFirefox() {
    return navigator.userAgent.indexOf("Firefox") !== -1;
}
export function getFirefoxVersion() {
    var match = window.navigator.userAgent.match(/Firefox\/([0-9]+)\./);
    return match ? parseInt((match === null || match === void 0 ? void 0 : match[1]) || "") : 0;
}
