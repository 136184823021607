import { FAIcon } from "@components/FAIcon/FAIcon";
import React from "react";
import { useStyles } from "./styles";
export var Icon = function (_a) {
    var href = _a.href, iconName = _a.iconName, altText = _a.altText;
    var _b = useStyles(), accessibilityText = _b.accessibilityText, iconLink = _b.iconLink, icon = _b.icon;
    return (React.createElement("a", { href: href, className: iconLink },
        React.createElement("span", { className: accessibilityText },
            altText,
            " ",
            href),
        React.createElement(FAIcon, { icon: iconName, className: icon })));
};
