import React from "react";
import { createUseStyles } from "react-jss";
import { rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { LocalizedLabel } from "@hooks/LocalizationContext";
import { Button } from "@components/Button/Button";
var useStyles = createUseStyles({
    zoomoutButton: {
        marginBottom: rem(spacings.s),
        width: "fit-content",
    },
});
export var ZoomOutButton = function (_a) {
    var onClick = _a.onClick, disabled = _a.disabled;
    var zoomoutButton = useStyles().zoomoutButton;
    return (React.createElement(Button, { className: zoomoutButton, type: "secondary", onClick: onClick, icon: "search", disabled: disabled },
        React.createElement(LocalizedLabel, { section: "InteractiveMap", label: "ZoomOutButton" })));
};
