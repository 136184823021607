import React from "react";
import { createUseStyles } from "react-jss";
import { Checkbox } from "@components/Checkbox/Checkbox";
import { RadioButton } from "@components/RadioButton/RadioButton";
import { BreakFormLayout, getColumnStyle, useCommonFormStyles, } from "./common";
import cn from "classnames";
import { onBreakpoint } from "@basics/layout";
import { RenderProperty } from "@/src/views/RenderProperty";
var useStyles = createUseStyles({
    choice: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
    },
    horizontal: onBreakpoint("sm", {
        flexDirection: "row",
    }),
});
export function FormChoice(props) {
    var _a;
    var Element = props.data.allowMultiSelect.value ? Checkbox : RadioButton;
    var styles = useStyles();
    var commonFormStyles = useCommonFormStyles();
    var columnStyle = commonFormStyles[getColumnStyle(props.data.horizontalLayout.value)];
    var valueSet = new Set((_a = props.value) === null || _a === void 0 ? void 0 : _a.split(","));
    var onChangeMulti = function (value) {
        valueSet.has(value) ? valueSet.delete(value) : valueSet.add(value);
        props.onChange(Array.from(valueSet.values()).join(","));
    };
    var onChange = props.data.allowMultiSelect.value
        ? onChangeMulti
        : props.onChange;
    var isHorizontal = props.data.orientation.value === "horizontal";
    var newRow = props.data.newRow.value;
    var validationError = props.isValid === true ? null : props.isValid.join(" ");
    var options = props.data.options;
    return (React.createElement(React.Fragment, null,
        newRow && React.createElement(BreakFormLayout, null),
        React.createElement("div", { className: columnStyle },
            props.data.label.value && (React.createElement(RenderProperty, { value: props.data.label, className: commonFormStyles.label })),
            React.createElement("div", { className: cn(commonFormStyles.inputWrapper, styles.choice, isHorizontal && styles.horizontal) }, options.map(function (option) { return (React.createElement(Element, { key: option.value + option.caption, label: option.caption, checked: valueSet.has(option.value), onCheck: function () { return onChange(option.value); }, onBlur: props.setBlurred })); })),
            validationError && (React.createElement("div", { className: commonFormStyles.validationError }, validationError)))));
}
