var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { createUseStyles } from "react-jss";
import { RenderProperty } from "@/src/views/RenderProperty";
import { typography } from "@basics/typography";
import { Col, Container, onBreakpoint, rem, Row, GUTTER_WIDTHS, } from "@basics/layout";
import { colors } from "@basics/colors";
import { spacings } from "@basics/spacings";
import { Button } from "@components/Button/Button";
import cn from "classnames";
import { LocalizedLabel } from "@hooks/LocalizationContext";
import { AnimatedRevealBlock } from "@components/AnimatedReveal/AnimatedReveal";
var useStyles = createUseStyles({
    relatedContent: __assign(__assign({ marginBottom: rem(spacings.s) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.sam),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.m),
    })),
    headline: __assign(__assign({}, typography.h2), { textAlign: "center" }),
    relatedElement: __assign({ marginBottom: rem(spacings.s) }, onBreakpoint("sm", {
        height: "calc(100% - ".concat(spacings.m, ")"),
        marginBottom: rem(spacings.m),
    })),
    elementContent: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: colors.gray10,
        padding: "".concat(spacings.sam, " ").concat(spacings.sam, " ").concat(spacings.m),
        flexGrow: 1,
        alignSelf: "stretch",
        position: "relative",
    },
    singleElementContent: {
        "&:before": __assign(__assign({ position: "absolute", height: "100%", backgroundColor: colors.gray10, top: 0, left: 0 }, onBreakpoint("sm", {
            content: '""',
            left: -GUTTER_WIDTHS["small"],
            width: GUTTER_WIDTHS["small"],
        })), onBreakpoint("md", {
            left: -GUTTER_WIDTHS["big"],
            width: GUTTER_WIDTHS["big"],
        })),
    },
    elementContentLink: {
        marginTop: "auto",
        justifyContent: "flex-start",
    },
    category: __assign(__assign(__assign({}, typography.caption), { fontSize: "1.25rem", lineHeight: 1.6, marginBottom: rem(spacings.xs) }), onBreakpoint("sm", __assign(__assign({}, typography.caption), { fontSize: "1rem", lineHeight: 1.5, marginBottom: rem(spacings.xs) }))),
    title: typography.h4,
    relatedElementImageWrapper: {
        width: "100%",
        flexShrink: 0,
    },
    relatedElementImageLink: {
        width: "100%",
        display: "block",
        overflow: "hidden",
    },
    relatedElementImage: {
        transform: "none",
        transition: "transform 0.3s ease",
        "&:hover": {
            transform: "scale(1.03)",
            transition: "transform 0.3s ease",
        },
    },
    flexWrapper: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    fullWidth: {
        width: "100%",
    },
});
export function RelatedContentBlock(props) {
    var _a;
    var relatedContentElements = (_a = props.relatedContent) === null || _a === void 0 ? void 0 : _a.expandedValue;
    var styles = useStyles(relatedContentElements === null || relatedContentElements === void 0 ? void 0 : relatedContentElements.length);
    var isOneElement = (relatedContentElements === null || relatedContentElements === void 0 ? void 0 : relatedContentElements.length) === 1;
    var isTwoElements = (relatedContentElements === null || relatedContentElements === void 0 ? void 0 : relatedContentElements.length) === 2;
    var isFourElements = (relatedContentElements === null || relatedContentElements === void 0 ? void 0 : relatedContentElements.length) === 4;
    var columnsToSpread = isFourElements ? 3 : 4;
    return (React.createElement("div", { className: styles.relatedContent },
        React.createElement("h2", { className: styles.headline },
            React.createElement(LocalizedLabel, { section: "Global", label: "RelatedContent" })),
        React.createElement(AnimatedRevealBlock, { revealDelay: isOneElement ? 0 : -1 },
            React.createElement(Container, null,
                React.createElement(Row, null, isOneElement ? (React.createElement(React.Fragment, null, relatedContentElements === null || relatedContentElements === void 0 ? void 0 : relatedContentElements.map(function (item, idx) { return (React.createElement(React.Fragment, { key: idx },
                    React.createElement(Col, { sm: 6, md: 4, mdStart: 2, className: styles.relatedElementImageWrapper },
                        React.createElement("a", { href: item.url || undefined, className: styles.relatedElementImageLink },
                            React.createElement(RenderProperty, { value: item.metaImageAdaptive, className: styles.relatedElementImage }))),
                    React.createElement(Col, { sm: 6, md: 4 },
                        React.createElement("div", { className: cn(styles.singleElementContent, styles.elementContent) },
                            React.createElement(RenderProperty, { value: item.captionHeadline, className: styles.category }),
                            React.createElement("h4", { className: styles.title },
                                React.createElement(RenderProperty, { value: item.metaTitle, fallbackValue: item.name })),
                            React.createElement(Button, { href: item.url || undefined, type: "link", className: styles.elementContentLink },
                                React.createElement(LocalizedLabel, { section: "Global", label: "ReadMore" })))))); }))) : (React.createElement(React.Fragment, null, relatedContentElements === null || relatedContentElements === void 0 ? void 0 : relatedContentElements.map(function (item, idx) { return (React.createElement(Col, { sm: 4, smStart: idx % 2 === 0 && (isTwoElements || isFourElements)
                        ? 2
                        : null, md: columnsToSpread, mdStart: idx === 0 && isTwoElements ? 2 : null, style: { msGridRow: idx }, key: idx },
                    React.createElement(AnimatedRevealBlock, { revealDelay: idx * 200, className: styles.relatedElement },
                        React.createElement("div", { className: styles.flexWrapper },
                            React.createElement("a", { href: item.url || undefined, className: cn(styles.relatedElementImageWrapper, styles.relatedElementImageLink) },
                                React.createElement(RenderProperty, { value: item.metaImageAdaptive, className: styles.relatedElementImage })),
                            React.createElement("div", { className: styles.elementContent },
                                React.createElement(RenderProperty, { value: item.captionHeadline, className: styles.category }),
                                React.createElement("h4", { className: styles.title },
                                    React.createElement(RenderProperty, { value: item.metaTitle, fallbackValue: item.name })),
                                React.createElement(Button, { href: item.url || undefined, type: "link", className: styles.elementContentLink },
                                    React.createElement(LocalizedLabel, { section: "Global", label: "ReadMore" }))))))); }))))))));
}
