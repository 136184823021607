export function pseudoSelectorShadow(shadowProps) {
    return {
        content: "''",
        display: "block",
        position: "absolute",
        left: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        boxShadow: shadowProps,
    };
}
