var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var iconUnicodes = {
    cars: "f85b",
    ship: "f21a",
    "shield-check": "f2f7",
    "industry-alt": "f3b3",
    tools: "f7d9",
    "phone-laptop": "f87a",
    "money-check-edit": "f872",
    "chart-user": "f6a3",
    "briefcase-medical": "f469",
    umbrella: "f0e9",
    "hospital-user": "f80d",
    "street-view": "f21d",
    "pen-fancy": "f5ac",
    "file-user": "f65c",
    "lightbulb-on": "f672",
    globe: "f0ac",
    coins: "f51e",
    "chart-network": "f78a",
    link: "f0c1",
    "car-building": "f859",
    "plane-departure": "f5b0",
    vial: "f492",
    "wind-turbine": "f89b",
    database: "f1c0",
    pills: "f484",
    train: "f238",
    water: "f773",
    "hand-holding-seedling": "f4bf",
    forklift: "f47a",
    digging: "f85e",
    "user-friends": "f500",
    "house-flood": "f74f",
};
var topicsProductsDEV = {
    "topicCheckbox-1713521168523": iconUnicodes["industry-alt"],
    "topicCheckbox-1713521385716": iconUnicodes["shield-check"],
    "topicCheckbox-1713521466082": iconUnicodes.tools,
    "topicCheckbox-1713521573103": iconUnicodes.ship,
    "topicCheckbox-1713521670426": iconUnicodes["phone-laptop"],
    "topicCheckbox-1713521705206": iconUnicodes["money-check-edit"],
    "topicCheckbox-1713521415533": iconUnicodes["briefcase-medical"],
    "topicCheckbox-1713521366821": iconUnicodes["chart-user"],
    "topicCheckbox-1715167389206": iconUnicodes.umbrella,
    "topicCheckbox-1715167454229": iconUnicodes["street-view"],
    "topicCheckbox-1715167490834": iconUnicodes["hospital-user"],
};
var topicsProductsPROD = {
    "topicCheckbox-1715166816025": iconUnicodes["industry-alt"],
    "topicCheckbox-1715166883224": iconUnicodes["shield-check"],
    "topicCheckbox-1715167021396": iconUnicodes.tools,
    "topicCheckbox-1715167067797": iconUnicodes.ship,
    "topicCheckbox-1715167263015": iconUnicodes["phone-laptop"],
    "topicCheckbox-1715167294019": iconUnicodes["money-check-edit"],
    "topicCheckbox-1715167326095": iconUnicodes["briefcase-medical"],
    "topicCheckbox-1715167389206": iconUnicodes["chart-user"],
    "topicCheckbox-1713521534633": iconUnicodes.umbrella,
    "topicCheckbox-1713521588927": iconUnicodes["street-view"],
    "topicCheckbox-1713521624898": iconUnicodes["hospital-user"],
};
export var subscriptionItems = __assign(__assign({}, topicsProductsDEV), topicsProductsPROD);
var topicsInterestsDEV = [
    "topicCheckbox-1713525829724",
    "topicCheckbox-1713525949728",
    "topicCheckbox-1713526008915",
    "topicCheckbox-1713526097937",
    "topicCheckbox-1713525915870",
    "topicCheckbox-1713525981436",
    "topicCheckbox-1713528454372",
];
var topicsInterestsPROD = [
    "topicCheckbox-1715167718214",
    "topicCheckbox-1715167808089",
    "topicCheckbox-1715167838546",
    "topicCheckbox-1715167926235",
    "topicCheckbox-1715167969377",
    "topicCheckbox-1715167884091",
    "topicCheckbox-1715168007442",
];
export var interests = topicsInterestsDEV.concat(topicsInterestsPROD);
var newslettersDEV = [
    "topicCheckbox-1713526227416",
    "topicCheckbox-1713526275137",
    "topicCheckbox-1713528492875",
    "topicCheckbox-1713528527928",
];
var newslettersPROD = [
    "topicCheckbox-1715168202717",
    "topicCheckbox-1715168293316",
    "topicCheckbox-1715168328152",
    "topicCheckbox-1715168364189",
];
export var newsletters = newslettersDEV.concat(newslettersPROD);
