var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext } from "react";
import { createUseStyles } from "react-jss";
import { LocalizationContext, localizedLabelString, } from "@hooks/LocalizationContext";
import { RenderProperty } from "@/src/views/RenderProperty";
import { Select } from "@components/Select/Select";
import { BreakFormLayout, getColumnStyle, getFieldProps, useCommonFormStyles, } from "./common";
var useStyles = createUseStyles({
    select: function (_a) {
        var _b;
        var inputWrapper = _a.inputWrapper;
        return (_b = {},
            _b["& .".concat(inputWrapper, " > *")] = {
                height: "3.5rem",
            },
            _b["& .select-option"] = {
                fontSize: "1.25rem !important",
            },
            _b);
    },
});
export function FormSelect(props) {
    var commonFormStyles = useCommonFormStyles();
    var styles = useStyles({ inputWrapper: commonFormStyles.inputWrapper });
    var localizationCtx = useContext(LocalizationContext);
    var columnStyle = commonFormStyles[getColumnStyle(props.data.horizontalLayout.value)];
    var newRow = props.data.newRow.value;
    var validationError = props.isValid === true ? null : props.isValid.join(" ");
    return (React.createElement(React.Fragment, null,
        newRow && React.createElement(BreakFormLayout, null),
        React.createElement("div", { className: columnStyle },
            props.data.label.value && (React.createElement(RenderProperty, { value: props.data.label, className: commonFormStyles.label })),
            React.createElement(Select, __assign({}, getFieldProps(props.data), { className: styles.select, label: props.data.placeHolder.value ||
                    localizedLabelString({
                        localizationCtx: localizationCtx,
                        section: "Forms",
                        label: "SelectOption",
                    }), options: props.data.options.map(function (option) { return ({
                    value: option.value,
                    label: option.caption,
                }); }), selected: props.value || "", setSelected: props.onChange, onBlur: props.setBlurred })),
            validationError && (React.createElement("div", { className: commonFormStyles.validationError }, validationError)))));
}
