var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cn from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";
export var BREAKPOINTS = {
    xs: 480,
    sm: 768,
    md: 1025,
    lg: 1280,
    xl: 1440,
};
export var GUTTER_WIDTHS = {
    small: 16,
    big: 24,
};
export var CONTAINER_OFFSET_WIDTHS = {
    small: 16,
    big: 32,
};
export var MAX_LAYOUT_WIDTH = BREAKPOINTS["xl"] + 2 * CONTAINER_OFFSET_WIDTHS["big"];
export var MAX_SECTION_WIDTH = 1920;
export var MARGIN_OF_CONTAINER_LIMITED = "(min(100vw, ".concat(MAX_SECTION_WIDTH, "px) - min(100vw, ").concat(MAX_LAYOUT_WIDTH, "px))/2");
export var MARGIN_OF_CONTAINER = "(100vw - min(100vw, ".concat(MAX_LAYOUT_WIDTH, "px))/2");
export function getGridColumnWidth(breakpoint, isFluid) {
    if (breakpoint === "xs")
        return "(100vw - 2 * ".concat(CONTAINER_OFFSET_WIDTHS["small"], "px - 11 * ").concat(GUTTER_WIDTHS["small"], "px) / 4");
    if (breakpoint === "sm")
        return "(100vw - 2 * ".concat(CONTAINER_OFFSET_WIDTHS["big"], "px - 11 * ").concat(GUTTER_WIDTHS["small"], "px) / 12");
    var MAX_LAYOUT_WIDTH = BREAKPOINTS["xl"] + 2 * CONTAINER_OFFSET_WIDTHS["big"];
    var MARGIN_OF_CONTAINER = isFluid
        ? "0px"
        : "(100vw  - min(100vw, ".concat(MAX_LAYOUT_WIDTH, "px))");
    return "(100vw - ".concat(MARGIN_OF_CONTAINER, " - 2 * ").concat(CONTAINER_OFFSET_WIDTHS["big"], "px - 11 * ").concat(GUTTER_WIDTHS["big"], "px) / 12");
}
export function onBreakpoint(breakpoint, styles) {
    var _a;
    return _a = {},
        _a["@media (min-width: ".concat(BREAKPOINTS[breakpoint], "px)")] = styles,
        _a;
}
export function onBefore(breakpoint, styles) {
    var _a;
    return _a = {},
        _a["@media (max-width: ".concat(BREAKPOINTS[breakpoint] - 1, "px)")] = styles,
        _a;
}
export function rem(value) {
    return "".concat(value.valueOf(), "rem");
}
var col = function (size) {
    return ({
        position: "relative",
        gridColumnEnd: "span ".concat(size),
    });
};
var colStart = function (offset) { return ({
    gridColumnStart: offset,
}); };
var useStyles = createUseStyles(__assign(__assign({ container: __assign({ width: "100%", maxWidth: BREAKPOINTS["xl"] + 2 * CONTAINER_OFFSET_WIDTHS["big"], margin: "0 auto", padding: "0 ".concat(CONTAINER_OFFSET_WIDTHS["small"], "px") }, onBreakpoint("sm", { padding: "0 ".concat(CONTAINER_OFFSET_WIDTHS["big"], "px") })), containerFluid: {
        maxWidth: "100%",
        margin: "auto",
    }, containerNoPadding: {
        padding: 0,
        "& [class^='row']": {
            columnGap: 0,
        },
    }, row: __assign(__assign({ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", columnGap: GUTTER_WIDTHS["small"] }, onBreakpoint("sm", {
        gridTemplateColumns: "repeat(12, 1fr)",
    })), onBreakpoint("md", {
        columnGap: GUTTER_WIDTHS["big"],
    })) }, [1, 2, 3, 4]
    .map(function (size) {
    var _a;
    return (_a = {},
        _a["col-xs-".concat(size)] = __assign({}, col(size)),
        _a["start-xs-".concat(size - 1)] = __assign({}, colStart(size)),
        _a);
})
    .reduce(function (acc, value) { return (__assign(__assign({}, acc), value)); }, {})), ["sm", "md", "lg", "xl"]
    .map(function (breakpoint) {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        .map(function (size) {
        var _a;
        return (_a = {},
            _a["col-".concat(breakpoint, "-").concat(size)] = onBreakpoint(breakpoint, col(size)),
            _a["start-".concat(breakpoint, "-").concat(size - 1)] = onBreakpoint(breakpoint, colStart(size)),
            _a["start-".concat(breakpoint, "-clear")] = onBreakpoint(breakpoint, { gridColumnStart: "auto" }),
            _a);
    })
        .reduce(function (acc, value) { return (__assign(__assign({}, acc), value)); }, {});
})
    .reduce(function (acc, value) { return (__assign(__assign({}, acc), value)); }, {})));
export function Container(props) {
    var styles = useStyles();
    return (React.createElement("div", { className: cn(props.isFluid ? styles.containerFluid : styles.container, props.noPadding && styles.containerNoPadding, props.className), style: props.style, "data-testid": props.dataTestid }, props.children));
}
export function Row(props) {
    var styles = useStyles();
    return (React.createElement("div", { className: cn(styles.row, props.className), style: props.style, "data-testid": props.dataTestid }, props.children));
}
export function Col(props) {
    var styles = useStyles();
    var xsCol = props.xs || 4;
    var smCol = props.sm || (props.sm !== null && xsCol * 3);
    var mdCol = props.md || (props.md !== null && smCol);
    var lgCol = props.lg || (props.lg !== null && mdCol);
    var xlCol = props.xl || (props.xl !== null && lgCol);
    var xsStart = props.xsStart || 0;
    var smStart = props.smStart !== null ? props.smStart || xsStart * 3 : "clear";
    var mdStart = props.mdStart !== null ? props.mdStart || smStart : "clear";
    var lgStart = props.lgStart !== null ? props.lgStart || mdStart : "clear";
    var xlStart = props.xlStart !== null ? props.xlStart || lgStart : "clear";
    return (React.createElement("div", { className: cn(styles["col-xs-".concat(xsCol)], styles["col-sm-".concat(smCol)], styles["col-md-".concat(mdCol)], styles["col-lg-".concat(lgCol)], styles["col-xl-".concat(xlCol)], xsStart && styles["start-xs-".concat(xsStart)], smStart && styles["start-sm-".concat(smStart)], mdStart && styles["start-md-".concat(mdStart)], lgStart && styles["start-lg-".concat(lgStart)], xlStart && styles["start-xl-".concat(xlStart)], props.className), style: props.style, "data-testid": props.dataTestid }, props.children));
}
