export var animations = {
    linkChevronEffect: {
        "&:after": {
            content: "'→'",
            display: "inline-block",
            opacity: 0,
            transform: "translateX(-5px)",
            transition: "0.3s opacity ease, 0.3s transform ease",
        },
        "&:hover:after": {
            opacity: 1,
            transform: "translateX(5px)",
        },
    },
    simpleFadeIn: {
        "@keyframes simpleFadeIn": {
            "0%": {
                opacity: 0,
            },
            "100%": {
                opacity: 1,
            },
        },
    },
    simpleFadeOut: {
        "@keyframes simpleFadeOut": {
            "0%": {
                opacity: 1,
            },
            "100%": {
                opacity: 0,
            },
        },
    },
    grow: function (initialScale) { return ({
        "@keyframes grow": {
            "0%": {
                transform: "scale(".concat(initialScale, ")"),
            },
            "100%": {
                transform: "scale(1)",
            },
        },
    }); },
};
