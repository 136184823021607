var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cn from "classnames";
import React, { useContext, useState } from "react";
import { createUseStyles } from "react-jss";
import Slider from "react-slick";
import { Col, Container, onBefore, onBreakpoint, rem, Row, } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { typography } from "@basics/typography";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
import { isDeviceResOrHigher, isDeviceResOrLower, useDeviceType, } from "@hooks/useDeviceType";
import { AnimatedRenderProperty } from "@/src/views/RenderProperty";
import { AnimatedRevealBlock } from "@components/AnimatedReveal/AnimatedReveal";
import { ButtonWrapper } from "@components/Button/Button";
import { ContactListOverlay } from "@components/Block/ContactListBlock/ContactListOverlay";
import { ContactExpertCard } from "./ContactExpertCard";
import { brandColors, textColors } from "@basics/brand-colors";
var contactExpertStyles = createUseStyles({
    contactExperts: {
        textAlign: function (_a) {
            var isSingleElement = _a.isSingleElement;
            return (isSingleElement ? "left" : "center");
        },
        backgroundColor: function (_a) {
            var theme = _a.theme;
            return theme.background;
        },
        "& $title, & $tag, & $description, & $description p, $description p.caption, $description p.large, & $description li": {
            color: function (_a) {
                var theme = _a.theme;
                return theme.color;
            },
        },
        "& $description a": {
            color: function (_a) {
                var theme = _a.theme;
                return theme.color;
            },
            textDecoration: function (_a) {
                var theme = _a.theme;
                return theme.textDecoration;
            },
        },
        "&$isSingleElement": __assign({}, onBefore("sm", {
            textAlign: "center",
        })),
    },
    contactExpertsContainer: __assign(__assign(__assign({ position: "relative", marginBottom: rem(spacings.m), paddingTop: rem(spacings.m), paddingBottom: rem(spacings.m) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.l),
        paddingTop: rem(spacings.l),
        paddingBottom: rem(spacings.l),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xxl),
        paddingTop: rem(spacings.xxl),
        paddingBottom: rem(spacings.xxl),
    })), { backgroundColor: function (_a) {
            var theme = _a.theme;
            return theme;
        } }),
    contactList: __assign(__assign({ marginBottom: rem(spacings.s) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.sam),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xl),
    })),
    infoSection: __assign({ marginRight: function (_a) {
            var isSingleElement = _a.isSingleElement;
            return isSingleElement ? rem(spacings.s) : undefined;
        }, marginBottom: rem(spacings.m) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.s),
    })),
    title: typography.h2,
    tag: __assign(__assign({}, typography.caption), { marginBottom: rem(spacings.s) }),
    description: typography.textDefault,
    findMoreTop: {
        display: "none",
        color: function (_a) {
            var theme = _a.theme;
            return theme.color;
        },
        "&$isSingleElement": __assign({ display: "inline-flex" }, onBefore("sm", {
            display: "none",
        })),
        "& svg": {
            color: function (_a) {
                var theme = _a.theme;
                return theme.color;
            },
        },
    },
    findMoreBottom: {
        color: function (_a) {
            var theme = _a.theme;
            return theme.color;
        },
        "&$isSingleElement": __assign({ display: "none" }, onBefore("sm", {
            display: "inline-flex",
            marginTop: rem(spacings.l),
        })),
        "& svg": {
            color: function (_a) {
                var theme = _a.theme;
                return theme.color;
            },
        },
    },
    cardCarousel: {
        margin: "0 auto",
        "& .slick-list": {
            padding: "".concat(rem(spacings.xs), " 0"),
        },
        "& .slick-track": {
            display: "flex",
            marginBottom: rem(spacings.sam),
        },
        "& .slick-slide": {
            height: "auto",
            "& > div": {
                height: "100%",
                margin: "0 ".concat(rem(spacings.s)),
            },
        },
        "& .slick-arrow": {
            width: 48,
            height: 48,
            "&:before": {
                color: function (_a) {
                    var theme = _a.theme;
                    return theme.color;
                },
                fontSize: "3rem",
            },
        },
        "& .slick-prev": {
            left: -70,
            "&:before": {
                fontFamily: "Font Awesome\\ 5 Pro",
                content: '"\\f053"',
            },
        },
        "& .slick-next": {
            right: -70,
            "&:before": {
                fontFamily: "Font Awesome\\ 5 Pro",
                content: '"\\f054"',
            },
        },
        "& .slick-dots": {
            "& button:before, & .slick-active button:before": {
                fontFamily: "Font Awesome\\ 5 Pro",
                content: '"\\f111"',
                fontWeight: 900,
                fontSize: "1rem",
                color: function (_a) {
                    var theme = _a.theme;
                    return theme.color;
                },
            },
        },
        "& + $findMoreBottom": {
            marginTop: rem(spacings.xl),
        },
    },
    isSingleElement: {},
    fullHeight: {
        height: "100%",
    },
    flexCenter: __assign({}, onBreakpoint("sm", {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    })),
});
var pickTheme = function (colorSchema, isFeaturedPage) {
    if (isFeaturedPage) {
        return {
            background: brandColors.universalGreen40,
            color: textColors.black,
        };
    }
    switch (colorSchema) {
        case "green":
            return { background: brandColors.green, color: textColors.white };
        case "red":
            return { background: brandColors.darkRed, color: textColors.white };
        case "orange":
            return {
                background: brandColors.yellowOchre,
                color: textColors.black,
                textDecoration: "underline",
            };
        case "universalGreen":
            return {
                background: brandColors.universalGreen40,
                color: textColors.black,
                textDecoration: "underline",
            };
        default:
            return {
                background: brandColors.lightGrey,
                color: textColors.black,
                textDecoration: "underline",
            };
    }
};
export function ContactExpertComponent(props) {
    var _a, _b;
    var title = props.title, description = props.description, tagLine = props.tagLine, contact = props.contact, colorSchema = props.colorSchema, buttons = props.buttons, isFeaturedPage = props.isFeaturedPage;
    var getContacts = useContext(GlobalContentStoreContext).getContacts;
    var _c = useState(false), modalOpened = _c[0], setModalOpened = _c[1];
    var _d = useState(0), slide = _d[0], setSlide = _d[1];
    var contacts = getContacts();
    var contactsWithFallback = ((contact === null || contact === void 0 ? void 0 : contact.expandedValue) ||
        (contacts === null || contacts === void 0 ? void 0 : contacts.items));
    var contactsAmount = contactsWithFallback === null || contactsWithFallback === void 0 ? void 0 : contactsWithFallback.length;
    var isSingleElement = (contactsWithFallback === null || contactsWithFallback === void 0 ? void 0 : contactsWithFallback.length) === 1;
    var styles = contactExpertStyles({
        theme: pickTheme(colorSchema === null || colorSchema === void 0 ? void 0 : colorSchema.value, isFeaturedPage),
        isSingleElement: isSingleElement,
        isFeaturedPage: isFeaturedPage,
    });
    var closeModal = function () {
        setModalOpened(false);
    };
    function openModal(slide) {
        setModalOpened(true);
        setSlide(slide);
    }
    var deviceType = useDeviceType();
    var maxMobile = isDeviceResOrLower(deviceType, "mobile");
    var maxTablet = isDeviceResOrLower(deviceType, "tablet");
    var minLargeDesktop = isDeviceResOrHigher(deviceType, "largeDesktop");
    var onlyTablet = isDeviceResOrHigher(deviceType, "tablet") &&
        isDeviceResOrLower(deviceType, "tablet");
    var onlySmallDesktop = isDeviceResOrHigher(deviceType, "desktop") &&
        isDeviceResOrLower(deviceType, "desktop");
    var mobileStyles = (maxMobile && contactsAmount > 1) || (maxTablet && contactsAmount > 2);
    var carouselSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: minLargeDesktop ? 3 : 2,
        slidesToScroll: 1,
        arrows: true,
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, { className: cn(styles.contactExperts, isSingleElement && styles.isSingleElement), isFluid: isFeaturedPage },
            React.createElement(Container, { className: styles.contactExpertsContainer },
                React.createElement(Row, null,
                    React.createElement(Col, { sm: isSingleElement ? 6 : 8, smStart: isSingleElement ? 1 : 2, md: isSingleElement ? 5 : 6, mdStart: isSingleElement ? 2 : 3, className: styles.flexCenter },
                        React.createElement("div", { className: styles.infoSection },
                            React.createElement(AnimatedRenderProperty, { value: tagLine, className: styles.tag }),
                            React.createElement("h2", { className: styles.title },
                                React.createElement(AnimatedRenderProperty, { value: title, revealDelay: 200 })),
                            React.createElement(AnimatedRenderProperty, { value: description, className: styles.description, revealDelay: 400 }),
                            React.createElement(AnimatedRevealBlock, { revealDelay: 600 }, (_a = buttons.value) === null || _a === void 0 ? void 0 : _a.map(function (b, idx) { return (React.createElement(ButtonWrapper, __assign({}, b, { key: idx, className: cn(styles.findMoreTop, isSingleElement && styles.isSingleElement), buttonType: "link" }))); })))),
                    React.createElement(Col, { sm: isSingleElement ? 4 : 8, smStart: isSingleElement ? null : 2, md: isSingleElement ? 4 : 10, mdStart: isSingleElement ? null : 1, lg: isSingleElement ? 3 : 10, style: { width: "100%" /* due to FF */ } },
                        (onlySmallDesktop && contactsAmount > 2) ||
                            (minLargeDesktop && contactsAmount > 3) ? (React.createElement(React.Fragment, null,
                            React.createElement(Slider, __assign({}, carouselSettings, { className: styles.cardCarousel }), contactsWithFallback === null || contactsWithFallback === void 0 ? void 0 : contactsWithFallback.map(function (c, idx) { return (React.createElement(AnimatedRevealBlock, { key: idx, className: styles.fullHeight, revealDelay: idx >= carouselSettings.slidesToShow ? -1 : 200 * idx },
                                React.createElement(ContactExpertCard, __assign({}, c, { openModal: openModal, itemNumber: idx })))); })))) : (React.createElement(Row, { className: cn(isSingleElement ? undefined : styles.contactList) }, contactsWithFallback === null || contactsWithFallback === void 0 ? void 0 : contactsWithFallback.map(function (c, idx) { return (React.createElement(Col, { sm: contactsAmount === 2 ? 6 : 12, md: isSingleElement ? 12 : 5, mdStart: idx === 0 && contactsAmount === 2 ? 1 : null, lg: isSingleElement ? 12 : 4, lgStart: idx === 0 && contactsAmount === 2 ? 2 : null, key: idx },
                            React.createElement(AnimatedRevealBlock, { movement: false, revealDelay: 200 + 200 * idx, className: styles.fullHeight },
                                React.createElement(ContactExpertCard, __assign({}, c, { isSingleElement: isSingleElement, moreThanTwoItems: contactsAmount > 2, openModal: openModal, mobileStyles: mobileStyles, onlyTablet: onlyTablet, itemNumber: idx }))))); }))),
                        React.createElement(AnimatedRevealBlock, { revealDelay: 600, className: cn(styles.findMoreBottom, isSingleElement && styles.isSingleElement) }, (_b = buttons.value) === null || _b === void 0 ? void 0 : _b.map(function (b, idx) { return (React.createElement(ButtonWrapper, __assign({}, b, { key: idx, className: styles.findMoreBottom, buttonType: "link" }))); })))))),
        React.createElement(ContactListOverlay, { contacts: contactsWithFallback, modalOpened: modalOpened, closeModal: closeModal, initialSlide: slide })));
}
