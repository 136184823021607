import React, { useState } from "react";
import cn from "classnames";
import { CloudinaryVideoPlayer } from "@components/CloudinaryVideoPlayer/CloudinaryVideoPlayer";
import { AnimatedBlockRenderProperty, RenderProperty, } from "@/src/views/RenderProperty";
import { AnimatedRevealBlock } from "@components/AnimatedReveal/AnimatedReveal";
import { Container, Row, Col } from "@basics/layout";
import { LocalizedLabel } from "@hooks/LocalizationContext";
import { Button } from "@components/Button/Button";
import { SemanticHeader } from "@components/SemanticHeader/SematicHeader";
import { useStyles } from "./styles";
export function FeaturedContentReversed(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var isVideo = function (dataBlock) {
        return typeof dataBlock.videoBlockArea !== "undefined";
    };
    var _m = useState(false), forceVideoPlay = _m[0], setForceVideoPlay = _m[1];
    var isRight = (isVideo(props.data)
        ? props.data.videoAlignment.value
        : props.data.imageAlignment.value) === "right";
    var styles = useStyles(isRight);
    var link = !isVideo(props.data) ? (_a = props.data.link.value) === null || _a === void 0 ? void 0 : _a[0] : undefined;
    var videoBlock = isVideo(props.data) && ((_b = props.data.videoBlockArea.expandedValue) === null || _b === void 0 ? void 0 : _b[0])
        ? props.data.videoBlockArea.expandedValue[0]
        : undefined;
    var isAutoplay = ((_c = videoBlock === null || videoBlock === void 0 ? void 0 : videoBlock.startAction) === null || _c === void 0 ? void 0 : _c.value) === "autoplay";
    var isLoop = ((_d = videoBlock === null || videoBlock === void 0 ? void 0 : videoBlock.endAction) === null || _d === void 0 ? void 0 : _d.value) === "loop_video";
    var resetOnEnd = ((_e = videoBlock === null || videoBlock === void 0 ? void 0 : videoBlock.endAction) === null || _e === void 0 ? void 0 : _e.value) === "reset_to_thumbnail";
    var defaultPosterUrl = ((_f = videoBlock === null || videoBlock === void 0 ? void 0 : videoBlock.thumbnail.value) === null || _f === void 0 ? void 0 : _f.urlLarge) ||
        ((_g = videoBlock === null || videoBlock === void 0 ? void 0 : videoBlock.thumbnail.value) === null || _g === void 0 ? void 0 : _g.urlMedium) ||
        ((_h = videoBlock === null || videoBlock === void 0 ? void 0 : videoBlock.thumbnail.value) === null || _h === void 0 ? void 0 : _h.urlSmall);
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, null,
            React.createElement(Row, null,
                React.createElement(Col, { sm: 9, smStart: isRight ? 3 : undefined },
                    React.createElement("div", { className: cn(styles.teaserItemBg, !isRight && styles.leftItem) },
                        React.createElement("div", { className: cn(styles.teaserItem, styles.fullHeight) },
                            React.createElement(SemanticHeader, { className: styles.h2, headerSize: (_j = props.data.headerSize) === null || _j === void 0 ? void 0 : _j.value },
                                React.createElement(RenderProperty, { value: props.data.headline, fallbackValue: videoBlock === null || videoBlock === void 0 ? void 0 : videoBlock.headline })),
                            React.createElement("div", { className: styles.descriptionBox },
                                React.createElement("p", { className: styles.textDefault },
                                    React.createElement(RenderProperty, { value: props.data.descriptionRichText }))),
                            isVideo(props.data) ? (React.createElement(Button, { type: "secondary", onClick: function () { return setForceVideoPlay(true); }, className: styles.button, icon: "play" },
                                React.createElement(LocalizedLabel, { section: "Global", label: "PlayVideo" }))) : (link && (React.createElement(Button, { type: "secondary", href: link.href, title: link.title, target: link.target, className: styles.button }, link.text)))))))),
        React.createElement(AnimatedRevealBlock, { className: cn(styles.textContainer, isVideo(props.data)
                ? styles.textVideoContainer
                : styles.textImageContainer), revealDelay: 100 },
            React.createElement(Container, { className: styles.fullHeight },
                React.createElement(Row, { className: styles.fullHeight },
                    React.createElement(Col, { xs: 4, sm: 4, smStart: isRight ? undefined : 8, className: styles.itemSectionColumn },
                        React.createElement("div", { className: cn(styles.itemSection, isRight ? styles.leftItem : styles.rightItem, styles.fullHeight) }, isVideo(props.data) ? (React.createElement(CloudinaryVideoPlayer, { publicId: ((_k = videoBlock === null || videoBlock === void 0 ? void 0 : videoBlock.cloudinaryVideoPublicID) === null || _k === void 0 ? void 0 : _k.value) || "", logoImageUrl: ((_l = props.startPageData.logo.value) === null || _l === void 0 ? void 0 : _l.url) || "", showControls: false, autoplay: isAutoplay, loop: isLoop, resetVideo: resetOnEnd, thumbnailUrl: defaultPosterUrl || undefined, withOverlay: true, forcePlay: forceVideoPlay, setForcePlay: setForceVideoPlay })) : (React.createElement(AnimatedBlockRenderProperty, { value: props.data.imageAdaptive })))))))));
}
