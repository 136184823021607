import React from "react";
import { useInView } from "react-intersection-observer";
import { createUseStyles } from "react-jss";
import cn from "classnames";
import { isCypressTest } from "@basics/isCypressTest";
import { getFirefoxVersion, isFirefox, isNonChromiumEdge, } from "@basics/browserDetection";
var useStyles = createUseStyles({
    wrapper: function (_a) {
        var inView = _a.inView, delay = _a.delay, fade = _a.fade, movement = _a.movement;
        return ({
            opacity: inView || delay < 0 || !fade ? 1 : 0,
            transform: inView || delay < 0 || !movement ? "none" : "translateY(-1em)",
            transition: "opacity 0.65s ease, transform 0.65s ease",
            transitionDelay: delay + "ms",
        });
    },
});
function makeAnimatedReveal(type) {
    function AnimatedReveal(_a) {
        var children = _a.children, className = _a.className, revealDelay = _a.revealDelay, fade = _a.fade, movement = _a.movement, dataTestId = _a["data-testid"];
        var _b = useInView({
            threshold: 0.3,
            triggerOnce: true,
            delay: 100,
            rootMargin: "50px",
        }), ref = _b.ref, inView = _b.inView;
        var styles = useStyles({
            inView: inView,
            fade: fade,
            movement: movement,
            delay: Number(revealDelay) || 0,
        });
        var disableAnimations = isCypressTest() ||
            isNonChromiumEdge() ||
            (isFirefox() && getFirefoxVersion() < 79) ||
            (inView && document.readyState !== "complete");
        if (type === "block") {
            return (React.createElement("div", { ref: ref, className: cn(!disableAnimations && styles.wrapper, className), "data-testid": dataTestId }, children));
        }
        else {
            return (React.createElement("span", { ref: ref, className: cn(!disableAnimations && styles.wrapper, className), "data-testid": dataTestId }, children));
        }
    }
    AnimatedReveal.defaultProps = {
        fade: true,
        movement: true,
    };
    return AnimatedReveal;
}
export var AnimatedRevealInline = makeAnimatedReveal("inline");
export var AnimatedRevealBlock = makeAnimatedReveal("block");
