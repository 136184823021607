var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cn from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";
import { brandColors } from "@basics/brand-colors";
import { fonts } from "@basics/fonts";
import { onBreakpoint, rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { FAIcon, FAIconStyles } from "@components/FAIcon/FAIcon";
var useStyles = createUseStyles({
    button: __assign(__assign({}, fonts.bold), { display: "inline-flex", alignItems: "center", textAlign: "center", border: "none", position: "relative", outline: "none", flexShrink: "0", fontSize: "1.1875rem", lineHeight: "26px", transition: "all 0.3s ease", boxShadow: "inset 0 0 0 2px var(--borderColor)", backgroundPosition: "100% 100%", 
        // hover state
        "&:hover:not(:active):not(:focus)": {
            backgroundPosition: "100% 100%",
            boxShadow: "inset 0 0 0 2px var(--hoverColor)",
        }, 
        // after press, default state
        "&:hover:focus": {
            backgroundPosition: "100% 200%",
            boxShadow: "inset 0 0 0 2px var(--hoverColor)",
        }, "&:hover $icon, &:focus $icon": {
            transform: "scale(1)",
        }, "&:hover $iconInternalLink, &:focus-visible $iconInternalLink": {
            transform: "scale(1) translateX(2px)",
        }, "&:disabled": {
            pointerEvents: "none",
            cursor: "not-allowed",
        }, "&:focus-visible": {
            "&:before": {
                content: '""',
                position: "absolute",
                cursor: "pointer",
                width: "100%",
                height: "100%",
                top: "-6px",
                left: "-6px",
                border: "2px solid",
                borderRadius: "4px",
                borderColor: brandColors.lightBlue,
                padding: "4px",
                boxSizing: "content-box",
            },
        } }),
    default: {
        width: "auto",
        height: "auto",
        "&:not($link)": __assign({ padding: "9px ".concat(rem(spacings.sam), " 11px") }, onBreakpoint("sm", {
            padding: "14px ".concat(rem(spacings.m), " ").concat(rem(spacings.s)),
        })),
    },
    square: __assign(__assign({ justifyContent: "center" }, onBreakpoint("sm", {
        width: "56px",
        height: "56px",
    })), { width: "46px", height: "46px", padding: "0px" }),
    primary: {
        "--defaultColor": brandColors.universalGreen,
        "--hoverColor": brandColors.universalGreenHover,
        "--borderColor": brandColors.universalGreen,
        color: brandColors.white,
        backgroundColor: brandColors.universalGreen,
        "& svg": {
            color: brandColors.white,
        },
        "&:hover:not(:active):not(:focus)": {
            backgroundColor: brandColors.universalGreenHover,
            "& svg": {
                color: brandColors.white,
            },
        },
        "&:disabled": {
            color: brandColors.grey,
            background: brandColors.lightGrey,
            boxShadow: "inset 0 0 0 2px ".concat(brandColors.lightGrey),
            "& svg": {
                color: brandColors.grey,
            },
        },
    },
    secondary: {
        "--defaultColor": brandColors.white,
        "--hoverColor": brandColors.universalGreenHover,
        "--borderColor": brandColors.universalGreen,
        color: brandColors.black,
        backgroundColor: brandColors.white,
        "& svg": {
            color: brandColors.black,
        },
        "@media (hover: hover) and (pointer: fine)": {
            "&:hover:not(:active):not(:focus)": {
                color: brandColors.white,
                backgroundColor: brandColors.universalGreenHover,
                "& svg": {
                    color: brandColors.white,
                },
            },
        },
        "&:disabled": {
            color: brandColors.grey,
            background: function (_a) {
                var shape = _a.shape;
                return shape === "default" ? brandColors.white : brandColors.lightGrey;
            },
            boxShadow: function (_a) {
                var shape = _a.shape;
                return shape === "default"
                    ? "inset 0 0 0 2px ".concat(brandColors.grey)
                    : "inset 0 0 0 2px ".concat(brandColors.lightGrey);
            },
            "& svg": {
                color: brandColors.grey,
            },
        },
    },
    tertiary: {
        "--defaultColor": brandColors.white,
        "--hoverColor": brandColors.black,
        "--borderColor": brandColors.black,
        color: brandColors.black,
        backgroundColor: brandColors.white,
        "& svg": {
            color: brandColors.black,
        },
        "&:hover:not(:active):not(:focus)": {
            backgroundColor: brandColors.black,
            color: brandColors.white,
            "& svg": {
                color: brandColors.white,
            },
        },
        "&:disabled": {
            color: brandColors.grey,
            backgroundColor: brandColors.white,
            boxShadow: "inset 0 0 0 2px ".concat(brandColors.grey),
            "& svg": {
                color: brandColors.grey,
            },
        },
    },
    link: __assign({ color: brandColors.black, background: "none", border: "none", padding: 0, display: "inline-flex", alignItems: "center", justifyContent: "center", textAlign: "left", "&:hover": {
            background: "none",
        }, fontSize: rem(spacings.s) }, onBreakpoint("sm", { fontSize: "1.1875rem" })),
    icon: {
        verticalAlign: "middle",
        transform: "scale(0.95) translateX(0px)",
        transition: "transform 0.3s ease",
        marginLeft: "".concat(rem(spacings.xs)),
    },
    iconInternalLink: {
        marginLeft: rem(spacings.xs),
    },
    iconMedium: __assign({}, FAIconStyles({
        width: 30,
        height: 30,
    })),
    iconSmall: __assign({}, FAIconStyles({
        width: 18,
        height: 18,
    })),
    animationUp: {
        background: "linear-gradient(var(--defaultColor), var(--defaultColor) 50%, var(--hoverColor) 50%, var(--hoverColor))",
        backgroundSize: "100% 200%",
        backgroundPosition: "0% 0%",
        "&:active, &:focus": {
            transform: "translateY(2px)",
            transition: "transform 0.3s ease",
        },
    },
    animationRight: {
        background: "linear-gradient(90deg ,var(--defaultColor), var(--defaultColor) 50%, var(--hoverColor) 50%, var(--hoverColor))",
        backgroundPosition: "0% 0%",
        backgroundSize: "200% 100%",
        "&:active, &:focus": {
            transform: "translateX(2px)",
            transition: "transform 0.3s ease",
        },
    },
});
export var isExternal = function (url) {
    var urlObject = new URL(url, window.location.origin);
    return urlObject.host !== window.location.host;
};
export var isFile = function (url) {
    var urlObject = new URL(url, window.location.origin);
    return Boolean(urlObject.pathname.match(/^.*\.([a-z0-9]+)$/gim));
};
var HTMLAnchor = function (elemProps) { return React.createElement("a", __assign({}, elemProps), elemProps.children); };
var HTMLButton = function (elemProps) { return (React.createElement("button", __assign({ role: "button" }, elemProps), elemProps.children)); };
export var Button = function (props) {
    var _a = props.shape, shape = _a === void 0 ? "default" : _a, _b = props.animationDirection, animationDirection = _b === void 0 ? "up" : _b;
    var styles = useStyles({ shape: shape });
    var Element = props.href ? HTMLAnchor : HTMLButton;
    var customIcon = typeof props.icon !== "undefined";
    var clickFunction = props.onClick || props.trackingFunction
        ? function () {
            var _a, _b;
            (_a = props.trackingFunction) === null || _a === void 0 ? void 0 : _a.call(props);
            (_b = props.onClick) === null || _b === void 0 ? void 0 : _b.call(props);
        }
        : undefined;
    return (React.createElement(Element, { className: cn(styles.button, (props.type === "primary" || props.type === undefined) &&
            styles.primary, props.type === "secondary" && styles.secondary, props.type === "tertiary" && styles.tertiary, props.type === "link" && styles.link, shape === "square" && styles.square, shape === "default" && styles.default, animationDirection === "up" && styles.animationUp, animationDirection === "right" && styles.animationRight, props.className), onClick: clickFunction, href: props.href, title: props.title, target: (props.target === "_blank" && "_blank") || undefined, rel: (props.target === "_blank" && "noreferer noopener") || undefined, disabled: props.disabled, "data-testid": props.dataTestid },
        props.children,
        props.type === "link" && !customIcon && (React.createElement(FAIcon, { className: cn(styles.icon, styles.iconMedium, styles.iconInternalLink), icon: "long-arrow-right" })),
        props.type !== "link" &&
            props.href &&
            isExternal(props.href) &&
            !customIcon && (React.createElement(FAIcon, { className: cn(styles.icon, styles.iconSmall), icon: "external-link" })),
        props.type !== "link" &&
            props.href &&
            !isExternal(props.href) &&
            isFile(props.href) &&
            !customIcon && (React.createElement(FAIcon, { className: cn(styles.icon, styles.iconSmall), icon: "arrow-to-bottom" })),
        props.icon && customIcon && (React.createElement(FAIcon, { className: cn(styles.icon, styles.iconSmall), icon: props.icon }))));
};
export function ButtonWrapper(props) {
    var _a;
    var link = (_a = props.link) === null || _a === void 0 ? void 0 : _a[0];
    var icon = props.buttonIconType === "custom"
        ? props.buttonIcon
        : props.buttonIconType === "none"
            ? null
            : undefined;
    return (React.createElement(Button, { type: props.buttonType || undefined, href: (link === null || link === void 0 ? void 0 : link.href) || undefined, target: (link === null || link === void 0 ? void 0 : link.target) || undefined, title: (link === null || link === void 0 ? void 0 : link.title) || undefined, className: props.className, icon: icon, trackingFunction: props.trackingFunction }, link === null || link === void 0 ? void 0 : link.text));
}
