var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useContext, useState } from "react";
import { createUseStyles } from "react-jss";
import { GUTTER_WIDTHS, onBreakpoint, rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { typography } from "@basics/typography";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
import { LocalizedLabel } from "@hooks/LocalizationContext";
import { RenderProperty } from "@/src/views/RenderProperty";
import { Button } from "@components/Button/Button";
import { FormChoice } from "./Elements/FormChoice";
import { FormNumber } from "./Elements/FormNumber";
import { FormRichText } from "./Elements/FormRichText";
import { FormSelect } from "./Elements/FormSelect";
import { FormText } from "./Elements/FormText";
import { FormTextArea } from "./Elements/FormTextArea";
import { FormUrl } from "./Elements/FormUrl";
import { hasOptions, hasValidators } from "./Elements/common";
import { CONTACT_MAIL_PLACEHOLDER } from "./placeholders";
import { useBlurred } from "./useBlurred";
import { getValidators } from "./validation";
function renderElement(data, getValue, setValue, isFieldBlurred, setFieldBlurred, validateField, getIsFieldValid) {
    var elementType = data.contentType.join("/");
    var fieldId = String(data.contentLink.id);
    var value = getValue(fieldId);
    var isBlurred = isFieldBlurred(fieldId);
    var onChange = function (value) { return setValue(fieldId, value); };
    var setBlurred = function () {
        window.setTimeout(function () {
            setFieldBlurred(fieldId);
            validateField(fieldId);
        }, 500);
    };
    var isValid = getIsFieldValid(fieldId);
    switch (elementType) {
        case "Block/ExtendedChoiceElementBlock":
            return hasOptions(data) ? (React.createElement(FormChoice, { data: data, value: value, onChange: onChange, isBlurred: isBlurred, setBlurred: setBlurred, isValid: isValid })) : null;
        case "Block/ExtendedNumberElementBlock":
            return (React.createElement(FormNumber, { data: data, value: value, onChange: onChange, isBlurred: isBlurred, setBlurred: setBlurred, isValid: isValid }));
        case "Block/ExtendedSelectionElementBlock":
            return hasOptions(data) ? (React.createElement(FormSelect, { data: data, value: value, onChange: onChange, isBlurred: isBlurred, setBlurred: setBlurred, isValid: isValid })) : null;
        case "Block/ExtendedTextAreaElementBlock":
            return (React.createElement(FormTextArea, { data: data, value: value, onChange: onChange, isBlurred: isBlurred, setBlurred: setBlurred, isValid: isValid }));
        case "Block/ExtendedTextFormBlock":
            return (React.createElement(FormText, { data: data, value: value, onChange: onChange, isBlurred: isBlurred, setBlurred: setBlurred, isValid: isValid }));
        case "Block/ExtendedUrlElementBlock":
            return (React.createElement(FormUrl, { data: data, value: value, onChange: onChange, isBlurred: isBlurred, setBlurred: setBlurred, isValid: isValid }));
        case "Block/ParagraphTextElementBlock":
            return React.createElement(FormRichText, { data: data });
        default:
            return null;
    }
}
function useFormModel(initialFormDescription) {
    var _a, _b, _c;
    var _d = useState(new Map(((_a = initialFormDescription.elementsArea.expandedValue) === null || _a === void 0 ? void 0 : _a.map(function (element) {
        var _a;
        var elementType = element.contentType.join("/");
        var fieldId = String(element.contentLink.id);
        switch (elementType) {
            case "Block/ExtendedChoiceElementBlock": {
                var choiceElement_1 = element;
                var isMultiSelect = choiceElement_1.allowMultiSelect.value;
                var getValue_1 = function () { var _a; return ((_a = choiceElement_1.options.find(function (option) { return option.checked; })) === null || _a === void 0 ? void 0 : _a.value) || ""; };
                var getValues = function () {
                    return choiceElement_1.options
                        .filter(function (option) { return option.checked; })
                        .map(function (option) { return option.value; })
                        .join(" ") || "";
                };
                return [fieldId, isMultiSelect ? getValues() : getValue_1()];
            }
            case "Block/ExtendedSelectionElementBlock": {
                var selectElement = element;
                var value = ((_a = selectElement.options.find(function (option) { return option.checked; })) === null || _a === void 0 ? void 0 : _a.value) || "";
                return [fieldId, value];
            }
            case "Block/MappedValueElementBlock": {
                var predefinedValue = element.predefinedValue.value ||
                    "";
                return [fieldId, predefinedValue];
            }
            case "Block/PredefinedHiddenElementBlock": {
                var predefinedValue = element.predefinedValue
                    .value || "";
                return [fieldId, predefinedValue];
            }
            default:
                return [fieldId, null];
        }
    })) || [])), formModel = _d[0], setFormModel = _d[1];
    var _e = useState(new Map()), validityMap = _e[0], setValidityMap = _e[1];
    var validators = new Map((_c = (_b = initialFormDescription.elementsArea.expandedValue) === null || _b === void 0 ? void 0 : _b.filter(hasValidators)) === null || _c === void 0 ? void 0 : _c.map(function (element) { return [
        String(element.contentLink.id),
        getValidators(element.validatorsExpanded),
    ]; }));
    function getValue(fieldId) {
        return formModel.get(fieldId) || null;
    }
    function setValue(fieldId, value) {
        setFormModel(function (s) {
            var newMap = new Map(s);
            newMap.set(fieldId, value);
            return newMap;
        });
    }
    function validateField(fieldId) {
        var _a;
        var isFieldValid = ((_a = validators.get(fieldId)) === null || _a === void 0 ? void 0 : _a(formModel.get(fieldId) || null)) || true;
        setValidityMap(function (s) {
            var newMap = new Map(s);
            newMap.set(fieldId, isFieldValid);
            return newMap;
        });
        return isFieldValid;
    }
    function getIsFieldValid(fieldId) {
        return validityMap.get(fieldId) || true;
    }
    function validateForm() {
        return Array.from(validators.keys())
            .map(function (fieldId) { return validateField(fieldId); })
            .reduce(function (acc, validity) { return acc && validity === true; }, true);
    }
    function setFieldsErrors(validationErrors) {
        setValidityMap(function (s) {
            var newMap = new Map(s);
            validationErrors.forEach(function (_a) {
                var fieldId = _a.fieldId, error = _a.error;
                var prevFieldValidity = newMap.get(fieldId) || true;
                newMap.set(fieldId, prevFieldValidity === true ? [error] : __spreadArray(__spreadArray([], prevFieldValidity, true), [error], false));
            });
            return newMap;
        });
    }
    return {
        getValue: getValue,
        setValue: setValue,
        validateField: validateField,
        getIsFieldValid: getIsFieldValid,
        validateForm: validateForm,
        setFieldsErrors: setFieldsErrors,
        formModel: formModel,
    };
}
var useStyles = createUseStyles({
    headline: typography.h2,
    description: typography.textDefault,
    formModal: {
        "& .Form__Element": {
            marginTop: rem(spacings.sam),
        },
    },
    form: __assign(__assign({ display: "flex", flexWrap: "wrap", alignItems: "flex-start" }, onBreakpoint("sm", {
        marginLeft: -GUTTER_WIDTHS["small"],
    })), onBreakpoint("md", {
        marginLeft: -GUTTER_WIDTHS["big"],
    })),
    flexBreak: {
        flexBasis: "100%",
        height: 0,
    },
    submitWrapper: {
        display: "flex",
        alignItems: "flex-end",
        width: "100%",
        flexDirection: "column",
    },
});
export function FormContainer(props) {
    var getMainPageContentLink = useContext(GlobalContentStoreContext).getMainPageContentLink;
    var allSteps = 1;
    var _a = useState(0), step = _a[0], setStep = _a[1];
    var _b = useState(false), isSubmitting = _b[0], setSubmitting = _b[1];
    var styles = useStyles();
    var _c = useBlurred(), isFieldBlurred = _c.isFieldBlurred, setFieldBlurred = _c.setFieldBlurred, setAllBlurred = _c.setAllBlurred;
    var _d = useFormModel(props.data), getValue = _d.getValue, setValue = _d.setValue, validateField = _d.validateField, getIsFieldValid = _d.getIsFieldValid, validateForm = _d.validateForm, setFieldsErrors = _d.setFieldsErrors, formModel = _d.formModel;
    function onSubmit() {
        var _a, _b;
        var isFormValid = validateForm();
        !isFormValid && setAllBlurred();
        if (isFormValid) {
            setSubmitting(true);
            var formData_1 = new FormData();
            formData_1.append("__FormGuid", props.data.contentLink.guidValue);
            formData_1.append("__FormHostedPage", String((_a = getMainPageContentLink()) === null || _a === void 0 ? void 0 : _a.id) || "");
            formData_1.append("__FormLanguage", ((_b = props.data.language) === null || _b === void 0 ? void 0 : _b.name) || "en");
            formData_1.append("__FormCurrentStepIndex", String(step));
            formData_1.append("__FormWithJavaScriptSupport", "true");
            Array.from(formModel.entries()).forEach(function (_a) {
                var id = _a[0], value = _a[1];
                var valueMap = new Map([
                    [CONTACT_MAIL_PLACEHOLDER, props.emailValue],
                ]);
                formData_1.append("__field_".concat(id), valueMap.get(value) || value);
            });
            fetch("/EPiServer.Forms/DataSubmit/Submit", {
                method: "POST",
                body: formData_1,
            })
                .then(function (response) { return response.json(); })
                .then(function (response) {
                var _a;
                if (response.isSuccess) {
                    (_a = props.trackingFunction) === null || _a === void 0 ? void 0 : _a.call(props);
                    if (step + 1 === allSteps && props.data.redirectToPage.value) {
                        window.location.href = props.data.redirectToPage.value;
                    }
                    else {
                        setStep(function (s) { return (s + 1 === allSteps ? -1 : s + 1); });
                    }
                }
                else {
                    var errors = response.data.validationInfo;
                    var fieldIdRegex_1 = /__field_(?<fieldId>\d+)/;
                    setFieldsErrors(errors.map(function (error) {
                        var _a, _b;
                        return ({
                            fieldId: ((_b = (_a = error.invalidElementName.match(fieldIdRegex_1)) === null || _a === void 0 ? void 0 : _a.groups) === null || _b === void 0 ? void 0 : _b.fieldId) || "",
                            error: error.validationMessage,
                        });
                    }));
                }
            })
                .catch(function (error) {
                console.error(error);
            })
                .finally(function () { return setSubmitting(false); });
        }
    }
    return (React.createElement("div", null, step >= 0 ? (React.createElement(React.Fragment, null,
        props.data.title.value && (React.createElement("h2", { className: styles.headline },
            React.createElement(RenderProperty, { value: props.data.title }))),
        props.data.description.value && (React.createElement("aside", null,
            React.createElement(RenderProperty, { value: props.data.description, className: styles.description }))),
        React.createElement("form", { className: styles.form, onSubmit: function (event) {
                event.preventDefault();
                onSubmit();
            }, id: "".concat(props.data.contentLink.id) },
            props.data.elementsArea.expandedValue.map(function (elem) {
                return renderElement(elem, getValue, setValue, isFieldBlurred, setFieldBlurred, validateField, getIsFieldValid);
            }),
            React.createElement("div", { className: styles.submitWrapper },
                React.createElement(Button, { disabled: isSubmitting },
                    React.createElement(LocalizedLabel, { section: "Forms", label: "Submit" })))))) : (React.createElement(RenderProperty, { value: props.data.submitSuccessMessage }))));
}
