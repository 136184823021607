import cn from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
import { LocalizedLabel } from "@hooks/LocalizationContext";
import { useTracking } from "@hooks/useTracking";
import { RenderProperty, ShowPropertyOnEdit } from "@/src/views/RenderProperty";
import { Button } from "@components/Button/Button";
import { FAIcon } from "@components/FAIcon/FAIcon";
import { formattedPhoneNumber } from "@components/Block/ContactListBlock/PersonCard";
import { contactCardExpertStyles } from "./styles";
export var PersonContactExpertCard = function (props) {
    var _a, _b, _c, _d, _e;
    var _f = useContext(GlobalContentStoreContext), getStartPageData = _f.getStartPageData, getImmediateStartPageData = _f.getImmediateStartPageData;
    var _g = useState(getImmediateStartPageData()), startPageData = _g[0], setStartPageData = _g[1];
    var tracking = useTracking();
    useEffect(function () {
        !startPageData &&
            getStartPageData().then(function (response) {
                setStartPageData(response);
            });
    }, []);
    var firstName = props.firstName, lastName = props.lastName, photo = props.photo, role = props.role, jobTitle = props.jobTitle, email = props.email, phoneNumber = props.phoneNumber;
    var cardPhoto = ((_a = photo.value) === null || _a === void 0 ? void 0 : _a.urlSmall) && ((_b = photo.value) === null || _b === void 0 ? void 0 : _b.urlMedium) && ((_c = photo.value) === null || _c === void 0 ? void 0 : _c.urlLarge)
        ? photo
        : startPageData === null || startPageData === void 0 ? void 0 : startPageData.contactPersonImageFallback;
    var isPhoto = !!((_d = photo.value) === null || _d === void 0 ? void 0 : _d.urlMedium) ||
        !!((_e = startPageData === null || startPageData === void 0 ? void 0 : startPageData.contactPersonImageFallback.value) === null || _e === void 0 ? void 0 : _e.urlMedium);
    var styles = contactCardExpertStyles({
        isSingleElement: props.isSingleElement,
        isPhoto: isPhoto,
        onlyTablet: props.onlyTablet,
    });
    var isLandscape = props.contentType[1] === "ContactGenericBlock";
    var trackingFunction = function () {
        return tracking.event("Contacts", "Open expert contact", "".concat((firstName === null || firstName === void 0 ? void 0 : firstName.value) || "", " ").concat((lastName === null || lastName === void 0 ? void 0 : lastName.value) || ""));
    };
    return (React.createElement("article", { className: cn(styles.card, !props.mobileStyles && isLandscape && styles.landscaped, props.mobileStyles && styles.cardMobile, props.isSingleElement && styles.singleCard, props.onlyTablet && styles.onlyTablet) },
        cardPhoto && (React.createElement("div", { className: cn(!isLandscape && styles.photoWrapper) },
            React.createElement(RenderProperty, { value: cardPhoto, className: styles.photo }))),
        React.createElement("div", { className: styles.bioSection },
            React.createElement("h4", { className: styles.name },
                firstName && React.createElement(RenderProperty, { value: firstName }),
                " ",
                lastName && React.createElement(RenderProperty, { value: lastName })),
            ShowPropertyOnEdit(jobTitle) && (React.createElement(RenderProperty, { value: jobTitle, className: styles.jobTitle })),
            ShowPropertyOnEdit(role) && (React.createElement(RenderProperty, { value: role, className: styles.role })),
            React.createElement("div", { className: styles.ctaButtons },
                React.createElement(Button, { onClick: function () { return props.openModal(props.itemNumber); }, trackingFunction: trackingFunction, dataTestid: "ContactExpertCardButton" },
                    React.createElement(LocalizedLabel, { section: "Contact", label: "Contact" }))),
            React.createElement("div", { className: styles.iconsMobile },
                React.createElement("div", null,
                    React.createElement("a", { href: "mailto:".concat(email.value) },
                        React.createElement("span", { className: styles.accessibilityText },
                            "Write an email to ",
                            email.value),
                        React.createElement(FAIcon, { icon: "envelope", className: styles.icon })),
                    phoneNumber.value && (React.createElement("a", { href: "tel: ".concat(formattedPhoneNumber(phoneNumber.value)) },
                        React.createElement("span", { className: styles.accessibilityText },
                            "Call to a phone number ",
                            phoneNumber.value),
                        React.createElement(FAIcon, { icon: "phone", className: styles.icon })))))),
        React.createElement("div", { className: styles.buttonMobile },
            React.createElement(Button, { type: "link", onClick: function () { return props.openModal(props.itemNumber); }, trackingFunction: trackingFunction, dataTestid: "ContactExpertCardButton" },
                React.createElement(LocalizedLabel, { section: "Contact", label: "ShowDetails" })))));
};
