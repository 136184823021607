var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { HeroBackground } from "./HeroBackground";
import { Logo } from "./Logo";
import { useStyles } from "./styles";
import { ContactDetails } from "./ContactDetails";
import { ContactPhoto } from "./ContactPhoto";
import { ErrorMessage } from "./ErrorMessage";
export var BusinessCard = function (props) {
    var _a, _b;
    var _c = useStyles(), backgroundImage = _c.backgroundImage, backgroundLogo = _c.backgroundLogo, container = _c.container, heroSection = _c.heroSection, errorMessage = _c.errorMessage;
    var showContact = (_a = props.contactPerson) === null || _a === void 0 ? void 0 : _a.expandedValue;
    return (React.createElement("div", { className: container },
        React.createElement("div", { className: heroSection },
            React.createElement(HeroBackground, { className: backgroundImage }),
            React.createElement(Logo, { className: backgroundLogo }),
            showContact && (React.createElement(ContactPhoto, { photo: ((_b = props.contactPerson) === null || _b === void 0 ? void 0 : _b.expandedValue)
                    .photo }))),
        showContact ? (React.createElement(ContactDetails, __assign({}, props))) : (React.createElement(ErrorMessage, { className: errorMessage }))));
};
