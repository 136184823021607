import { brandColors } from "@basics/brand-colors";
import { findCountryAndRegion } from "./regionsExtentions";
export var Colors = {
    "hdi-logo-green": brandColors.green,
    "universal-green": brandColors.universalGreen,
    "universal-green-hover": brandColors.universalGreenHover,
    "hdi-light-blue": brandColors.lightBlue,
    gray: "#ACACAC",
    "region-color": null,
};
export var MapColors = {
    mandatedBusiness: Colors["hdi-logo-green"],
    networkPartner: Colors["universal-green"],
    individualSolution: brandColors.lightGrey,
    unsupportedPartner: brandColors.white,
};
var customColorExists = function (subject) {
    return (subject && subject.customColor && subject.customColor !== "region-color");
};
export var findColorFor = function (geography, data) {
    var result = findCountryAndRegion(geography, data === null || data === void 0 ? void 0 : data.regions);
    if (!result) {
        return MapColors.networkPartner;
    }
    var country = result.country, region = result.region;
    if (country === null || country === void 0 ? void 0 : country.disabled) {
        return MapColors.unsupportedPartner;
    }
    if (customColorExists(country)) {
        return Colors[country.customColor] || MapColors.networkPartner;
    }
    else if (region && region.customColor) {
        return Colors[region.customColor] || MapColors.networkPartner;
    }
    else {
        return MapColors.networkPartner;
    }
};
