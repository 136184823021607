var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { isContactPersonBlock } from "@components/Block/ContactListBlock/CardOverlay";
import { GenericContactExpertCard } from "./GenericContactExpertCard";
import { PersonContactExpertCard } from "./PersonContactExpertCard";
export var ContactExpertCard = function (props) {
    if (isContactPersonBlock(props)) {
        return React.createElement(PersonContactExpertCard, __assign({}, props));
    }
    else {
        return React.createElement(GenericContactExpertCard, __assign({}, props));
    }
};
