var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { RenderProperty } from "@/src/views/RenderProperty";
import { colors } from "@basics/colors";
import { rem, onBreakpoint, BREAKPOINTS, Row, Col } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { typography, customTypography } from "@basics/typography";
import { SvgRenderer } from "@components/Block/SvgBlock/SvgRenderer";
import { SemanticHeader } from "@components/SemanticHeader/SematicHeader";
import React from "react";
import { createUseStyles } from "react-jss";
var useStyles = createUseStyles({
    textContainer: {
        marginBottom: rem(spacings.s),
    },
    headline: __assign(__assign({}, typography.h2), { textAlign: "center" }),
    description: customTypography(__assign(__assign({}, typography.textDefault), { textAlign: "center" }), { marginBottom: rem(spacings.s) }, { marginBottom: rem(spacings.sam) }, { marginBottom: rem(spacings.m) }),
    svgInner: {
        width: "100%",
        transform: "scale(1)",
        transition: "transform 0.3s ease",
    },
    svgCaption: {
        marginTop: rem(spacings.xxs),
        "& p": __assign(__assign({}, typography.textXSmall), { color: colors.gray60 }),
    },
    grayBg: __assign(__assign({ backgroundColor: function (_a) {
            var isGrayBg = _a.isGrayBg;
            return isGrayBg && colors.gray10;
        }, padding: "".concat(spacings.m, " ").concat(spacings.sam, " ").concat(spacings.m) }, onBreakpoint("sm", {
        padding: "".concat(spacings.l, " ").concat(spacings.m, " ").concat(spacings.l),
    })), onBreakpoint("md", {
        padding: "".concat(spacings.xxl, " ").concat(spacings.l, " ").concat(spacings.xxl),
    })),
    bgRow: {
        maxWidth: BREAKPOINTS["xl"],
        margin: "0 auto",
    },
});
export var SvgComponent = function (props) {
    var _a, _b;
    var _c = props.data, vectorImage = _c.vectorImage, imageCaption = _c.imageCaption, title = _c.title, headerSize = _c.headerSize, description = _c.description;
    var isGrayBg = ((_a = props.data.backgroundColor) === null || _a === void 0 ? void 0 : _a.value) === "gray";
    var styles = useStyles({ isGrayBg: isGrayBg });
    return (React.createElement("div", null,
        (title.value || description.value) && (React.createElement(Row, null,
            React.createElement(Col, { className: styles.textContainer },
                title.value && (React.createElement(SemanticHeader, { className: styles.headline, headerSize: headerSize === null || headerSize === void 0 ? void 0 : headerSize.value },
                    React.createElement(RenderProperty, { value: title }))),
                description.value && (React.createElement("div", { className: styles.description },
                    React.createElement(RenderProperty, { value: description })))))),
        React.createElement(Row, null,
            React.createElement(Col, { md: 10, mdStart: 1 },
                React.createElement(SvgRenderer, { url: ((_b = vectorImage.value) === null || _b === void 0 ? void 0 : _b.url) || "", className: styles.svgInner }),
                imageCaption.value && (React.createElement(RenderProperty, { value: imageCaption, className: styles.svgCaption }))))));
};
