var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import { Container, onBreakpoint, rem } from "@basics/layout";
import { createUseStyles } from "react-jss";
import { FactsAndFiguresLayout } from "./FactsAndFiguresLayout";
import { spacings } from "@basics/spacings";
var useStyles = createUseStyles({
    factsAndFigures: __assign(__assign(__assign({ marginBottom: rem(spacings.m) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.l),
    })), { display: "flex", flexWrap: "wrap" }), onBreakpoint("md", {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gridTemplateRows: "repeat(2, 1fr)",
        gridColumnGap: 24,
        gridRowGap: 24,
        marginBottom: rem(spacings.xxl),
    })),
});
export function FactsAndFigures(props) {
    var jsonRaw = props.data.jsonRaw;
    var _a = useState(), factsAndFiguresData = _a[0], setFactsAndFiguresData = _a[1];
    useEffect(function () {
        try {
            var data = JSON.parse(jsonRaw.value || "");
            setFactsAndFiguresData(data);
        }
        catch (_a) {
            console.error("No json file.");
        }
    }, []);
    var styles = useStyles();
    return (React.createElement(Container, { className: styles.factsAndFigures },
        React.createElement(FactsAndFiguresLayout, { blocks: (factsAndFiguresData === null || factsAndFiguresData === void 0 ? void 0 : factsAndFiguresData.blocks) || [], layout: (factsAndFiguresData === null || factsAndFiguresData === void 0 ? void 0 : factsAndFiguresData.layout) || "1" })));
}
