var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export function convertPascalCaseToCamelCase(obj) {
    function camalize(str) {
        return str
            .replace(/\s(.)/g, function ($1) {
            return $1.toUpperCase();
        })
            .replace(/\s/g, "")
            .replace(/^(.)/, function ($1) {
            return $1.toLowerCase();
        });
    }
    return Array.from(Object.entries(obj))
        .map(function (_a) {
        var key = _a[0], value = _a[1];
        return [
            camalize(key),
            typeof value === "object" && value !== null
                ? Array.isArray(value)
                    ? value.map(function (item) {
                        return typeof item === "object" && item !== null
                            ? convertPascalCaseToCamelCase(item)
                            : item;
                    })
                    : convertPascalCaseToCamelCase(value)
                : value,
            value,
        ];
    })
        .reduce(function (acc, _a) {
        var _b;
        var key = _a[0], value = _a[1];
        return (__assign(__assign({}, acc), (_b = {}, _b[key] = value, _b)));
    }, {});
}
