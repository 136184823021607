var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useContext, useState, useEffect, useRef } from "react";
import { Header } from "@components/Header/Header";
import { Footer } from "@components/Shared/Footer";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
import { LocalizedLabel, localizedLabelString, LocalizationContext, } from "@hooks/LocalizationContext";
import { createUseStyles } from "react-jss";
import { rem, onBreakpoint, Container, Row, Col, onBefore, CONTAINER_OFFSET_WIDTHS, } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { typography, customTypography } from "@basics/typography";
import { colors } from "@basics/colors";
import { Spinner } from "@components/Spinner/Spinner";
import { Input } from "@components/Input/Input";
import { Button } from "@components/Button/Button";
import { RadioButton } from "@components/RadioButton/RadioButton";
import { Checkbox } from "@components/Checkbox/Checkbox";
import { AdaptiveImageComponent } from "@components/AdaptiveImageComponent/AdaptiveImageComponent";
import { isDeviceResOrLower, useDeviceType } from "@hooks/useDeviceType";
import { Select } from "@components/Select/Select";
import { ContactListBlockComponent, } from "@components/Block/ContactListBlock/ContactListBlock";
import { DownloadBlock } from "@components/Block/DownloadBlock";
import { RenderProperty } from "@/src/views/RenderProperty";
import { convertPascalCaseToCamelCase } from "@basics/convertCases";
import { Accordion, AccordionItem } from "@components/Accordion/Accordion";
import { useDebounce } from "@hooks/useDebounce";
import { getCurrentLocale } from "@basics/locales";
import { ContactExpert } from "@components/ContactExpert/ContactExpert";
import { fonts } from "@basics/fonts";
import { DynamicDownloadBlock } from "@components/Block/DynamicDownloadBlock";
var PAGE_SIZE = 30;
export function getSearchData(page, language, query, pageType, sortBy, searchType) {
    var queryParameters = new URLSearchParams();
    queryParameters.set("q", query);
    language && queryParameters.set("language", language);
    queryParameters.set("page", page.toString());
    queryParameters.set("size", PAGE_SIZE.toString());
    queryParameters.set("page_type", Array.from(pageType).join(","));
    queryParameters.set("sort_by", sortBy);
    return fetch("/api/hdiglobal/search/".concat(searchType, "?").concat(queryParameters)).then(function (r) { return r.json(); });
}
var useStyles = createUseStyles({
    element: __assign(__assign({ paddingBottom: rem(spacings.l) }, onBreakpoint("sm", {
        paddingTop: rem(spacings.l),
        paddingBottom: rem(spacings.xl),
    })), onBreakpoint("md", {
        paddingTop: rem(spacings.xl),
        paddingBottom: rem(spacings.xxl),
    })),
    header: typography.h1,
    results: {
        marginTop: rem(spacings.sam),
    },
    resultsText: __assign(__assign({}, customTypography(typography.textLarge, { marginBottom: 0 }, { marginBottom: rem(spacings.m) })), { flex: 3 }),
    autocorrectionText: typography.textDefault,
    autocorrectionLink: {
        textDecoration: "underline",
    },
    contentRow: onBreakpoint("sm", {
        gridTemplateRows: "auto 1fr",
    }),
    searchBarColumn: {
        gridRow: 1,
        zIndex: 1,
    },
    articleCardsColumn: __assign({ gridRow: 3 }, onBreakpoint("sm", {
        gridRow: 2,
    })),
    filtersColumn: __assign({ zIndex: 1, gridRow: 2 }, onBreakpoint("sm", {
        gridRow: 1,
        gridRowEnd: "span 2",
    })),
    flexBar: {
        display: "flex",
        alignItems: "flex-end",
    },
    queryBar: __assign(__assign({}, onBefore("sm", { marginTop: rem(spacings.m) })), { display: "flex" }),
    filtersBackground: onBefore("sm", {
        zIndex: -1,
        position: "absolute",
        height: "100%",
        width: "100vw",
        backgroundColor: colors.gray10,
        marginLeft: -CONTAINER_OFFSET_WIDTHS["small"],
    }),
    moreButton: {
        display: "flex",
        justifyContent: "center",
        paddingTop: rem(spacings.m),
    },
    sortDropdown: __assign(__assign({ marginBottom: rem(spacings.sam) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.m),
    })), onBreakpoint("md", {
        flex: 1,
        marginBottom: rem(spacings.m),
    })),
    blockWrapper: __assign({ width: "calc(100% + ".concat(CONTAINER_OFFSET_WIDTHS["small"] * 2, "px)"), marginLeft: -CONTAINER_OFFSET_WIDTHS["small"] }, onBreakpoint("sm", {
        width: "calc(100% + ".concat(CONTAINER_OFFSET_WIDTHS["big"] * 2, "px)"),
        marginLeft: -CONTAINER_OFFSET_WIDTHS["big"],
    })),
});
export function SearchPage(props) {
    var _a, _b, _c, _d, _e;
    var initialQueryParametrs = new URLSearchParams(window.location.search);
    var _f = useContext(GlobalContentStoreContext), getStartPageData = _f.getStartPageData, getImmediateStartPageData = _f.getImmediateStartPageData, getWebsiteData = _f.getWebsiteData;
    var websiteData = getWebsiteData();
    var localizationCtx = useContext(LocalizationContext);
    var styles = useStyles();
    var _g = useState(getImmediateStartPageData()), startPageData = _g[0], setStartPageData = _g[1];
    var _h = useState(null), searchPageData = _h[0], setSearchPageData = _h[1];
    var _j = useState(null), searchContactData = _j[0], setSearchContactData = _j[1];
    var _k = useState(null), searchDownloadsData = _k[0], setSearchDownloadsData = _k[1];
    var _l = useState(false), isLoading = _l[0], setLoading = _l[1];
    var _m = useState(0), page = _m[0], setPage = _m[1];
    var deviceType = useDeviceType();
    var isMobile = isDeviceResOrLower(deviceType, "mobile");
    var isTabletOrMobile = isDeviceResOrLower(deviceType, "tablet");
    var CONTENT_TYPE_OPTIONS = [
        {
            label: localizedLabelString({
                section: "Search",
                label: "ContentTypePages",
                localizationCtx: localizationCtx,
            }),
            value: "pages",
        },
        {
            label: localizedLabelString({
                section: "Search",
                label: "ContentTypeContacts",
                localizationCtx: localizationCtx,
            }),
            value: "contacts",
        },
    ];
    var PAGE_TYPE_OPTIONS = [
        {
            label: localizedLabelString({
                section: "Search",
                label: "PageTypesContentPages",
                localizationCtx: localizationCtx,
            }),
            value: "content",
        },
        {
            label: localizedLabelString({
                section: "Search",
                label: "PageTypesProductPages",
                localizationCtx: localizationCtx,
            }),
            value: "product",
        },
        {
            label: localizedLabelString({
                section: "Search",
                label: "PageTypesArticlePages",
                localizationCtx: localizationCtx,
            }),
            value: "article",
        },
    ];
    var SORTBY_OPTIONS = [
        {
            label: localizedLabelString({
                section: "Search",
                label: "MostRelevant",
                localizationCtx: localizationCtx,
            }),
            value: "relevant",
        },
        {
            label: localizedLabelString({
                section: "Search",
                label: "MostRecent",
                localizationCtx: localizationCtx,
            }),
            value: "recent",
        },
    ];
    var _o = useState(initialQueryParametrs.get("contenttype") ||
        ((_a = CONTENT_TYPE_OPTIONS === null || CONTENT_TYPE_OPTIONS === void 0 ? void 0 : CONTENT_TYPE_OPTIONS[0]) === null || _a === void 0 ? void 0 : _a.value)), contentTypeSelection = _o[0], setContentTypeSelection = _o[1];
    var _p = useState(new Set(initialQueryParametrs.has("pagetypes")
        ? decodeURI(initialQueryParametrs.get("pagetypes") || "").split(",")
        : PAGE_TYPE_OPTIONS.map(function (pt) { return pt.value; }))), pageTypeSelection = _p[0], setPageTypeSelection = _p[1];
    var _q = useState(getCurrentLocale(websiteData, initialQueryParametrs)), localeSelection = _q[0], setLocaleSelection = _q[1];
    var _r = useState(initialQueryParametrs.get("sort") ||
        ((_b = SORTBY_OPTIONS === null || SORTBY_OPTIONS === void 0 ? void 0 : SORTBY_OPTIONS[0]) === null || _b === void 0 ? void 0 : _b.value)), sortType = _r[0], setSortType = _r[1];
    var _s = useState(decodeURI(initialQueryParametrs.get("query") || "")), query = _s[0], setQuery = _s[1];
    var queryInputRef = useRef(null);
    var results = searchPageData || searchContactData || searchDownloadsData;
    var debouncePromise = useDebounce().debouncePromise;
    function loadItemsPage(requestedPage) {
        var apiPage = requestedPage || page + 1;
        setLoading(true);
        return debouncePromise(function () {
            return getSearchData(apiPage, localeSelection !== "all" ? localeSelection : undefined, query, pageTypeSelection, sortType, contentTypeSelection).then(function (response) {
                setPage(apiPage);
                setLoading(false);
                return response;
            });
        });
    }
    function loadItems(page) {
        var append = !page;
        switch (contentTypeSelection) {
            case "pages": {
                return loadItemsPage(page).then(function (response) {
                    setSearchPageData(function (s) {
                        return s && append
                            ? __assign(__assign({}, s), { Hits: __spreadArray(__spreadArray([], (s.Hits || []), true), ((response === null || response === void 0 ? void 0 : response.Hits) || []), true) }) : response;
                    });
                });
            }
            case "contacts": {
                return loadItemsPage(page).then(function (response) {
                    var _a;
                    var Hits = ((_a = response === null || response === void 0 ? void 0 : response.Hits) === null || _a === void 0 ? void 0 : _a.map(convertPascalCaseToCamelCase)) || [];
                    setSearchContactData(function (s) {
                        return response && __assign(__assign({}, response), { Hits: append ? __spreadArray(__spreadArray([], ((s === null || s === void 0 ? void 0 : s.Hits) || []), true), Hits, true) : Hits });
                    });
                });
            }
            default: {
                return loadItemsPage(page).then(function (response) {
                    var _a;
                    var Hits = ((_a = response === null || response === void 0 ? void 0 : response.Hits) === null || _a === void 0 ? void 0 : _a.map(convertPascalCaseToCamelCase)) || [];
                    setSearchDownloadsData(function (s) {
                        return response && __assign(__assign({}, response), { Hits: append ? __spreadArray(__spreadArray([], ((s === null || s === void 0 ? void 0 : s.Hits) || []), true), Hits, true) : Hits });
                    });
                });
            }
        }
    }
    function searchFunction() {
        var _a;
        setQuery(((_a = queryInputRef.current) === null || _a === void 0 ? void 0 : _a.value) || "");
    }
    function clearQuery() {
        setQuery("");
    }
    useEffect(function () {
        !startPageData &&
            getStartPageData().then(function (response) {
                setStartPageData(response);
            });
    }, []);
    useEffect(function () {
        var queryParameters = new URLSearchParams();
        queryParameters.set("contenttype", contentTypeSelection);
        queryParameters.set("pagetypes", encodeURI(Array.from(pageTypeSelection.values()).join(",")));
        localeSelection && queryParameters.set("locale", localeSelection);
        queryParameters.set("query", encodeURI(query));
        queryParameters.set("sort", sortType);
        setSearchPageData(null);
        setSearchContactData(null);
        setSearchDownloadsData(null);
        history.replaceState({}, "", "".concat(window.location.pathname, "?").concat(queryParameters));
        loadItems(1);
    }, [
        startPageData,
        contentTypeSelection,
        pageTypeSelection,
        localeSelection,
        sortType,
        query,
    ]);
    if (!startPageData)
        return null;
    var isUserRetention = ((_c = props.data.contactCta) === null || _c === void 0 ? void 0 : _c.value) === "enabled";
    var contactExpertData = ((_d = props.data.contactsUseDefaultContent) === null || _d === void 0 ? void 0 : _d.value)
        ? startPageData
        : props.data;
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, __assign({}, startPageData, { hideSearch: true })),
        React.createElement("main", { "data-testid": "SearchPage" },
            React.createElement(Container, { className: styles.element },
                !isMobile && (React.createElement(Row, null,
                    React.createElement(Col, { sm: 7, md: 8 },
                        React.createElement("h1", { className: styles.header },
                            React.createElement(RenderProperty, { value: localizedLabelString({
                                    section: "Search",
                                    label: "Header",
                                    localizationCtx: localizationCtx,
                                }) }))))),
                React.createElement(Row, { className: styles.contentRow },
                    React.createElement(Col, { xs: 4, sm: 4, md: 3, className: styles.filtersColumn },
                        React.createElement("div", { className: styles.filtersBackground }),
                        React.createElement(FiltersElement, { contentTypeSelection: contentTypeSelection, setContentTypeSelection: function (value) {
                                return setContentTypeSelection(value);
                            }, pageTypeSelection: pageTypeSelection, setPageTypeSelection: function (changeFunc) {
                                return setPageTypeSelection(function (state) { return changeFunc(state); });
                            }, localeSelection: localeSelection, setLocaleSelection: setLocaleSelection, contentTypeOptions: CONTENT_TYPE_OPTIONS, pageTypeOptions: PAGE_TYPE_OPTIONS, localeOptions: props.data.existingLanguages || [], teaserContent: props.data.teaserContent, enableLocalFilter: (_e = props.data.enableLocalFilter) === null || _e === void 0 ? void 0 : _e.value })),
                    React.createElement(Col, { xs: 4, sm: 8, smStart: 4, md: 6, mdStart: 3, className: styles.searchBarColumn },
                        React.createElement("div", { className: styles.filtersBackground }),
                        React.createElement("div", { className: styles.queryBar },
                            React.createElement(Input, { label: localizedLabelString({
                                    section: "Search",
                                    label: "Search",
                                    localizationCtx: localizationCtx,
                                }), initialValue: query, inputRef: queryInputRef, onSubmit: searchFunction, clearQuery: clearQuery, theme: isMobile ? "white" : "gray" }),
                            React.createElement(Button, { type: "primary", onClick: searchFunction },
                                React.createElement(LocalizedLabel, { section: "Search", label: "Search" })))),
                    React.createElement(Col, { xs: 4, sm: 8, smStart: 4, md: 9, mdStart: 3, className: styles.articleCardsColumn },
                        React.createElement("div", { className: styles.results },
                            isLoading && React.createElement(Spinner, null),
                            results && (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: styles.flexBar },
                                    React.createElement("p", { className: styles.resultsText },
                                        React.createElement("strong", null, results === null || results === void 0 ? void 0 : results.TotalCount),
                                        "\u00A0",
                                        React.createElement(LocalizedLabel, { section: "Search", label: "Results" }),
                                        (results === null || results === void 0 ? void 0 : results.Filter.Q) && (React.createElement(React.Fragment, null,
                                            "\u00A0",
                                            React.createElement(LocalizedLabel, { section: "Search", label: "ForQuery" }),
                                            "\u00A0",
                                            React.createElement("strong", null,
                                                "\"", results === null || results === void 0 ? void 0 :
                                                results.Filter.Q,
                                                "\"")))),
                                    React.createElement(Select, { className: styles.sortDropdown, label: "Sort by", options: SORTBY_OPTIONS, selected: sortType, setSelected: function (props) {
                                            return setSortType(props);
                                        }, customTriggerIcon: "sort-amount-up", iconOnlyTrigger: isTabletOrMobile })),
                                (searchPageData === null || searchPageData === void 0 ? void 0 : searchPageData.Hits) &&
                                    contentTypeSelection === "pages" && (React.createElement(PageSearchResults, { pages: searchPageData.Hits })),
                                (searchContactData === null || searchContactData === void 0 ? void 0 : searchContactData.Hits) &&
                                    contentTypeSelection === "contacts" && (React.createElement("div", { className: styles.blockWrapper },
                                    React.createElement(ContactListBlockComponent, { contacts: searchContactData.Hits, isWide: true }))),
                                (searchDownloadsData === null || searchDownloadsData === void 0 ? void 0 : searchDownloadsData.Hits) &&
                                    contentTypeSelection === "documents" && (React.createElement("div", { className: styles.blockWrapper },
                                    React.createElement(DownloadBlock, { downloadItems: searchDownloadsData.Hits || [], disableFeaturedOption: true, isFullWidth: true }))))))),
                    results && results.TotalCount > page * PAGE_SIZE && (React.createElement(Col, { className: styles.moreButton }, isLoading ? (React.createElement(Spinner, null)) : (React.createElement(Button, { type: "secondary", onClick: function () { return loadItems(); } },
                        React.createElement(LocalizedLabel, { section: "Search", label: "LoadMore" }))))))),
            React.createElement(RenderProperty, { value: props.data.additionalContent }),
            React.createElement(DynamicDownloadBlock, { downloadCategoriesFilter: props.data.downloadCategoriesFilter, downloadsManual: props.data.downloadsManual }),
            isUserRetention && React.createElement(ContactExpert, __assign({}, contactExpertData))),
        React.createElement(Footer, __assign({}, startPageData))));
}
var useFiltersStyles = createUseStyles({
    mainElement: __assign({ marginBottom: rem(spacings.sam) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.m),
    })),
    teaserContentItem: {
        "& > div": __assign({ marginBottom: rem(spacings.sam) }, onBreakpoint("sm", {
            marginBottom: rem(spacings.m),
        })),
    },
});
function FiltersElement(props) {
    var localizationCtx = useContext(LocalizationContext);
    var deviceType = useDeviceType();
    var isMobile = isDeviceResOrLower(deviceType, "mobile");
    var styles = useFiltersStyles();
    return (React.createElement(React.Fragment, null,
        React.createElement(Accordion, { noOuterBorder: isMobile, className: styles.mainElement },
            React.createElement(AccordionItem, { headline: localizedLabelString({
                    section: "Search",
                    label: "ContentType",
                    localizationCtx: localizationCtx,
                }), initialIsActive: !isMobile }, props.contentTypeOptions.map(function (option) { return (React.createElement(RadioButton, { key: option.value, label: option.label, onCheck: function () { return props.setContentTypeSelection(option.value); }, checked: props.contentTypeSelection === option.value })); })),
            React.createElement(AccordionItem, { headline: localizedLabelString({
                    section: "Search",
                    label: "PageTypes",
                    localizationCtx: localizationCtx,
                }), initialIsActive: !isMobile }, props.pageTypeOptions.map(function (option) { return (React.createElement(Checkbox, { disabled: props.contentTypeSelection !== "pages", key: option.value, onCheck: function () {
                    return props.setPageTypeSelection(function (s) {
                        if (!s.has(option.value))
                            return new Set(s).add(option.value);
                        var newSet = new Set(s);
                        newSet.delete(option.value);
                        return newSet;
                    });
                }, checked: props.pageTypeSelection.has(option.value), label: option.label })); })),
            props.enableLocalFilter && (React.createElement(AccordionItem, { headline: localizedLabelString({
                    section: "Search",
                    label: "Locales",
                    localizationCtx: localizationCtx,
                }) }, props.localeOptions.map(function (option, idx) { return (React.createElement(RadioButton, { key: idx, label: option.displayName, onCheck: function () { return props.setLocaleSelection(option.name); }, checked: props.localeSelection === option.name })); })))),
        React.createElement(RenderProperty, { value: props.teaserContent, theme: "filters", className: styles.teaserContentItem })));
}
var usePagesStyles = createUseStyles({
    pagesList: __assign({}, onBreakpoint("md", {
        "& $pageItem:first-child": {
            borderTop: "none",
            paddingTop: 0,
        },
    })),
    pageItem: {
        display: "flex",
        padding: "".concat(spacings.sam, " 0"),
        borderTop: "2px solid ".concat(colors.gray20),
    },
    pageImage: {
        "& img": __assign(__assign({}, onBefore("sm", {
            paddingBottom: rem(spacings.sam),
        })), { height: "auto" }),
    },
    h4: typography.h4,
    caption: typography.caption,
    textDefault: __assign(__assign({}, typography.textDefault), fonts.book),
    fullWidth: {
        width: "100%",
    },
});
function PageSearchResults(props) {
    var styles = usePagesStyles();
    return (React.createElement("div", { className: styles.pagesList }, props.pages.map(function (page, idx) {
        var hasImage = page.ImageUrlLarge || page.ImageUrlMedium || page.ImageUrlSmall;
        return (React.createElement(Container, { key: idx, className: styles.pageItem, dataTestid: "SearchPageResultCard" },
            React.createElement(Row, { className: styles.fullWidth },
                hasImage && (React.createElement(Col, { sm: 4, md: 3 },
                    React.createElement("a", { href: page.Link },
                        React.createElement(AdaptiveImageComponent, { large: page.ImageUrlLarge, medium: page.ImageUrlMedium, small: page.ImageUrlSmall, alt: page.ImageAlt, className: styles.pageImage })))),
                React.createElement(Col, { sm: hasImage ? 8 : 12, md: hasImage ? 9 : 12 },
                    React.createElement("span", { className: styles.caption }, page.ParentTitle),
                    React.createElement("h4", { className: styles.h4 }, page.Title),
                    React.createElement("p", { className: styles.textDefault }, page.Description),
                    React.createElement(Button, { type: "link", href: page.Link },
                        React.createElement(LocalizedLabel, { section: "Global", label: "ReadMore" }))))));
    })));
}
