import cn from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";
import { rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { useStyles as useHeaderStyles } from "@components/Header/Header";
export var CloseButton = function (_a) {
    var onClick = _a.onClick;
    var hamburgerIcon = useHeaderStyles({
        openedMenu: true,
    }).hamburgerIcon;
    var useStyles = createUseStyles({
        button: {
            position: "absolute",
            top: rem(spacings.xs),
            right: rem(spacings.xs),
            border: "none",
            background: "white",
            "&:hover": {
                opacity: 1,
            },
            padding: rem(spacings.xxs),
            opacity: 0.5,
        },
    });
    var button = useStyles().button;
    var onClose = function () {
        onClick();
    };
    return (React.createElement("button", { className: button, onClick: onClose, "data-testid": "close-button" },
        React.createElement("div", { className: cn(hamburgerIcon), "aria-hidden": true },
            React.createElement("div", null),
            React.createElement("div", null),
            React.createElement("div", null),
            React.createElement("div", null))));
};
