var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Cloudinary } from "cloudinary-core";
import { useContext, useEffect, useRef } from "react";
import { CloudinaryPlayerContext } from "@/src/context/cloudinaryPlayerContext";
export var useCloudinaryPlayer = function (_a) {
    var cloudName = _a.cloudName, publicId = _a.publicId, inHeaderCarousel = _a.inHeaderCarousel, thumbnailUrl = _a.thumbnailUrl, logoImageUrl = _a.logoImageUrl, loop = _a.loop, autoplay = _a.autoplay, withOverlay = _a.withOverlay, resetVideo = _a.resetVideo;
    var setPlayer = useContext(CloudinaryPlayerContext).setPlayer;
    var videoPlayRef = useRef(null);
    var videoElementRef = useRef(null);
    useEffect(function () {
        if (!videoElementRef.current)
            return;
        if (videoElementRef.current.dataset["initialized"] === "true")
            return;
        var options = __assign(__assign({ publicId: publicId, controls: !inHeaderCarousel, fluid: true, posterOptions: thumbnailUrl
                ? {
                    publicId: thumbnailUrl,
                }
                : {
                    transformation: {
                        startOffset: "0",
                    },
                }, skin: "light", 
            // TODO Check if fixed after upgrade
            playbackRates: [0.75, 1, 1.5, 2], brandColors: {
                base: "#f5f5f5",
                accent: "#7aba00",
                text: "#262626",
            }, fontFace: "inherit", logoOnclickUrl: "https://www.hdi.global", logoImageUrl: logoImageUrl, showJumpControls: true, hideContextMenu: true, loop: !!loop }, (autoplay && !withOverlay
            ? {
                autoplay: true,
                muted: true,
                autoplayMode: inHeaderCarousel ? undefined : "on-scroll",
            }
            : {
                autoplay: false,
                muted: false,
                floatingWhenNotVisible: withOverlay ? "none" : "right",
            })), { 
            // WARNING(mkarol): Changes in analitics and usage reports require changes in privacy policy
            analytics: false, allowUsageReport: false, preload: "none" });
        var cld = Cloudinary.new({
            cloud_name: cloudName || "hdiglobal",
        });
        var cloudinaryPlayer = cld.videoPlayer(videoElementRef.current, options);
        if (!cloudinaryPlayer) {
            return;
        }
        if (resetVideo && !loop) {
            cloudinaryPlayer.on("ended", function () {
                cloudinaryPlayer.videojs.currentTime(0);
                cloudinaryPlayer.videojs.hasStarted(false);
                cloudinaryPlayer.videojs.triggerReady();
            });
        }
        videoPlayRef.current = cloudinaryPlayer;
        videoElementRef.current.dataset["initialized"] = "true";
        cloudinaryPlayer.on("play", function () {
            setPlayer(cloudinaryPlayer);
        });
    }, [
        autoplay,
        cloudName,
        inHeaderCarousel,
        logoImageUrl,
        loop,
        publicId,
        resetVideo,
        setPlayer,
        thumbnailUrl,
        videoElementRef,
        withOverlay,
    ]);
    return { videoPlayRef: videoPlayRef, videoElementRef: videoElementRef };
};
