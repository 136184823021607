var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { Col, Container, Row, rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { useBasicStyles } from "@basics/styles";
import { buttonStyles, checkboxStyles, commonStyles, containerWrapperStyles, datetimeStyles, descriptionStyles, dividerStyles, errorStyles, inputStyles, radioButtonStyles, selectStyles, subscriptionCenterStyles, successStyles, textAreaStyles, textStyles, } from "./styles";
import { interests, newsletters, subscriptionItems } from "./utils";
export var useStyles = createUseStyles({
    msDynamicsForm: __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, containerWrapperStyles), inputStyles), selectStyles), buttonStyles), checkboxStyles), radioButtonStyles), textAreaStyles), descriptionStyles), datetimeStyles), errorStyles), successStyles), dividerStyles), textStyles), { "& form": {
            display: "flex",
            flexDirection: "column",
            gap: rem(spacings.m),
            "& > div": {
                display: "flex",
                flexDirection: "column",
                gap: rem(spacings.m),
            },
        }, "& .lp-form-field": __assign(__assign({}, commonStyles), { 
            // valid both for checkbox and radio button
            "& > span": {
                display: "flex",
                flexDirection: "column",
                gap: rem(spacings.s),
            } }), "& .marketing-subscription-list": __assign(__assign({}, commonStyles), { flexDirection: "row", position: "relative", width: "fit-content" }), "& .lp-checkboxListItem": commonStyles, "& .lp-radioButton": commonStyles, 
        // Labels are not displayed because placeholders are used instead
        "& label": {
            display: "none",
        }, "& #validation-summary": {
            display: "none",
        } }), subscriptionCenterStyles),
});
export var useMsDynamicsForm = function () {
    var _a = useState(null), loader = _a[0], setLoader = _a[1];
    var getScriptFor = function (name) {
        var loaderPromise = new Promise(function (resolve, reject) {
            var loaderScript = document.createElement("script");
            loaderScript.src = "https://mktdplp102cdn.azureedge.net/public/latest/js/".concat(name, ".js?v=1.84.2007");
            loaderScript.onload = function () { return resolve(true); };
            loaderScript.onerror = function () { return reject(); };
            var scriptToBeLoaded = document.querySelector("script[src=\"".concat(loaderScript.src, "\"]"));
            if (window.MsCrmMkt && scriptToBeLoaded) {
                resolve(true);
            }
            else if (!window.MsCrmMkt && scriptToBeLoaded) {
                scriptToBeLoaded.addEventListener("load", function () {
                    resolve(true);
                });
            }
            else {
                document.body.appendChild(loaderScript);
            }
        });
        return loaderPromise;
    };
    useEffect(function () {
        var load = function () { return __awaiter(void 0, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, getScriptFor("form-loader")];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, getScriptFor("ws-tracking")];
                    case 2:
                        _b.sent();
                        setLoader(window.MsCrmMkt);
                        return [3 /*break*/, 4];
                    case 3:
                        _a = _b.sent();
                        console.error("Fail to load form");
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        load();
    }, []);
    return loader;
};
export var MsDynamicsConnectorBlock = function (props) {
    var _a, _b, _c, _d;
    var style = useStyles();
    var componentMargin = useBasicStyles().componentMargin;
    var formLoader = useMsDynamicsForm();
    var msDynamicsRef = useRef(null);
    var removeInlineStyle = function () {
        var scope = msDynamicsRef.current;
        scope === null || scope === void 0 ? void 0 : scope.querySelectorAll("*:not(span)").forEach(function (element) { return element.removeAttribute("style"); });
        scope === null || scope === void 0 ? void 0 : scope.querySelectorAll("style").forEach(function (element) { return element.remove(); });
        document
            .querySelectorAll("style#cssProviderStyle")
            .forEach(function (element) { return element.remove(); });
    };
    var enhanceClasses = function () {
        var scope = msDynamicsRef.current;
        scope === null || scope === void 0 ? void 0 : scope.querySelectorAll("div[data-editorblocktype=SubscriptionListBlock]").forEach(function (element) {
            var _a, _b, _c, _d, _e;
            var id = (_a = element.querySelector("input")) === null || _a === void 0 ? void 0 : _a.id;
            if (id && subscriptionItems[id]) {
                (_b = element.parentElement) === null || _b === void 0 ? void 0 : _b.classList.add("subscriptionListWithIcon");
                (_c = element.closest("form")) === null || _c === void 0 ? void 0 : _c.classList.add("subscriptionCenter");
            }
            else if (id && interests.includes(id)) {
                (_d = element.parentElement) === null || _d === void 0 ? void 0 : _d.classList.add("marketingPreferences");
            }
            else if (id && newsletters.includes(id)) {
                (_e = element.parentElement) === null || _e === void 0 ? void 0 : _e.classList.add("newsletters");
            }
        });
        scope === null || scope === void 0 ? void 0 : scope.querySelectorAll("span").forEach(function (element) {
            var fontSize = element.style.getPropertyValue("font-size");
            if (fontSize) {
                return element.setAttribute("style", "font-size: ".concat(fontSize));
            }
            else {
                return element.removeAttribute("style");
            }
        });
    };
    useEffect(function () {
        formLoader === null || formLoader === void 0 ? void 0 : formLoader.MsCrmFormLoader.on("afterFormLoad", function () {
            removeInlineStyle();
            enhanceClasses();
        });
        removeInlineStyle();
        enhanceClasses();
        // Mutation observer is needed because in case of error thrown by the form
        // styles are injected again
        var observer = new MutationObserver(removeInlineStyle);
        var config = { attributes: false, childList: true, subtree: true };
        if (msDynamicsRef.current) {
            observer.observe(msDynamicsRef.current, config);
        }
        return function () {
            formLoader === null || formLoader === void 0 ? void 0 : formLoader.MsCrmFormLoader.off("afterFormLoad");
            observer.disconnect();
        };
    }, [formLoader]);
    return (React.createElement(Container, null,
        React.createElement("div", { className: componentMargin },
            React.createElement(Row, null,
                React.createElement(Col, { sm: 10, smStart: 1, md: 8, mdStart: 2 },
                    React.createElement("div", { id: "ms-dynamics", className: style.msDynamicsForm, ref: msDynamicsRef },
                        React.createElement("div", { "data-form-block-id": (_a = props.data.formBlockId) === null || _a === void 0 ? void 0 : _a.value }),
                        React.createElement("div", { id: ((_b = props.data.formId) === null || _b === void 0 ? void 0 : _b.value) || "" }),
                        React.createElement("div", { className: "d365-mkt-config", style: { display: "none" }, "data-website-id": (_c = props.data.websiteId) === null || _c === void 0 ? void 0 : _c.value, "data-hostname": (_d = props.data.hostname) === null || _d === void 0 ? void 0 : _d.value }),
                        props.children))))));
};
