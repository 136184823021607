var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var iconUnicodes = {
    cars: "f85b",
    ship: "f21a",
    "shield-check": "f2f7",
    "industry-alt": "f3b3",
    tools: "f7d9",
    "phone-laptop": "f87a",
    "money-check-edit": "f872",
    "chart-user": "f6a3",
    "briefcase-medical": "f469",
    umbrella: "f0e9",
    "hospital-user": "f80d",
    "street-view": "f21d",
    "pen-fancy": "f5ac",
    "file-user": "f65c",
    "lightbulb-on": "f672",
    globe: "f0ac",
    coins: "f51e",
    "chart-network": "f78a",
    link: "f0c1",
    "car-building": "f859",
    "plane-departure": "f5b0",
    vial: "f492",
    "wind-turbine": "f89b",
    database: "f1c0",
    pills: "f484",
    train: "f238",
    water: "f773",
    "hand-holding-seedling": "f4bf",
    forklift: "f47a",
    digging: "f85e",
    "user-friends": "f500",
    "house-flood": "f74f",
};
var subscriptionItemsDEV = {
    "988a8e72-795e-ed11-9561-6045bd8c557b": iconUnicodes["industry-alt"],
    "21887483-705f-ed11-9561-6045bd8c528e": iconUnicodes.cars,
    "27836b7d-705f-ed11-9561-6045bd8c528e": iconUnicodes.ship,
    "011f6077-705f-ed11-9561-6045bd8c528e": iconUnicodes["shield-check"],
    "a3826b7d-705f-ed11-9561-6045bd8c528e": iconUnicodes.tools,
    "667869f6-0c64-ed11-9561-6045bd8c56af": iconUnicodes["phone-laptop"],
    "6e9735f0-0c64-ed11-9561-6045bd8c56af": iconUnicodes["money-check-edit"],
    "55f06502-0d64-ed11-9561-6045bd8c56af": iconUnicodes["chart-user"],
    "acf75a27-0d64-ed11-9561-6045bd8c56af": iconUnicodes["briefcase-medical"],
    "23ad7e3a-0d64-ed11-9561-6045bd8c56af": iconUnicodes.umbrella,
    "35c4a346-0d64-ed11-9561-6045bd8c56af": iconUnicodes["street-view"],
    "73fd3471-68cc-ed11-b597-6045bd8c5672": iconUnicodes["car-building"],
    "f0189f7d-68cc-ed11-b597-6045bd8c5672": iconUnicodes["plane-departure"],
    "a1058f8f-68cc-ed11-b597-6045bd8c5672": iconUnicodes.vial,
    "518374a2-68cc-ed11-b597-6045bd8c5672": iconUnicodes.database,
    "f731b0e8-6acc-ed11-b597-6045bd8c5672": iconUnicodes.pills,
    "f3a3ceee-6acc-ed11-b597-6045bd8c5672": iconUnicodes.train,
    "ac83f8fa-6acc-ed11-b597-6045bd8c5672": iconUnicodes["wind-turbine"],
    "86c6d207-6bcc-ed11-b597-6045bd8c5672": iconUnicodes.water,
    "8693b495-68cc-ed11-b597-6045bd8c5672": iconUnicodes["file-user"],
    "86c6bd50-34fa-ed11-8849-6045bd8c5c03": iconUnicodes["wind-turbine"],
    "81b70bb2-34fa-ed11-8849-6045bd8c5c03": iconUnicodes["hospital-user"],
    "6b1260c0-aa83-ee11-8179-6045bd8c56af": iconUnicodes["hand-holding-seedling"],
    "34ee7f02-ab83-ee11-8179-6045bd8c56af": iconUnicodes.forklift,
    "12410333-ab83-ee11-8179-6045bd8c56af": iconUnicodes.digging,
    "e275df75-ab83-ee11-8179-6045bd8c56af": iconUnicodes["user-friends"],
    "34041a9a-ab83-ee11-8179-6045bd8c56af": iconUnicodes["house-flood"],
};
var subscriptionItemsPROD = {
    "8744f8df-37b1-ed11-83ff-6045bd8c5612": iconUnicodes["industry-alt"],
    "7e8ff0d2-37b1-ed11-83ff-6045bd8c5612": iconUnicodes["shield-check"],
    "12ddd0c0-37b1-ed11-83ff-6045bd8c5612": iconUnicodes.tools,
    "13e1b3b3-37b1-ed11-83ff-6045bd8c5612": iconUnicodes.ship,
    "d245aa8d-37b1-ed11-83ff-6045bd8c5612": iconUnicodes["phone-laptop"],
    "4095e799-37b1-ed11-83ff-6045bd8c5612": iconUnicodes["money-check-edit"],
    "0b92297a-37b1-ed11-83ff-6045bd8c5612": iconUnicodes["briefcase-medical"],
    "954e1b67-37b1-ed11-83ff-6045bd8c5612": iconUnicodes.umbrella,
    "9de0904e-37b1-ed11-83ff-6045bd8c5612": iconUnicodes["street-view"],
    "5474545c-6400-ee11-8f6e-000d3abe446a": iconUnicodes["hospital-user"],
    "94f30a87-37b1-ed11-83ff-6045bd8c5612": iconUnicodes["chart-user"],
    "bdab46a3-f8c6-ed11-b597-6045bd8c5672": iconUnicodes["car-building"],
    "e938d7b1-f8c6-ed11-b597-00224887def8": iconUnicodes["plane-departure"],
    "077558bf-f8c6-ed11-b597-00224887def8": iconUnicodes.vial,
    "0296a8e6-f8c6-ed11-b597-00224887def8": iconUnicodes["file-user"],
    "7d476af4-f8c6-ed11-b597-00224887def8": iconUnicodes.database,
    "9f10102d-f9c6-ed11-b597-6045bd8c5405": iconUnicodes["wind-turbine"],
    "86baee57-f9c6-ed11-b597-6045bd8c5405": iconUnicodes.water,
    "a9796ea2-6700-ee11-8f6e-000d3abe446a": iconUnicodes["wind-turbine"],
    "728b3101-f9c6-ed11-b597-00224887def8": iconUnicodes.pills,
    "2c93810d-f9c6-ed11-b597-00224887def8": iconUnicodes.train,
    "f379cf05-b17c-ee11-8179-6045bd8c52cf": iconUnicodes["hand-holding-seedling"],
    "f50bfe11-b17c-ee11-8179-6045bd8c52cf": iconUnicodes.forklift,
    "c818862a-b17c-ee11-8179-6045bd8c52cf": iconUnicodes.digging,
    "db010582-af7c-ee11-8179-6045bd8c5612": iconUnicodes["user-friends"],
    "61101b10-b07c-ee11-8179-6045bd8c5612": iconUnicodes["house-flood"],
};
export var subscriptionItems = __assign(__assign({}, subscriptionItemsDEV), subscriptionItemsPROD);
var interestsDEV = [
    "52177ffd-34fa-ed11-8849-6045bd8c5c03",
    "1e8df430-35fa-ed11-8849-6045bd8c5c03",
    "a71d0aa8-33fa-ed11-8849-6045bd8c5c03",
    "504114e0-33fa-ed11-8849-6045bd8c5c03",
    "eb22b36a-35fa-ed11-8849-6045bd8c5c03",
    "7defe827-34fa-ed11-8849-6045bd8c5c03",
    "67bb4957-ab83-ee11-8179-6045bd8c56af",
];
var interestsPROD = [
    "a731eaf4-6500-ee11-8f6e-000d3abe446a",
    "0396dd62-6600-ee11-8f6e-000d3abe446a",
    "5635a7f0-6600-ee11-8f6e-000d3abe446a",
    "28917413-6600-ee11-8f6e-000d3abe446a",
    "4075aa1f-6600-ee11-8f6e-000d3abe446a",
    "2ee4ca2b-6600-ee11-8f6e-000d3abe446a",
    "1b7af237-6600-ee11-8f6e-000d3abe446a",
    "b6895f94-b07c-ee11-8179-6045bd8c52cf",
];
export var interests = interestsDEV.concat(interestsPROD);
var newslettersDEV = [
    "024433ff-37b1-ed11-83ff-6045bd8c5612",
    "8937ec59-795e-ed11-9561-6045bd8c557b",
    "f6bb3c60-795e-ed11-9561-6045bd8c557b",
    "043e966c-795e-ed11-9561-6045bd8c557b",
    "ca083566-795e-ed11-9561-6045bd8c557b",
];
var newslettersPROD = [
    "bb3f1506-38b1-ed11-83ff-6045bd8c5612",
    "024433ff-37b1-ed11-83ff-6045bd8c5612",
];
export var newsletters = newslettersDEV.concat(newslettersPROD);
