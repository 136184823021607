import cn from "classnames";
import React from "react";
import { LocalizedLabel } from "@hooks/LocalizationContext";
import { RenderProperty, ShowPropertyOnEdit } from "@/src/views/RenderProperty";
import { Button, isExternal } from "@components/Button/Button";
import { FAIcon } from "@components/FAIcon/FAIcon";
import { useLocationCardStyles } from "./LocationCardStyles";
import { formattedPhoneNumber } from "./PersonCard";
export function LocationCardOverlay(props) {
    var _a, _b, _c, _d;
    var styles = useLocationCardStyles();
    var locationName = props.locationName, photo = props.photo, role = props.role, address = props.address, email = props.email, phoneNumber = props.phoneNumber, faxNumber = props.faxNumber, website = props.website, startPageData = props.startPageData;
    var websiteLink = (_a = website.value) === null || _a === void 0 ? void 0 : _a[0];
    var cardPhoto = ((_b = photo.value) === null || _b === void 0 ? void 0 : _b.urlSmall) && ((_c = photo.value) === null || _c === void 0 ? void 0 : _c.urlMedium) && ((_d = photo.value) === null || _d === void 0 ? void 0 : _d.urlLarge)
        ? photo
        : startPageData === null || startPageData === void 0 ? void 0 : startPageData.contactLocationImageFallback;
    return (React.createElement("article", { className: styles.contact, "data-testid": "ContactCardOverlay" },
        React.createElement("div", { className: styles.bioSection },
            React.createElement("div", { className: styles.nameWrapper },
                React.createElement("h4", { className: styles.name },
                    React.createElement(RenderProperty, { value: locationName })),
                ShowPropertyOnEdit(role) && (React.createElement(RenderProperty, { value: role, className: styles.role }))),
            React.createElement(RenderProperty, { value: cardPhoto, className: styles.photo })),
        React.createElement("div", { className: cn(styles.infoSection, styles.infoSectionOverlay, styles.infoWrapper) },
            React.createElement("div", { className: cn(styles.address, styles.addressOverlay) },
                React.createElement(RenderProperty, { value: address })),
            React.createElement("div", { className: styles.contacts },
                React.createElement("div", { className: styles.contactLabels },
                    phoneNumber.value && React.createElement("span", null, "T"),
                    faxNumber.value && React.createElement("span", null, "F"),
                    email.value && React.createElement("span", null, "E"),
                    websiteLink && React.createElement("span", { className: styles.websiteAddress }, "W")),
                React.createElement("div", { className: cn(styles.contactNumbers, styles.contactNumbersOverlay) },
                    phoneNumber.value && (React.createElement("a", { href: "tel: ".concat(formattedPhoneNumber(phoneNumber.value)) },
                        React.createElement("span", { className: styles.accessibilityText },
                            "Call to a phone number ",
                            phoneNumber.value),
                        React.createElement(RenderProperty, { value: phoneNumber }))),
                    ShowPropertyOnEdit(faxNumber) && (React.createElement("a", { href: "fax: ".concat(faxNumber.value) },
                        React.createElement("span", { className: styles.accessibilityText },
                            "Fax to a number ",
                            faxNumber.value),
                        React.createElement(RenderProperty, { value: faxNumber }))),
                    React.createElement("a", { href: "mailto:".concat(email.value) },
                        React.createElement("span", { className: styles.accessibilityText },
                            "Write an email to ",
                            email.value),
                        React.createElement(RenderProperty, { value: email })),
                    websiteLink && (React.createElement("a", { href: websiteLink.href, className: styles.websiteAddress },
                        React.createElement("span", { className: styles.accessibilityText },
                            "Open a website ",
                            websiteLink.href),
                        React.createElement(RenderProperty, { value: website }))))),
            React.createElement("div", { className: styles.ctaButtons },
                websiteLink && (React.createElement(Button, { href: websiteLink.href, type: isExternal(websiteLink.href) ? "secondary" : "primary", target: isExternal(websiteLink.href) ? "_blank" : "_self" },
                    React.createElement("span", { className: styles.accessibilityText },
                        "Open a website ",
                        websiteLink.href),
                    isExternal(websiteLink.href) ? (React.createElement(LocalizedLabel, { section: "Contact", label: "VisitWebsite" })) : (React.createElement(LocalizedLabel, { section: "Contact", label: "ShowDetails" })))),
                React.createElement("div", { className: styles.iconWrapperMobile },
                    React.createElement("a", { href: "mailto:".concat(email.value) },
                        React.createElement("span", { className: styles.accessibilityText },
                            "Write an email to ",
                            email.value),
                        React.createElement(FAIcon, { icon: "envelope", className: styles.icon })),
                    phoneNumber.value && (React.createElement("a", { href: "tel: ".concat(formattedPhoneNumber(phoneNumber.value)) },
                        React.createElement("span", { className: styles.accessibilityText },
                            "Call to a phone number ",
                            phoneNumber.value),
                        React.createElement(FAIcon, { icon: "phone", className: styles.icon }))),
                    React.createElement("button", { className: styles.primaryCta, onClick: props.openFormMobile },
                        React.createElement("span", { className: styles.accessibilityText }, "Contact via form"),
                        React.createElement(FAIcon, { icon: "comments", className: styles.icon })))))));
}
