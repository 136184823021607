import { useState } from "react";
export function useBlurred() {
    var _a = useState(new Set()), blurred = _a[0], setBlurred = _a[1];
    var _b = useState(false), forced = _b[0], setForced = _b[1];
    function setFieldBlurred(guid) {
        setBlurred(function (s) { return new Set(s).add(guid); });
    }
    function isFieldBlurred(guid) {
        return forced || blurred.has(guid);
    }
    function clearAllBlurred() {
        setBlurred(function (_) { return new Set(); });
        setForced(function (_) { return false; });
    }
    function setAllBlurred() {
        setBlurred(function (_) { return new Set(); });
        setForced(function (_) { return true; });
    }
    return {
        setFieldBlurred: setFieldBlurred,
        isFieldBlurred: isFieldBlurred,
        clearAllBlurred: clearAllBlurred,
        setAllBlurred: setAllBlurred,
    };
}
