import React from "react";
import { createUseStyles } from "react-jss";
var useStyles = createUseStyles({
    picture: {
        height: "100%",
        width: "100%",
        display: "block",
    },
    image: {
        height: "100%",
        width: "100%",
        display: "block",
    },
});
export function AdaptiveImageComponent(props) {
    var styles = useStyles();
    var urlDefault = props.large || props.medium || props.small || undefined;
    var minImageSize = 1440;
    var imageSizes = [
        1920,
        Math.floor((minImageSize * 4) / 9),
        Math.floor((minImageSize * 2) / 9),
        Math.floor((minImageSize * 1) / 9),
    ];
    return urlDefault ? (React.createElement("div", { className: props.className },
        React.createElement("picture", { className: styles.picture },
            React.createElement("img", { src: props.small || urlDefault, srcSet: "".concat(props.fullHD || urlDefault, " ").concat(imageSizes[0], "w, ").concat(props.large || urlDefault, " ").concat(imageSizes[1], "w,\n            ").concat(props.medium || urlDefault, " ").concat(imageSizes[2], "w,\n            ").concat(props.small || urlDefault, " ").concat(imageSizes[3], "w"), alt: props.alt || "", className: styles.image, loading: "lazy" })))) : null;
}
