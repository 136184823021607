import cn from "classnames";
import React from "react";
import { RenderProperty, ShowPropertyOnEdit } from "@/src/views/RenderProperty";
import { FAIcon } from "@components/FAIcon/FAIcon";
import { formattedPhoneNumber } from "./PersonCard";
import { personCardStyles } from "./styles";
export var GenericCardOverlay = function (props) {
    var _a, _b, _c;
    var styles = personCardStyles();
    var title = props.title, subTitle = props.subTitle, photo = props.photo, phoneNumber = props.phoneNumber, email = props.email, faxNumber = props.faxNumber, description = props.description, startPageData = props.startPageData;
    var cardPhoto = ((_a = photo.value) === null || _a === void 0 ? void 0 : _a.urlSmall) && ((_b = photo.value) === null || _b === void 0 ? void 0 : _b.urlMedium) && ((_c = photo.value) === null || _c === void 0 ? void 0 : _c.urlLarge)
        ? photo
        : startPageData === null || startPageData === void 0 ? void 0 : startPageData.contactPersonImageFallback;
    return (React.createElement("article", { className: styles.contact, "data-testid": "ContactCardOverlay" },
        React.createElement("div", { className: styles.bioSection },
            React.createElement("h4", { className: styles.name }, title && React.createElement(RenderProperty, { value: title })),
            cardPhoto && (React.createElement(RenderProperty, { value: cardPhoto, className: styles.photo }))),
        React.createElement("div", { className: styles.infoSection },
            subTitle && (React.createElement(RenderProperty, { value: subTitle, className: styles.defaultText })),
            description && (React.createElement(RenderProperty, { value: description, className: styles.defaultText })),
            React.createElement("div", { className: styles.contacts },
                React.createElement("div", { className: styles.contactLabels },
                    (phoneNumber === null || phoneNumber === void 0 ? void 0 : phoneNumber.value) && React.createElement("span", null, "T"),
                    faxNumber.value && React.createElement("span", null, "F"),
                    email.value && React.createElement("span", null, "E")),
                React.createElement("div", { className: styles.contactNumbers },
                    phoneNumber.value && (React.createElement("a", { href: "tel: ".concat(formattedPhoneNumber(phoneNumber.value)) },
                        React.createElement("span", { className: styles.accessibilityText },
                            "Call to a number ",
                            phoneNumber.value),
                        React.createElement(RenderProperty, { value: phoneNumber }))),
                    ShowPropertyOnEdit(faxNumber) && (React.createElement("a", { href: "fax: ".concat(faxNumber.value) },
                        React.createElement("span", { className: styles.accessibilityText },
                            "Fax to a number ",
                            faxNumber.value),
                        React.createElement(RenderProperty, { value: faxNumber }))),
                    React.createElement("a", { href: "mailto:".concat(email.value) },
                        React.createElement("span", { className: styles.accessibilityText },
                            "Write an email to ",
                            email.value),
                        React.createElement(RenderProperty, { value: email })))),
            React.createElement("div", { className: cn(styles.ctaButtons, styles.ctaButtonsOverlay) },
                React.createElement("div", { className: styles.iconWrapperMobile },
                    React.createElement("a", { href: "mailto:".concat(email.value) },
                        React.createElement("span", { className: styles.accessibilityText },
                            "Write an email to ",
                            email.value),
                        React.createElement(FAIcon, { icon: "envelope", className: styles.icon })),
                    phoneNumber.value && (React.createElement("a", { href: "tel: ".concat(formattedPhoneNumber(phoneNumber.value)) },
                        React.createElement("span", { className: styles.accessibilityText },
                            "Call to a phone number ",
                            phoneNumber.value),
                        React.createElement(FAIcon, { icon: "phone", className: styles.icon }))),
                    React.createElement("button", { className: styles.primaryCta, onClick: props.openFormMobile },
                        React.createElement("span", { className: styles.accessibilityText }, "Contact via form"),
                        React.createElement(FAIcon, { icon: "comments", className: styles.icon })))))));
};
