var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext } from "react";
import { createUseStyles } from "react-jss";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
import { rem } from "@basics/layout";
import { customTypography, typography } from "@basics/typography";
import { spacings } from "@basics/spacings";
import cn from "classnames";
import { FAIcon, FAIconStyles } from "@components/FAIcon/FAIcon";
var useStyles = createUseStyles({
    breadcrumbs: {
        padding: 0,
        marginTop: 0,
        marginBottom: rem(spacings.s),
        display: "flex",
        flexWrap: "wrap",
        "& li": {
            display: "flex",
            alignItems: "center",
            listStyle: "none",
            marginBottom: rem(spacings.s),
            "&:last-child": {
                "& svg": {
                    display: "none",
                },
            },
            "& a": __assign(__assign({}, customTypography(__assign(__assign({}, typography.textDefault), { marginBottom: 0 }), {}, {
                marginBottom: 0,
            })), { "&:hover": {
                    textDecoration: "underline",
                } }),
            "& svg": {
                margin: "0 ".concat(spacings.s),
            },
        },
    },
    icon: FAIconStyles({
        width: 24,
        height: 24,
    }),
});
export function BreadcrumbComponent(props) {
    var getBreadcrumbs = useContext(GlobalContentStoreContext).getBreadcrumbs;
    var styles = useStyles();
    var breadcrumbs = getBreadcrumbs();
    return breadcrumbs ? (React.createElement("ul", { className: cn(styles.breadcrumbs, props.className) }, breadcrumbs.items.map(function (b, idx) { return (React.createElement("li", { key: idx },
        React.createElement("a", { href: b.url }, b.label),
        React.createElement(FAIcon, { icon: "long-arrow-right", className: styles.icon }))); }))) : null;
}
