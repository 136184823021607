var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { typography } from "@basics/typography";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
import { AnimatedRenderProperty } from "@/src/views/RenderProperty";
import { CloudinaryVideoPlayer } from "@components/CloudinaryVideoPlayer/CloudinaryVideoPlayer";
import { SemanticHeader } from "@components/SemanticHeader/SematicHeader";
var useStyles = createUseStyles({
    textContainer: {
        marginBottom: rem(spacings.xl),
    },
    headline: __assign(__assign({}, typography.h2), { textAlign: "center" }),
    description: __assign(__assign({}, typography.textDefault), { textAlign: "center" }),
});
export var VideoComponent = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var _j = props.data, headline = _j.headline, descriptionRichText = _j.descriptionRichText, headerSize = _j.headerSize;
    var styles = useStyles();
    var _k = useContext(GlobalContentStoreContext), getStartPageData = _k.getStartPageData, getImmediateStartPageData = _k.getImmediateStartPageData;
    var _l = useState(getImmediateStartPageData()), startPageData = _l[0], setStartPageData = _l[1];
    var isAutoplay = ((_a = props.data.startAction) === null || _a === void 0 ? void 0 : _a.value) === "autoplay";
    var isLoop = ((_b = props.data.endAction) === null || _b === void 0 ? void 0 : _b.value) === "loop_video";
    var resetOnEnd = ((_c = props.data.endAction) === null || _c === void 0 ? void 0 : _c.value) === "reset_to_thumbnail";
    var defaultPosterUrl = ((_d = props.data.thumbnail.value) === null || _d === void 0 ? void 0 : _d.urlLarge) ||
        ((_e = props.data.thumbnail.value) === null || _e === void 0 ? void 0 : _e.urlMedium) ||
        ((_f = props.data.thumbnail.value) === null || _f === void 0 ? void 0 : _f.urlSmall);
    useEffect(function () {
        !startPageData &&
            getStartPageData().then(function (response) {
                setStartPageData(response);
            });
    }, []);
    if (!startPageData)
        return null;
    return (React.createElement("div", null,
        ((headline === null || headline === void 0 ? void 0 : headline.value) || descriptionRichText.value) && (React.createElement("div", { className: styles.textContainer },
            React.createElement(SemanticHeader, { className: styles.headline, headerSize: headerSize === null || headerSize === void 0 ? void 0 : headerSize.value },
                React.createElement(AnimatedRenderProperty, { value: headline })),
            React.createElement(AnimatedRenderProperty, { value: descriptionRichText, className: styles.description }))),
        React.createElement(CloudinaryVideoPlayer, { publicId: ((_g = props.data.cloudinaryVideoPublicID) === null || _g === void 0 ? void 0 : _g.value) || "", logoImageUrl: ((_h = startPageData.logo.value) === null || _h === void 0 ? void 0 : _h.url) || "", autoplay: isAutoplay, loop: isLoop, resetVideo: resetOnEnd, showControls: Boolean(props.data.showControls.value), thumbnailUrl: defaultPosterUrl || undefined })));
};
