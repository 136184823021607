import cn from "classnames";
import React from "react";
import { RenderProperty, ShowPropertyOnEdit } from "@/src/views/RenderProperty";
import { FAIcon } from "@components/FAIcon/FAIcon";
import { formattedPhoneNumber } from "./PersonCard";
import { personCardStyles } from "./styles";
export var PersonCardOverlay = function (props) {
    var _a, _b, _c;
    var styles = personCardStyles();
    var firstName = props.firstName, lastName = props.lastName, photo = props.photo, role = props.role, jobTitle = props.jobTitle, phoneNumber = props.phoneNumber, mobileNumber = props.mobileNumber, email = props.email, faxNumber = props.faxNumber, linkedInProfile = props.linkedInProfile, description = props.description, startPageData = props.startPageData;
    var cardPhoto = ((_a = photo.value) === null || _a === void 0 ? void 0 : _a.urlSmall) && ((_b = photo.value) === null || _b === void 0 ? void 0 : _b.urlMedium) && ((_c = photo.value) === null || _c === void 0 ? void 0 : _c.urlLarge)
        ? photo
        : startPageData === null || startPageData === void 0 ? void 0 : startPageData.contactPersonImageFallback;
    return (React.createElement("article", { className: styles.contact, "data-testid": "ContactCardOverlay" },
        React.createElement("div", { className: styles.bioSection },
            React.createElement("h4", { className: styles.name },
                firstName && React.createElement(RenderProperty, { value: firstName }),
                " ",
                lastName && React.createElement(RenderProperty, { value: lastName })),
            React.createElement(RenderProperty, { value: cardPhoto, className: styles.photo })),
        React.createElement("div", { className: styles.infoSection },
            jobTitle && (React.createElement(RenderProperty, { value: jobTitle, className: styles.defaultText })),
            role && React.createElement(RenderProperty, { value: role, className: styles.role }),
            description && (React.createElement(RenderProperty, { value: description, className: styles.defaultText })),
            React.createElement("div", { className: styles.contacts },
                React.createElement("div", { className: styles.contactLabels },
                    (phoneNumber === null || phoneNumber === void 0 ? void 0 : phoneNumber.value) && React.createElement("span", null, "T"),
                    (mobileNumber === null || mobileNumber === void 0 ? void 0 : mobileNumber.value) && React.createElement("span", null, "M"),
                    faxNumber.value && React.createElement("span", null, "F"),
                    email.value && React.createElement("span", null, "E")),
                React.createElement("div", { className: styles.contactNumbers },
                    phoneNumber.value && (React.createElement("a", { href: "tel: ".concat(formattedPhoneNumber(phoneNumber.value)) },
                        React.createElement("span", { className: styles.accessibilityText },
                            "Call to a number ",
                            phoneNumber.value),
                        React.createElement(RenderProperty, { value: phoneNumber }))),
                    ShowPropertyOnEdit(mobileNumber) && mobileNumber.value && (React.createElement("a", { href: "tel: ".concat(formattedPhoneNumber(mobileNumber.value)) },
                        React.createElement("span", { className: styles.accessibilityText },
                            "Call to a mobile number ",
                            mobileNumber.value),
                        React.createElement(RenderProperty, { value: mobileNumber }))),
                    ShowPropertyOnEdit(faxNumber) && (React.createElement("a", { href: "fax: ".concat(faxNumber.value) },
                        React.createElement("span", { className: styles.accessibilityText },
                            "Fax to a number ",
                            faxNumber.value),
                        React.createElement(RenderProperty, { value: faxNumber }))),
                    React.createElement("a", { href: "mailto:".concat(email.value) },
                        React.createElement("span", { className: styles.accessibilityText },
                            "Write an email to ",
                            email.value),
                        React.createElement(RenderProperty, { value: email })))),
            (linkedInProfile === null || linkedInProfile === void 0 ? void 0 : linkedInProfile.value) && (React.createElement("div", { className: styles.linkedInIcon },
                React.createElement("a", { href: linkedInProfile.value, target: "_blank noreferer noopener" },
                    React.createElement("span", { className: styles.accessibilityText },
                        "Go to LinkedIn profile ",
                        linkedInProfile.value),
                    React.createElement(FAIcon, { icon: "linkedin", className: styles.icon })))),
            React.createElement("div", { className: cn(styles.ctaButtons, styles.ctaButtonsOverlay) },
                React.createElement("div", { className: styles.iconWrapperMobile },
                    (linkedInProfile === null || linkedInProfile === void 0 ? void 0 : linkedInProfile.value) && (React.createElement("a", { href: linkedInProfile.value, target: "_blank noreferer noopener" },
                        React.createElement("span", { className: styles.accessibilityText },
                            "Go to LinkedIn profile ",
                            linkedInProfile.value),
                        React.createElement(FAIcon, { icon: "linkedin", className: styles.icon }))),
                    React.createElement("a", { href: "mailto:".concat(email.value) },
                        React.createElement("span", { className: styles.accessibilityText },
                            "Write an email to ",
                            email.value),
                        React.createElement(FAIcon, { icon: "envelope", className: styles.icon })),
                    phoneNumber.value && (React.createElement("a", { href: "tel: ".concat(formattedPhoneNumber(phoneNumber.value)) },
                        React.createElement("span", { className: styles.accessibilityText },
                            "Call to a phone number ",
                            phoneNumber.value),
                        React.createElement(FAIcon, { icon: "phone", className: styles.icon }))),
                    React.createElement("button", { className: styles.primaryCta, onClick: props.openFormMobile },
                        React.createElement("span", { className: styles.accessibilityText }, "Contact via form"),
                        React.createElement(FAIcon, { icon: "comments", className: styles.icon })))))));
};
