import { geoPath, } from "d3-geo";
import compact from "lodash/compact";
import isEqual from "lodash/isEqual";
import { feature } from "topojson-client";
import { shadeColor } from "./ColorShader";
import { features } from "./features";
import { findColorFor } from "./findColorFor";
export var onCountryPointerEnter = function (regionData, geography, handleRegionHover, handleCountryHover) {
    var region = findRegion(regionData, geography);
    if (!region) {
        return;
    }
    var currentHoveredCountry = getCountryFromRegion(region, geography.id);
    if (!(currentHoveredCountry === null || currentHoveredCountry === void 0 ? void 0 : currentHoveredCountry.disabled)) {
        handleRegionHover(region);
        if (handleCountryHover && currentHoveredCountry) {
            handleCountryHover(currentHoveredCountry);
        }
    }
};
export var onCountryPointerLeave = function (handleRegionLeave, currenthoverCountryCallback) {
    handleRegionLeave();
    currenthoverCountryCallback(null);
};
export var getCountryColor = function (regionData, currentHoverRegion, currentHoverCountry, geography, selectedRegion) {
    var color = findColorFor(geography, regionData);
    var _a = findCountryAndRegion(geography, regionData === null || regionData === void 0 ? void 0 : regionData.regions) || {}, country = _a.country, region = _a.region;
    var countryIsClickable = isCountryClickable(geography, regionData, selectedRegion);
    var shouldCountryBeHovered = isEqual(currentHoverCountry, country);
    var shouldRegionBeHovered = isEqual(currentHoverRegion, region);
    var isHovered = countryIsClickable && (shouldCountryBeHovered || shouldRegionBeHovered);
    if (isHovered) {
        return shadeColor(color, -30);
    }
    else {
        return color;
    }
};
var findRegion = function (regionData, geography) {
    var _a;
    var newRegion = (_a = findCountryAndRegion(geography, regionData.regions)) === null || _a === void 0 ? void 0 : _a.region;
    return newRegion;
};
export var findCountryAndRegion = function (countryToFind, regions) {
    if (regions === void 0) { regions = []; }
    var region = regions.find(function (region) {
        return region.countries.find(function (country) {
            return country.id === countryToFind.id;
        });
    });
    var country = region === null || region === void 0 ? void 0 : region.countries.find(function (country) {
        return country.id === countryToFind.id;
    });
    if (!country) {
        var region_1 = regions.find(function (region) {
            return findCountryAndRegion(countryToFind, region.regions);
        });
        if (region_1) {
            return findCountryAndRegion(countryToFind, region_1.regions);
        }
    }
    if (region && country) {
        return { region: region, country: country };
    }
    else {
        return;
    }
};
var getCountryFromRegion = function (region, countryId) {
    var _a;
    var foundCountryIndex = (_a = region === null || region === void 0 ? void 0 : region.countries) === null || _a === void 0 ? void 0 : _a.findIndex(function (country) { return country.id === countryId; });
    if (foundCountryIndex !== -1) {
        var value = region === null || region === void 0 ? void 0 : region.countries[foundCountryIndex];
        if (value === undefined)
            return null;
        else
            return value;
    }
    return null;
};
export var clamp = function (num, min, max) {
    return Math.min(Math.max(num, min), max);
};
export var isCountryClickable = function (geo, mapData, selectedRegion) {
    if (!mapData) {
        return false;
    }
    var result = findCountryAndRegion(geo, mapData.regions);
    if (!result) {
        return;
    }
    var region = result.region, country = result.country;
    var isCountryDisabled = country.disabled;
    var isRegionZoomedOut = !selectedRegion;
    var regionHasDefaultOffice = region.defaultOffices && region.defaultOffices.length > 0;
    var countryInSelectedRegion = selectedRegion && isEqual(region, selectedRegion);
    if (isCountryDisabled) {
        return false;
    }
    if (isRegionZoomedOut) {
        return true;
    }
    if (!isRegionZoomedOut && regionHasDefaultOffice && countryInSelectedRegion) {
        return true;
    }
    return false;
};
export var isMarkerClickable = function (selectedRegion) {
    return !!selectedRegion;
};
export var handleCountryClick = function (regionData, geography, handleRegionClick, selectedRegion) {
    if (!isCountryClickable(geography, regionData, selectedRegion)) {
        return;
    }
    var region = findRegion(regionData, geography);
    if (!region) {
        return;
    }
    handleRegionClick(region);
};
export var getFeatures = function () {
    return feature(features, features.objects.world);
};
var calculateBoundingBox = function (collection, projection) {
    var gPath = geoPath().projection(projection);
    return gPath.bounds(collection);
};
export var calculateAreaSize = function (collection, projection) {
    var boundingBox = calculateBoundingBox(collection, projection);
    var width = boundingBox[1][0] - boundingBox[0][0];
    var height = boundingBox[1][1] - boundingBox[0][1];
    return [width, height];
};
export var calculateCenterPointInProjection = function (collection, projection) {
    var _a;
    var _b = calculateBoundingBox(collection, projection), lowerPoint = _b[0], upperPoint = _b[1];
    var centerXOfRegionInPixels = lowerPoint[0] + (upperPoint[0] - lowerPoint[0]) / 2;
    var centerYOfRegionInPixels = upperPoint[1] - (upperPoint[1] - lowerPoint[1]) / 2;
    var centerOfRegionInPixels = [
        centerXOfRegionInPixels,
        centerYOfRegionInPixels,
    ];
    return (_a = projection.invert) === null || _a === void 0 ? void 0 : _a.call(projection, centerOfRegionInPixels);
};
var getZoomableCountries = function (region) {
    return region.countries.filter(function (country) {
        return country.zoomable;
    });
};
export var zoomToSelection = function (region, projection, geographies) {
    var zoomableCountries = getZoomableCountries(region);
    var countriesToCenter = compact(zoomableCountries.map(function (country) {
        return geographies.find(function (geography) {
            return geography.id === country.id;
        });
    }));
    var countriesCollection = {
        features: countriesToCenter,
        type: "FeatureCollection",
        geometries: [],
    };
    var geoFeatures = getFeatures();
    var _a = calculateAreaSize(geoFeatures, projection), worldWidth = _a[0], worldHeight = _a[1];
    var _b = calculateAreaSize(countriesCollection, projection), regionWidth = _b[0], regionHeight = _b[1];
    var zoomWidth = worldWidth / regionWidth;
    var zoomHeight = worldHeight / regionHeight;
    var zoom = Math.max(Math.min(zoomWidth, zoomHeight), 1);
    // Linear function constants used to calculate zoom
    var Y_INTERCEPT = 0.9;
    var COEFFICIENT = -0.0085;
    var ZOOM_FACTOR = Math.max(COEFFICIENT * zoom + Y_INTERCEPT, 0.1);
    return {
        coordinates: calculateCenterPointInProjection(countriesCollection, projection),
        zoom: zoom * ZOOM_FACTOR,
    };
};
export var getAllOffices = function (mapData) {
    return getOffices(mapData.regions);
};
export var getOffices = function (regions) {
    var countries = regions.map(function (region) {
        return region.countries;
    });
    if (!countries) {
        return [];
    }
    var offices = countries.flatMap(function (country) {
        return country.flatMap(function (item) {
            return item.offices;
        });
    });
    return compact(offices);
};
export var getDefaultOffice = function (region) {
    return region.defaultOffices;
};
export function getOfficesFromCountry(country) {
    var _a;
    var offices = (_a = country === null || country === void 0 ? void 0 : country.offices) === null || _a === void 0 ? void 0 : _a.filter(function (office) {
        return office;
    });
    return offices !== null && offices !== void 0 ? offices : [];
}
export var getRegionsForNavigation = function (regions) {
    return regions
        .filter(function (region) {
        return region.showInTopBar;
    })
        .filter(function (region) {
        return region.regionName;
    });
};
