var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import cn from "classnames";
import React, { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import { createUseStyles, JssProvider, SheetsRegistry } from "react-jss";
import { colors } from "./basics/colors";
import { fonts } from "./basics/fonts";
import { isCypressTest } from "./basics/isCypressTest";
import { ButtonUp } from "./components/ButtonUp/ButtonUp";
import { UnsupportedBrowserNotice } from "./components/UnsupportedBrowserNotice/UnsupportedBrowserNotice";
import { EpiContext } from "./hooks/EpiContext";
import { GlobalContentStoreContext, useGlobalContentStore, } from "./hooks/GlobalContentStore";
import { LocaleContext } from "./hooks/LocaleContext";
import { InitLocalizationContext, LocalizationContext, } from "./hooks/LocalizationContext";
import { PrivacyContext, usePrivacyStore } from "./hooks/PrivacyStore";
import { useCurrentPage } from "./hooks/useCurrentPage";
import { RenderComponent } from "./views/RenderComponent";
export var NO_SCROLLING_CLASS = "no-scrolling";
export var MODALS_ROOT_ID = "modals-root";
export var useStyles = createUseStyles({
    "@global": {
        html: (_a = {
                margin: 0,
                padding: 0,
                overflowX: "hidden",
                background: colors.white
            },
            _a["&.".concat(NO_SCROLLING_CLASS)] = {
                position: "fixed",
                overflow: "hidden",
            },
            _a.boxSizing = "border-box",
            _a),
        "*, :after, :before": {
            boxSizing: "inherit",
        },
        "symbol path": {
            fill: "".concat(colors.gray60, " !important"),
        },
        body: __assign(__assign({}, fonts.medium), { webkitFontSmoothing: "antialiased", mozOsxFontSmoothing: "grayscale", margin: 0, fontSize: 16 }),
        "body figure": {
            margin: "unset",
        },
        a: {
            textDecoration: "none",
        },
        "a, p, span": {
            fontFamily: "inherit",
            color: "inherit",
            wordBreak: "break-word",
        },
        button: {
            border: "none",
            cursor: "pointer",
        },
        ".component-piwik": {
            display: "none",
        },
    },
    app: {
        width: "100vw",
        maxWidth: "100vw",
    },
    cypressTest: {
        "& *": {
            WebkitTransition: "none !important",
            MozTransition: "none !important",
            OTransition: "none !important",
            transition: "none !important",
            WebkitAnimation: "none !important",
            MozAnimation: "none !important",
            OAnimation: "none !important",
            animation: "none !important",
        },
        '& *[style*="opacity"]': {
            opacity: "1 !important",
        },
        '& *[style*="transform"]': {
            transform: "none !important",
        },
    },
});
export function App(props) {
    var initialData = typeof window.__INITIAL__DATA__ !== "undefined"
        ? JSON.parse(window.__INITIAL__DATA__)
        : props.testInitialData || null;
    var initialLocalizationData = typeof window.jsl10n !== "undefined"
        ? window.jsl10n
        : props.testTranslations || null;
    var isEditing = typeof window.__EDITING__ !== "undefined"
        ? window.__EDITING__
        : process.env.NODE_ENV === "development";
    useEffect(function () {
        var _a;
        window.addEventListener("load", function () {
            var _a, _b;
            (_b = (_a = window.epi) === null || _a === void 0 ? void 0 : _a.subscribe) === null || _b === void 0 ? void 0 : _b.call(_a, "contentSaved", function (event) {
                globalContentStore.updateWithContentLinkId(event.contentLink);
            });
        });
        window.history &&
            window.location &&
            history.replaceState({}, "", "".concat((_a = window.location.pathname) === null || _a === void 0 ? void 0 : _a.toLowerCase()).concat(window.location.search).concat(window.location.hash));
    }, []);
    var globalContentStore = useGlobalContentStore(initialData, isEditing);
    if (!globalContentStore.isInitialized())
        return null;
    return (React.createElement(EpiContext.Provider, { value: {
            isDebug: true,
            isEditable: isEditing,
        } },
        React.createElement(GlobalContentStoreContext.Provider, { value: globalContentStore },
            React.createElement(LocalizationContext.Provider, { value: InitLocalizationContext(initialLocalizationData) },
                React.createElement(InitedApp, { globalContentStore: globalContentStore })))));
}
function InitedApp(props) {
    var _a, _b, _c, _d, _e;
    var consentOptions = ((_b = (_a = props.globalContentStore.getImmediateStartPageData()) === null || _a === void 0 ? void 0 : _a.consentOptions) === null || _b === void 0 ? void 0 : _b.expandedValue) || null;
    var localeOverlay = ((_d = (_c = props.globalContentStore.getImmediateStartPageData()) === null || _c === void 0 ? void 0 : _c.localeSelector) === null || _d === void 0 ? void 0 : _d.expandedValue) || null;
    var rootContentLink = props.globalContentStore.getMainPageContentLink();
    var privacySettings = usePrivacyStore(consentOptions);
    var _f = useState(false), isLocaleOverlayOpened = _f[0], setIsLocaleOverlayOpened = _f[1];
    var styles = useStyles();
    var currentPageData = useCurrentPage();
    return (React.createElement("div", { className: cn(styles.app, isCypressTest() && styles.cypressTest) },
        React.createElement(PrivacyContext.Provider, { value: privacySettings },
            React.createElement(LocaleContext.Provider, { value: { isLocaleOverlayOpened: isLocaleOverlayOpened, setIsLocaleOverlayOpened: setIsLocaleOverlayOpened } },
                React.createElement(React.Fragment, null,
                    React.createElement("div", { id: MODALS_ROOT_ID }),
                    React.createElement(UnsupportedBrowserNotice, null),
                    !((_e = currentPageData === null || currentPageData === void 0 ? void 0 : currentPageData.hideGoToTopButton) === null || _e === void 0 ? void 0 : _e.value) && React.createElement(ButtonUp, null),
                    consentOptions && (React.createElement(RenderComponent, { contentLink: consentOptions === null || consentOptions === void 0 ? void 0 : consentOptions.contentLink })),
                    localeOverlay && (React.createElement(RenderComponent, { contentLink: localeOverlay === null || localeOverlay === void 0 ? void 0 : localeOverlay.contentLink })),
                    rootContentLink && (React.createElement(RenderComponent, { contentLink: rootContentLink })))))));
}
export function SSRApp() {
    var registry = new SheetsRegistry();
    var body = renderToString(React.createElement(JssProvider, { registry: registry },
        React.createElement(App, null)));
    var minifiedStyles = registry.registry
        .filter(function (ss) { return ss.attached; })
        .map(function (ss) { return ss.toString(); })
        .join("\n")
        .replaceAll("}\n", "} ")
        .replaceAll("\n", "")
        .replaceAll(/\s\s+/g, " ")
        .replaceAll("{ ", "{")
        .replaceAll(": ", ":")
        .replaceAll("; ", ";");
    return { body: body, styles: minifiedStyles };
}
