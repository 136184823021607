var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { createUseStyles } from "react-jss";
import { Col, Container, Row, onBreakpoint, rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { VideoComponent } from "@components/Video/VideoComponent";
var useStyles = createUseStyles({
    element: __assign(__assign({ marginBottom: rem(spacings.m) }, onBreakpoint("sm", {
        display: "block",
        marginBottom: rem(spacings.l),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xxl),
    })),
});
export function VideoBlock(props) {
    var styles = useStyles();
    return (React.createElement(Container, { dataTestid: "VideoBlock" },
        React.createElement(Row, null,
            React.createElement(Col, { sm: 10, smStart: 1, md: 8, mdStart: 2 },
                React.createElement("div", { className: styles.element },
                    React.createElement(VideoComponent, __assign({}, props)))))));
}
